import {Injectable} from '@angular/core';
import {signInWithCustomToken} from '@angular/fire/auth';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {IProdEvents, ProdEvents} from '@shared/prod_events';
import {
   AUTHORIZATION_ABORT,
   AUTHORIZATION_BEGIN,
   AUTHORIZATION_COMPLETE,
} from '@shared/shared_analytics_events';
import {UserEventName} from '@shared/shared_db_types';
import {Mutex} from 'async-mutex';
import retry from 'async-retry';
import axios, {AxiosInstance, AxiosError} from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import axiosRetry from 'axios-retry';
import {IBuildLoginTokenFnData, IBuildLoginTokenFnRes} from 'functions/src/srv_func_types';
import {BehaviorSubject, Observable} from 'rxjs';
import {Trello} from 'src/trello';
import {assert, HttpStatus} from 'src/util';

import type {JsonObject} from 'type-fest';
import {appConfig} from '@app/app_config';
import {SentrySrv} from '@app/sentry.srv';

import {DisabledError, isTrelloError, PostMessageIOError} from '@trello/trello_errors';
import {BackendFunctionsSrv} from './backend_functions.srv';
import {FbAuthSrv} from './fb_auth.srv';
import {appLog, breadcrumb, timingEnd, timingStart} from './logging';

export type TrelloSrvAuthState =
   | 'unresolved'
   | 'no_auth_no_login'
   | 'authorized'
   | 'logged_in'
   | 'authed_and_logged_in';

/** Options about creating a new user account. */
export interface INewUserOptions {
   isEditor: boolean;
   registerProdList: boolean;
}

/**
 * Base service to get access to the current Trello frame and related data.
 *
 * Initialized directly in initHook or through Route Resolver.
 *
 * Purpose:
 *    * Provide access to authorized user state
 *    * Provide access to frame with helpers for common needs
 *
 * TODO:
 *  - This may mix too many things together.  The Firebase auth, etc is
 *    really something that is stable for the entire frame, but the trello
 *    calls based upon t objects need to keep changing.
 *  - The part that needs to be sorted is the authorized Trello state connection
 *    to then doing firebase things.
 */
@Injectable({
   providedIn: 'root',
})
export class TrelloSrv {
   get initialized(): boolean {
      return this._initialized;
   }

   // -- Internal Srv State -- //
   /**
    * Track if we have been initialized already.
    */
   protected _initialized: boolean = false;

   /** Used to gate parallel calls to initialize. */
   protected initMutex: Mutex = new Mutex();

   /** Track the current state of authorization and login status for the system. */
   //protected _authState: TrelloSrvAuthState = 'unresolved';

   // --- Internal Trello -- //
   protected tFrame: Trello.PowerUp.IFrame | null = null;

   protected _restApi: Trello.PowerUp.RestApiClient | null = null;
   protected _authorized: boolean = false;
   protected _restToken: string | null = null;

   /** Standard axios API instances */
   protected _axiosInstance: AxiosInstance | null = null;

   /** axios API instance that is set to retry on rate limited calls */
   protected _axiosInstanceRetrying: AxiosInstance | null = null;

   protected _memberDetails: Trello.PowerUp.Member | null = null;
   protected _orgDetails: Trello.PowerUp.Organization | null = null;

   /** Subject used to emit events when authorization status changes. */
   protected _authorizedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   protected _featureFlags: Record<string, boolean | string | number> = {};
   protected _featureFlagPayloads: Record<string, JsonObject> = {};

   constructor(
      protected fnSrv: BackendFunctionsSrv,
      protected fbAuthSrv: FbAuthSrv,
      protected sentrySrv: SentrySrv,
   ) {
      // pass
      appLog.log('TrellSrv:constructor: called');
   }

   /**
    * Initialize access to trello details and APIs.
    *
    * Note: this is intialized in path resolver to ensure it is ready to go.
    *
    * If the powerup frame is part of capability callback, then pass in to it.
    *
    * At Exit:
    *   - If Trello is authorized, we have full authorization details
    *   - If Trello authorized and FB user exists, should be signed in
    */
   async init(opts: {
      tCapability?: Trello.PowerUp.IFrame;
      loginFb: boolean;
      preloadFlags?: boolean;
   }): Promise<void> {
      breadcrumb('TrelloSrv:init called');

      try {
         await this.initMutex.runExclusive(async () => {
            if (!this.initialized) {
               breadcrumb('TrelloSrv:init: initializing');
               this.tFrame =
                  opts.tCapability ??
                  window.TrelloPowerUp.iframe({
                     helpfulStacks: true, //!environment.production,
                     appKey: appConfig.trelloApiKey,
                     appName: appConfig.amzPowerupName,
                  });
               breadcrumb('TrelloSrv:init: context', {data: {context: this.tFrame.getContext()}});

               const member_prom = this.tFrame.member('all'); // takes ~25ms
               const org_prom = this.tFrame.organization('all');
               const feature_flag_prom =
                  opts.preloadFlags ?? false ? this.loadFeatureFlags() : Promise.resolve(false);

               [this._memberDetails, this._orgDetails] = await Promise.all([member_prom, org_prom]);
               await feature_flag_prom;

               Sentry.setUser({
                  id: this._memberDetails.id,
                  username: this._memberDetails.username ?? undefined,
               });

               this._restApi = this.tFrame.getRestApi();
               await this.updateAuthorizationState({checkToken: true});

               breadcrumb('init: trello authorized: ', {data: {authorized: this._authorized}});

               // Attempt Firebase login iff we are trello authorized and it is requested
               // - this occurs if we have authorized and then login times out or we use
               //   a different browser, computer, etc.
               // - if login succeeds, then this will kick off autState update with claims, etc
               if (this.authorized && opts.loginFb) {
                  try {
                     // wait for pending logins: don't just check current user, wait for inflight
                     // note: normally returns within 1-20ms.
                     breadcrumb('TrelloSrv:init: calling currentFbUserReady');
                     const login_wait_time_ms = 1500;

                     timingStart('currentFbUserReady');
                     const fb_user = await this.fbAuthSrv.currentFbUserReady(login_wait_time_ms);
                     timingEnd('currentFbUserReady');

                     breadcrumb('init: checking firebase user: ', {
                        data: {user: fb_user?.toJSON() ?? null},
                     });
                     const fb_user_mismatch =
                        this._memberDetails.id !== (fb_user?.uid ?? 'null_id');

                     // we have a null user which means we should try to login
                     if (fb_user == null || fb_user_mismatch) {
                        if (fb_user == null) {
                           breadcrumb(
                              'TrelloSrv:init: FB Relogin: because authorized but fb user is null',
                           );
                        } else {
                           breadcrumb(
                              'TrelloSrv:init: FB Relogin: because authorized but FB user id mismatch',
                           );
                        }
                        // note: don't know if editor user, but should not be creating an account anyway
                        const success = await this.loginFirebase({
                           newUser: {isEditor: false, registerProdList: false},
                        });
                        breadcrumb(`TrelloSrv:init: re-login ${success ? 'SUCCESS' : 'FAILED'}`);
                     }
                  } catch (e: any) {
                     breadcrumb(`Trello:init: exception while attempting FB login.`, {
                        data: {name: e.name, msg: e.message},
                     });
                     throw new Error('Error trying to login to firebase.');
                  }
               }

               this._initialized = true;
            }
         });
      } catch (e: any) {
         if (isTrelloError(e) && e.name === PostMessageIOError.PLUGIN_DISABLED) {
            throw new DisabledError('Plugin disabled - found in TrelloSrv:init');
         } else {
            throw e;
         }
      }
   }

   // ====================[ TRELLO ]======================================= //
   /**
    * Reset frame to a new frame.
    *
    * HACK: fix up better
    */
   resetFrame(tCapability: Trello.PowerUp.IFrame): void {
      breadcrumb('TrelloSrv:resetFrame', {data: {context: tCapability.getContext()}}, {log: false});
      this.tFrame = tCapability;
   }

   /** Return the currently associated trello frame.  Used for all trello API calls. */
   get frame(): Trello.PowerUp.IFrame {
      assert(this.tFrame != null);
      return this.tFrame;
   }

   /**
    * Return the current context.
    *
    * todo: consider ways to make this reactive when card changes.
    */
   get context(): Trello.PowerUp.Context {
      assert(this.tFrame != null);
      return this.tFrame.getContext();
   }

   /**
    * The member details from when the service was initialized.
    */
   get member(): Trello.PowerUp.Member | null {
      return this._memberDetails;
   }

   get organization(): Trello.PowerUp.Organization | null {
      return this._orgDetails;
   }

   /** Return the user's permissions in the current context. */
   get permissions(): Trello.PowerUp.ContextPermissions {
      return this.context.permissions!;
   }

   /** Return reference to the Axios instance for making REST API calls. */
   get api(): AxiosInstance {
      if (this._axiosInstance == null) {
         throw new Error('Attempted to use null API');
      }
      return this._axiosInstance;
   }

   /** Return reference to the Axios instance for making REST API calls. */
   get apiRetry(): AxiosInstance {
      if (this._axiosInstanceRetrying == null) {
         throw new Error('Attempted to use null API');
      }
      return this._axiosInstanceRetrying;
   }

   get restToken(): string | null {
      return this._restToken;
   }

   /** Return true if trello is authorized. */
   get authorized(): boolean {
      return this._authorized;
   }

   get authorized$(): Observable<boolean> {
      return this._authorizedSubject;
   }

   get fbAuth(): FbAuthSrv {
      return this.fbAuthSrv;
   }

   /**
    * Send query to get the type of board membership we have.
    *
    * Null means we don't have a valid board. (powerup may be running in background on another page)
    * or some other detail has caused us not to be able to work.
    */
   async getBoardMemberType(): Promise<Trello.PowerUp.MemberType | null> {
      assert(this.frame != null);

      try {
         const [member, board_info] = await Promise.all([
            await this.frame.member('all'),
            await this.frame.board('all'),
         ]);

         const our_membership = board_info.memberships.find((x) => x.idMember === member.id);
         return our_membership?.memberType ?? 'observer';
      } catch (e: any) {
         if (
            e.name === PostMessageIOError.PLUGIN_DISABLED ||
            e.name === PostMessageIOError.NOT_HANDLED ||
            e.name === PostMessageIOError.INVALID_CONTEXT
         ) {
            return null;
         }

         breadcrumb(`Failure attempting to lookup board member type: ${e}`, {
            data: {context: this.frame.getContext()},
         });
      }
      // default to observer
      return 'observer';
   }

   /**
    * Log a user event to the backend for processing.
    */
   logUserEvent(event: {name: UserEventName; data?: Record<string, any>}): void {
      assert(this.initialized, 'call to logUserEvent before initialized');
      this.fnSrv.logUserEvent(
         {
            eventName: event.name,
            userId: this.context.member,
            boardId: this.context.board,
            username: this.member?.username ?? null,
         },
         event.data,
      );
   }

   /**
    * Log a product event to the backend for processing
    */
   logProdEvent(event: IProdEvents): void {
      assert(this.initialized, 'call to logProdEvent before initialized');
      this.fnSrv.logProdEvent({
         uid: this.context.member,
         board_id: this.context.board,
         ...event,
      });
   }

   /** Load feature flags for use while running. */
   async loadFeatureFlags(): Promise<void> {
      const resp = await this.fnSrv.getFeatureFlags({
         uid: this.context.member,
      });
      this._featureFlags = resp?.flags ?? {};
      this._featureFlagPayloads = resp?.flagPayloads ?? {};
      appLog.info('Feature Flags: ', this._featureFlags, this._featureFlagPayloads);
   }

   getFeatureFlag(name: string): boolean | string | number | undefined {
      return this._featureFlags[name];
   }

   getFeatureFlagPayload(name: string): JsonObject | undefined {
      return this._featureFlagPayloads[name];
   }

   /**
    * Called to trigger an attempt to ask the user to authorize trello.
    *
    * By default it also performs the firebase login process.
    */
   async authorizeTrello(opts: {newUser: INewUserOptions}): Promise<boolean> {
      assert(this._restApi != null);

      let completed = false;

      try {
         this.fnSrv.logEvent({eventName: AUTHORIZATION_BEGIN});
         this.logUserEvent({name: UserEventName.AUTH_START});
         this.logProdEvent({type: ProdEvents.AUTH_START, props: {}});

         // Auth with trello
         try {
            // debugging: check if possible to auth while have FB user still.
            breadcrumb('authorizeTrello: fb user: ', {
               data: {user: this.fbAuth.currentFbUser?.toJSON ?? null},
            });
            await this._restApi.authorize({scope: 'read,write,account'});
            await this.updateAuthorizationState();
         } catch (e: unknown) {
            appLog.log('Authorization aborted');
            this.fnSrv.logEvent({eventName: AUTHORIZATION_ABORT});
            this.logUserEvent({name: UserEventName.AUTH_ABORT});
            this.logProdEvent({type: ProdEvents.AUTH_ABORT, props: {}});
            return false;
         }

         this.fnSrv.logEvent({eventName: AUTHORIZATION_COMPLETE});
         this.logUserEvent({name: UserEventName.AUTH_SUCCESS});
         this.logProdEvent({type: ProdEvents.AUTH_SUCCESS, props: {}});

         // Now auth with firebase
         breadcrumb('authorizeTrello: attempting firebase login');
         await this.loginFirebase({newUser: opts.newUser});

         //appLog.log('token: ', token);
         completed = true;
      } catch (e: unknown) {
         breadcrumb('Authorization aborted');
         this.fnSrv.logEvent({eventName: AUTHORIZATION_ABORT});
         this.logUserEvent({name: UserEventName.AUTH_ABORT});
         this.logProdEvent({type: ProdEvents.AUTH_ABORT, props: {}});
      }
      return completed;
   }

   /**
    * Clear the current trello authorization status.
    */
   async clearTrelloAuthorization(): Promise<void> {
      breadcrumb('TrelloSrv:clearTrelloAuthorization');
      assert(this._restApi != null);
      await this._restApi.clearToken();
      await this.updateAuthorizationState();
   }

   /**
    * Login / create the firebase user account.
    */
   async loginFirebase(opts: {newUser: INewUserOptions}): Promise<boolean> {
      breadcrumb('TrelloSrv:loginFirebase: called', {data: opts});
      const token = this.restToken;
      if (token != null) {
         let build_resp: IBuildLoginTokenFnRes;

         // -- BUILD LOGIN TOKEN -- //
         try {
            breadcrumb('TrelloSrv:loginFirebase: signout from firebase to clear any bad creds.');
            await this.fbAuthSrv.logoutFirebase();

            const args: IBuildLoginTokenFnData = {
               trelloApiKey: appConfig.trelloApiKey,
               trelloToken: token,
               newUserOpts: {
                  isEditor: opts.newUser.isEditor,
                  registerProdList: opts.newUser.registerProdList,
               },
               metadata: {
                  trelloUid: this._memberDetails?.id ?? null,
                  trelloUser: this._memberDetails?.username ?? null,
               },
            };
            breadcrumb('TrelloSrv:loginFirebase: buildLoginToken');
            build_resp = await this.fnSrv.buildLoginToken(args);
            breadcrumb('TrelloSrv:loginFirebase: result', {data: {build_resp}});

            if (build_resp.errorCode != null) {
               appLog.warn('buildLoginToken: failed to build token.');
               breadcrumb('buildLoginToken failed.', {data: {args, build_resp}});
               this.sentrySrv.handleError(
                  new Error(
                     `TrelloSrv:loginFirebase: buildLoginToken error: ${build_resp.errorCode}:${build_resp.errorMsg}`,
                  ),
               );
               return false;
            }
         } catch (e: any) {
            breadcrumb('buildLoginToken: unexpected failure');
            this.sentrySrv.handleError(
               e,
               'TrelloSrv:loginFirebase: buildLoginToken unexpected failure',
            );
            return false;
         }

         // -- SIGNIN TO FIREBASE -- //
         try {
            const custom_token = build_resp.firebaseCustomToken!;
            const new_user_created = build_resp.newUserWasCreated;
            //appLog.log('custom token resp: ', custom_token);

            breadcrumb(
               `TrelloSrv:loginFirebase: signInWithCustomToken - (buildLoginToken: created user - ${new_user_created}`,
            );
            const user_cred = await retry(
               async (_bail, count) => {
                  breadcrumb(`signInWithCustomToken: attempt ${count}`);
                  const cred = await signInWithCustomToken(this.fbAuthSrv.fireAuth, custom_token);
                  return cred;
               },
               {
                  retries: 3,
                  minTimeout: 100,
                  maxTimeout: 1000,
                  factor: 2,
               },
            );

            appLog.log('user cred: ', user_cred);
            return true;
         } catch (e: any) {
            breadcrumb('signInWithCustomToken: failed');
            this.sentrySrv.handleError(e, 'TrelloSrv:loginFirebase: signInWithCustomToken failure');
            return false;
         }
      } else {
         appLog.error('Trello is not authorized for login yet.');
         return false;
      }
   }

   /**
    * Re-test and update the state of authorization
    */
   protected async updateAuthorizationState(opts?: {checkToken: boolean}): Promise<void> {
      breadcrumb('TrelloSrv:updateAuthorizationState');
      // auth state is in flux, so mark unresolved for now
      //this._authState = 'unresolved';

      const check_token = opts?.checkToken ?? false;

      const clear_rest_api_and_auth = () => {
         this._restApi = null;
         this._authorized = false;
         this._restToken = null;
         this._axiosInstance = null;
         this._axiosInstanceRetrying = null;
      };

      if (this._restApi == null) {
         clear_rest_api_and_auth();
      } else {
         try {
            // note: this can throw PostMessageIOError.NOT_HANDLED
            // when it does we clear everything to consider it as not authorized
            breadcrumb('updateAuthState: updating restAPI data');
            this._authorized = await this._restApi.isAuthorized();
            this._restToken = await this._restApi.getToken();
            breadcrumb('updateAuthState: completed restAPI data');

            // Add better error handling for axios calls
            const addErrorHandling = (axInst: AxiosInstance, instName: string): void => {
               axiosBetterStacktrace(axInst);
               axInst.interceptors.response.use(
                  undefined,
                  // eslint-disable-next-line @typescript-eslint/promise-function-async
                  (e: AxiosError) => {
                     let ex_stack = '';
                     if (axios.isAxiosError(e) && e.config?.topmostError != null) {
                        e.originalStack = e.stack;
                        e.stack = `${e.stack}\n${e.config.topmostError.stack}`;
                        ex_stack = e.config.topmostError.stack ?? '';
                        delete e.config.topmostError;
                     }

                     if (e.response) {
                        breadcrumb(`trelloSrv:${instName}: AxiosError: `, {
                           data: {
                              url: e.config?.url,
                              message: e.message,
                              headers: e.response.headers,
                              status: e.response.status,
                              statusText: e.response.statusText,
                              data: e.response?.data,
                              topStack: ex_stack,
                           },
                        });
                     }
                     return Promise.reject(e);
                  },
               );
            };

            this._axiosInstance = axios.create({
               baseURL: 'https://api.trello.com/1',
               params: {
                  key: appConfig.trelloApiKey,
                  token: this._restToken,
               },
            });
            addErrorHandling(this._axiosInstance, 'axApi');

            this._axiosInstanceRetrying = axios.create({
               baseURL: 'https://api.trello.com/1',
               params: {
                  key: appConfig.trelloApiKey,
                  token: this._restToken,
               },
            });
            axiosRetry(this._axiosInstanceRetrying, {
               retries: 7, // wait up to 12.8 seconds 😱
               retryDelay: axiosRetry.exponentialDelay,
               retryCondition: (e: any) => {
                  return (
                     axiosRetry.isIdempotentRequestError(e) ||
                     (axios.isAxiosError(e) && e.response?.status === HttpStatus.TOO_MANY_REQUESTS)
                  );
               },
               onRetry: (count: number, e: any, config: any) => {
                  appLog.warn(`Retrying request`, count, e, config);
               },
            });
            addErrorHandling(this._axiosInstanceRetrying, 'axApiRetry');

            // If asked to check token, make a simple request and see get unauthorized and
            // if we do, then clear the token because we have a bad or out of date one.
            if (this._authorized && check_token) {
               const resp = await this._axiosInstanceRetrying.get<any>('/members/me', {
                  validateStatus: (_s) => true,
               });
               // If we are unauthorized, then our token is invalid and we should clear
               if (resp.status === HttpStatus.UNAUTHORIZED) {
                  appLog.warn('Trello token found to be invalid. Clearing for reauthorization.');
                  breadcrumb('Trello token found invalid, clearing auth');
                  await this.clearTrelloAuthorization();
               }
            }
         } catch (e: any) {
            breadcrumb('TrelloSrv:updateAuthorizationState: had failure', {
               data: {name: e.name, msg: e.message},
            });

            if (isTrelloError(e) && e.name === PostMessageIOError.NOT_HANDLED) {
               breadcrumb(
                  'Error was a PostMessageIOError.NOT_HANDLED.  Clear rest API/auth and continue',
               );
               // Didn't have context to get details, so clear things out
               clear_rest_api_and_auth();
            } else {
               this.sentrySrv.handleError(e, 'TrelloSrv:updateAuthorizationState');
               throw e;
            }
         }
      }

      try {
         this._authorizedSubject.next(this._authorized);
      } catch (e: any) {
         this.sentrySrv.handleError(e, 'AuthorizedSubj:emit_chain:');
      }
   }
}

/**
 * Angular Router Resolver to ensure that the TrelloSrv has been initialized.
 */
@Injectable({providedIn: 'root'})
export class TrelloSrvResolver implements Resolve<boolean> {
   constructor(protected trelloSrv: TrelloSrv) {}

   async resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
      const preload_flags = route.data.preloadFlags ?? false;

      breadcrumb(`TrelloSrvResolver:resolve: init srv - preloadFlags: ${preload_flags}`);
      if (appLog.getLevel() <= appLog.levels.DEBUG) {
         console.time('trelloSrvResolve');
      }
      try {
         await this.trelloSrv.init({loginFb: true});
      } catch (e: any) {
         breadcrumb(`TrelloSrvResolver:resolve: init failed:`, {data: {msg: e.message}});
         throw new Error(`TrelloSrvResolver:resolve had error: ${e.message}`);
      }
      if (appLog.getLevel() <= appLog.levels.DEBUG) {
         console.timeEnd('trelloSrvResolve');
      }
      return true;
   }
}
