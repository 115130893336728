import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IFieldConfigV12 {
   version: number;
   fields: IFieldInfoV12[];
}

interface IFieldConfigV13 {
   version: number;
   custFieldIds: string[];
   fields: IFieldInfoV13[];
}

interface IFieldInfoV12 {
   name: string;
}

interface IFieldInfoV13 {
   name: string;

   cust: {
      enabled: boolean;
      id: string | null;
   };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addCustFields_12_13: IFieldDataMigration = {
   targetVersion: 13,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 12);

      const incoming = c as IFieldConfigV12;
      assert(incoming != null);

      const outgoing = c as IFieldConfigV13;

      // add empty ids
      outgoing.custFieldIds = [];

      for (const f of outgoing.fields) {
         f.cust = {
            enabled: false,
            id: null,
         };
      }

      // Set final version
      c.version = 13;
      return c;
   },
};
