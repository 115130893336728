import {INumberField, NumberFormat, NumberRuleOp, NumberShowType} from '@shared/domain_types';
import {assert} from '@util/assert';

/**
 * Return the given number formatted as requested by field data.
 */
export function formatNumber(v: number, cfg: INumberField, locale?: string): string {
   assert(cfg.showAs === NumberShowType.NUMBER || cfg.showAs === NumberShowType.PROGRESS_BAR);

   let final_v = v;

   const opts: Intl.NumberFormatOptions = {};

   if (cfg.numFormat === NumberFormat.STANDARD) {
      opts.style = 'decimal';
      opts.notation = 'standard';
      opts.useGrouping = true;
   } else if (cfg.numFormat === NumberFormat.STANDARD_NO_GROUPING) {
      opts.style = 'decimal';
      opts.notation = 'standard';
      opts.useGrouping = false;
   } else if (cfg.numFormat === NumberFormat.SCIENTIFIC) {
      opts.style = 'decimal';
      opts.notation = 'scientific';
   } else if (cfg.numFormat === NumberFormat.ENGINEERING) {
      opts.style = 'decimal';
      opts.notation = 'engineering';
   } else if (
      cfg.numFormat === NumberFormat.CURRENCY ||
      cfg.numFormat === NumberFormat.ACCOUNTING
   ) {
      opts.style = 'currency';
      opts.notation = 'standard';
      opts.currency = cfg.currency;
      opts.currencySign = cfg.numFormat === NumberFormat.ACCOUNTING ? 'accounting' : 'standard';
   } else if (
      cfg.numFormat === NumberFormat.PERCENT ||
      cfg.numFormat === NumberFormat.PERCENT_100
   ) {
      opts.style = 'percent';
      opts.notation = 'standard';

      // Format as a percent, but change the assumed range
      if (cfg.numFormat === NumberFormat.PERCENT_100) {
         final_v = final_v / 100.0;
      }
   }

   const f_digits = cfg.fDigits ?? 2;
   opts.minimumFractionDigits = f_digits;
   opts.maximumFractionDigits = f_digits;

   return Intl.NumberFormat(locale, opts).format(final_v);
}

interface INumberRuleOpDesc {
   op: NumberRuleOp;
   desc: string;
}

export const NUMBER_RULE_OP_DESCS: INumberRuleOpDesc[] = [
   {op: NumberRuleOp.EQUAL, desc: 'Equal'},
   {op: NumberRuleOp.LT, desc: 'Less Than'},
   {op: NumberRuleOp.GT, desc: 'Greater Than'},
   {op: NumberRuleOp.LTE, desc: 'Less Than or Equal'},
   {op: NumberRuleOp.GTE, desc: 'Greater Than or Equal'},
   {op: NumberRuleOp.NOT_EQUAL, desc: 'Not Equal'},
];
