import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV4 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

enum NumberShowTypeV4 {
   NUMBER = 'N',
   PROGRESS_BAR = 'P',
}

// Only define the parts needed
// V4
interface IBoardConfigV4 {
   version: number;
   fields: (IFieldInfoV4 | INumberFieldV4)[];
}

interface IFieldInfoV4 {
   name: string;
   type: FieldTypeV4;

   show: {
      front: boolean;
      back: boolean;
      name: boolean;
      activity: boolean;
   };
}

interface INumberFieldV4 extends IFieldInfoV4 {
   type: FieldTypeV4.NUMBER;
   showAs: NumberShowTypeV4;

   /** Progress bar items. */
   /** The style to use when showing as progress bar. */
   barStyle: ProgressBarStyleV4;

   /** Should we show the number next to the progress bar. */
   showNum: boolean;

   /** The minimum value for progress. */
   min: number;

   /** The maximum value for progress. */
   max: number;

   /** The width of the bar in characters. */
   width: number;

   /** List of custom Unicode strings used for progress */
   customBars?: string[];
}

// V5
interface IBoardConfigV5 {
   version: number;
   fields: (IFieldInfoV5 | INumberFieldV5)[];
}

interface IFieldInfoV5 {
   name: string;
   type: FieldTypeV4;
}

interface INumberFieldV5 extends IFieldInfoV5 {
   type: FieldTypeV4.NUMBER;
   showAs: NumberShowTypeV4;

   /** Optional configuration for progress bar. */
   bar?: IProgressBarSettingsV5;
}

interface IProgressBarSettingsV5 {
   barStyle: ProgressBarStyleV4;
   showNum: boolean;
   min: number;
   max: number;
   width: number;
   customBars?: string[];
}

enum ProgressBarStyleV4 {
   ONE = '1',
   TWO = '2',
   THREE = '3',
   FOUR = '4',
   FIVE = '5',
   SIX = '6',
   SEVEN = '7',
   EIGHT = '8',
   NINE = '9',
   TEN = 'A',
   ELEVEN = 'B',
   TWELVE = 'C',
   THIRTEEN = 'D',
   CUSTOM = 'Z',
}

/**
 * Refactor the configuration of progress bars into a sub item.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const refactorProgressBarConfigMigration_4_5: IFieldDataMigration = {
   targetVersion: 5,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 4);

      const incoming = c as unknown as IBoardConfigV4;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV5;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV4.NUMBER) {
            const f_in = f as INumberFieldV4;
            const f_out = f as INumberFieldV5;
            f_out.bar = {
               barStyle: f_in.barStyle,
               showNum: f_in.showNum,
               min: f_in.min,
               max: f_in.max,
               width: f_in.width,
               customBars: f_in.customBars == null ? undefined : [...f_in.customBars],
            };
            // @ts-expect-error: workaround
            delete f_in.barStyle;
            // @ts-expect-error: workaround
            delete f_in.showNum;
            // @ts-expect-error: workaround
            delete f_in.min;
            // @ts-expect-error: workaround
            delete f_in.max;
            // @ts-expect-error: workaround
            delete f_in.width;
            delete f_in.customBars;
         }
      }

      // Set final version
      c.version = 5;
      return c;
   },
};
