export const POWERUP_REMOVE_DATA = 'powerup_remove_data';
export const SHOW_SETTINGS_BACK = 'show_settings_back';
export const SHOW_SETTINGS = 'show_settings';
export const POWERUP_ENABLED = 'powerup_enabled';
export const POWERUP_DISABLED = 'powerup_disabled';
export const AUTHORIZATION_BEGIN = 'authorization_begin';
export const AUTHORIZATION_ABORT = 'authorization_abort';
export const AUTHORIZATION_COMPLETE = 'authorization_complete';
export const AUTHORIZATION_DEAUTHORIZE = 'authorization_deauthorize';

export const SUBSCRIBE_CLICK = 'subscribe_click';
export const SUBSCRIBE_START = 'subscribe_start';
export const SUBSCRIBE_REDIRECT = 'subscribe_stripe_redirect';
export const SUBSCRIBE_SUCCESS = 'subscribe_success';
export const SUBSCRIBE_ABORT = 'subscribe_abort';
export const SUBSCRIBE_FAILURE = 'subscribe_failure';

export const GA_POWERUP_LISTING_VIEW_EVENT_NAME = 'powerup_listing_view';
export const GA_AMZ_FIELD_PROD_PAGE_VIEW = 'amz_field_prod_page_view';
export const GA_AMZ_FIELD_ADD_TO_TRELLO_CLICK = 'amz_field_add_to_trello_click';
export const AMZ_FIELD_PAGE_ADD_TO_TRELLO_REDIRECT = 'amz_field_page_add_to_trello_redirect';
export const AMZ_FIELDS_GADS_LISTING_REDIRECT = 'amz_field_gad_listing_redirect';

export const OPEN_BEACON_EVENT_NAME = 'open_beacon';

export const CREATE_BACKUP_EVENT_NAME = 'create_backup';

/** Logged when we have user enable and we find that it was a google ads referal. */
export const AMF_GADS_ENABLE_CONVERSION = 'amz_field_gads_enable_conversion';

/** Used to track when a user opens the portal to edit their subscription. */
export const SUBSCRIPTION_PORTAL_VIEW = 'subscription_portal_view';
export const SUBSCRIPTION_ABORT = 'subscription_abort';

/** Used to track if the window was successfully opened. */
export const SUBSCRIPTION_POPUP_OPENED = 'subscription_popup_opened';

/** Used to track when people click on a nudge to get subscription. */
export const SUBSCRIPTION_NUDGE_CLICKED = 'subscription_nudge_click';

export const GA_TEMPLATE_OPEN = 'template_open';

/** Sent when we load powerup in cookie fail mode where can't get localstorage access. */
export const GA_LOCAL_STORAGE_FAIL_INIT_EVENT_NAME = 'powerup_ls_fail_init';

/** Sent when user enables powerup with local storage fail. */
export const GA_LOCAL_STORAGE_FAIL_ENABLE_EVENT_NAME = 'powerup_ls_fail_enable';

/** Sent when user disables powerup from local storage fail setup. */
export const GA_LOCAL_STORAGE_FAIL_DISABLE_EVENT_NAME = 'powerup_ls_fail_disable';

/** For temporarily debugging */
export const EVENT_DEBUG_VAR1 = 'powerup_debug_v1';

/**
 * Id of the property attached to GA users for subscription variant.
 */
export const GA_SUBSCRIPTION_VAR_PARAM_NAME = 'subscribe_variant';
export const GA_FIELD_SETTINGS_VAR_PARAM_NAME = 'field_settings_variant';
export const GA_APP_VERSION_PARAM_NAME = 'app_version';

// --- Template Related --- //
/** Sent when a user chooses to open a template link in AMF */
export const TEMPLATE_LINK_EVENT = 'template_click';

/** Sent when a user views a template page */
export const TEMPLATE_VIEW_EVENT = 'template_view';

// ---- REDIRECT RELATED EVENTS & SUB EVENTS ---- //
export const REDIRECT_BASE_EVENT = 'redirect';

export enum RedirectSubEvent {
   GOOGLE_ADS = 'google_ads',
   //AMZ_PAGE_ADD_TO_TRELLO = 'amz_page_add_to_trello',
   // page: unique page ids
}

// --- REFERRAL RELATED EVENTS --- //
export const REFERRAL_ENABLED_BASE_EVENT = 'referral_enabled';
export const REFERRAL_SUBSCRIBE_BASE_EVENT = 'referral_subscribe';

// note: sub events are the UserReferralType id.

// Nest events - use base and sub variant
export const FIELD_ADD_BASE_EVENT = 'field_add';

export const PAGE_VIEW_EVENT = 'page_view';
