import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {CaptureContext, ScopeContext, SeverityLevel} from '@sentry/types';
import {isFirebaseError} from '@util/firebase';
import {rewriteErrorWithZone} from '@util/zone/long-stack-traces-ext';
import {getZoneContextPath} from '@util/zone/zone_context-ext';
import {appLog, breadcrumb} from './services/logging';

export const AREA_TAG = 'code_area';

/**
 * Service to hold onto the sentry handler that
 * we can use in multiple places.
 */
@Injectable({
   providedIn: 'root',
})
export class SentrySrv {
   protected sentryHandler: Sentry.SentryErrorHandler;

   constructor() {
      this.sentryHandler = Sentry.createErrorHandler({
         showDialog: false,
         logErrors: false,
      });
   }

   handleError(err: any, prefix?: string, tags?: {[t: string]: string}): void {
      breadcrumb('SentrySrv:handleError: called to handle error', {
         data: {
            name: err?.name ?? null,
            message: err?.message ?? null,
            code: err?.code ?? null,
         },
      });
      const final_tags = tags == null ? {} : {...tags};
      const context_path = getZoneContextPath();
      const context_prefix = context_path == null ? '' : `${context_path}:`;

      //const in_angular = NgZone.isInAngularZone();
      //console.log('in angular: ', in_angular);

      // Expand the stack trace with zone task details
      rewriteErrorWithZone(err);

      if (prefix != null && err.message != null) {
         err.message = `${prefix}: ${context_prefix}${err.message}`;
      } else {
         err.message = `${context_prefix}${err.message}`;
      }
      appLog.error(`Error Handling: collecting error details: [${err.message}`);

      if (isFirebaseError(err)) {
         final_tags.firebase_error_code = `FB-${err.code}`;
      }
      Sentry.withScope((scope): void => {
         scope.setTags({
            handler: 'SentrySrv',
            zone_context: context_path ?? 'unknown',
         });
         scope.setTags(final_tags);
         this.sentryHandler.handleError(err);
      });
   }

   /** Capture a standard message. */
   captureMessage(message: string, context?: CaptureContext | SeverityLevel): void {
      Sentry.captureMessage(message, context);
   }

   /** Capture a warning message. */
   handleWarning(message: string, context?: Partial<ScopeContext>): void {
      const ctx: Partial<ScopeContext> = {...{level: 'warning'}, ...context};
      appLog.warn(message, ctx);
      Sentry.captureMessage(message, ctx);
   }
}
