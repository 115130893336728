import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV10 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
// V10
interface IBoardConfigV10 {
   version: number;
   fields: (IFieldInfoV10 | IListFieldV10)[];
}

interface IFieldInfoV10 {
   name: string;
   type: FieldTypeV10;
}

interface IListFieldV10 extends IFieldInfoV10 {
   type: FieldTypeV10.LIST;
}

// V11
interface IBoardConfigV11 {
   version: number;
   fields: (IFieldInfoV11 | IListFieldV11)[];
}

interface IFieldInfoV11 {
   name: string;
   type: FieldTypeV10;
}

interface IListFieldV11 extends IFieldInfoV11 {
   type: FieldTypeV10.LIST;

   multi: {
      enabled: boolean;
      method: ListMultiMethodV11;
   };
}

enum ListMultiMethodV11 {
   SINGLE_BADGE = 'S',
   MULTI_BADGE = 'M',
}

/**
 * Add text masking options
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addListMultiselect_10_11: IFieldDataMigration = {
   targetVersion: 11,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 10);

      const incoming = c as unknown as IBoardConfigV10;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV11;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV10.LIST) {
            const f_out = f as IListFieldV11;
            f_out.multi = {
               enabled: false,
               method: ListMultiMethodV11.SINGLE_BADGE,
            };
         }
      }

      // Set final version
      c.version = 11;
      return c;
   },
};
