import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IFieldConfigV2 {
   version: number;
   fields: IFieldInfoV2[];
}

interface IFieldInfoV2 {
   name: string;
   show?: {
      front: boolean;
      back: boolean;
      name: boolean;
      activity: boolean;
   };
}

interface IFieldConfigV3 {
   version: number;
   fields: IFieldInfoV3[];
}

interface IFieldInfoV3 {
   name: string;

   show?: {
      front: boolean;
      back: boolean;
      name: boolean;
      activity: boolean;
      hide: boolean;
   };
}

/**
 * Add the configuration for hiding field by default.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addHiddenDefaultMigration_2_3: IFieldDataMigration = {
   targetVersion: 3,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 2);

      const incoming = c as IFieldConfigV2;
      assert(incoming != null);

      const outgoing = c as IFieldConfigV3;

      for (const f of outgoing.fields) {
         if (f.show != null) {
            f.show.hide = false;
         }
      }

      // Set final version
      c.version = 3;
      return c;
   },
};
