import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV17 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
interface IBoardConfigV17 {
   version: number;
   fields: IFieldInfoV17[];
}

interface IFieldInfoV17 {
   name: string;
}

interface IBoardConfigV18 {
   version: number;
   fields: (ITextFieldV18 | IBoolFieldV18 | INumberFieldV18 | IDateFieldV18 | IUnknownFieldV18)[];
}

interface IFieldBaseV18 {
   id: string;
   name: string;
   type: FieldTypeV17;
}

interface IUnknownFieldV18 extends IFieldBaseV18 {
   type: FieldTypeV17.UNKNOWN;
}
interface IBoolFieldV18 extends IFieldBaseV18 {
   type: FieldTypeV17.BOOL;
   calc: IFormulaSettingV18;
}

interface INumberFieldV18 extends IFieldBaseV18 {
   type: FieldTypeV17.NUMBER;
   calc: IFormulaSettingV18;
}

interface ITextFieldV18 extends IFieldBaseV18 {
   type: FieldTypeV17.TEXT;
   calc: IFormulaSettingV18;
}

interface IDateFieldV18 extends IFieldBaseV18 {
   type: FieldTypeV17.DATE;
   calc: IFormulaSettingV18;
}

interface IFormulaSettingV18 {
   enabled: boolean;
   formula?: FormulaV18;
}

// Use empty array
type FormulaV18 = any[];

/**
 * Use partially defined types so we ensure we are checking the right ones.
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addDateFieldCalcs_17_18: IFieldDataMigration = {
   targetVersion: 18,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 17);

      const incoming = c as IBoardConfigV17;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV18;

      // add empty ids
      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV17.DATE && f.calc == null) {
            f.calc = {
               enabled: false,
               formula: [],
            };
         }
      }

      // Set final version
      c.version = 18;
      return c;
   },
};
