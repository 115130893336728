/**

/**
 * Promise that is resolved in a given amount of time in milliseconds.
 */
export async function runLater(delayMs: number): Promise<void> {
   return new Promise<void>((resolve) => {
      globalThis.setTimeout(() => resolve(), delayMs);
   });
}

/**
 * Wait until the given function returns true or time out.
 */
export async function waitUntil(fn: () => boolean, stepWait: number = 0): Promise<void> {
   return new Promise<void>((resolve) => {
      function runStep(): void {
         if (fn()) {
            resolve();
         } else {
            globalThis.setTimeout(runStep, stepWait);
         }
      }
      runStep();
   });
}

/**
 * Wait until the given function returns true or time out.
 */
export async function waitUntilAsync(
   fn: () => Promise<boolean>,
   stepWait: number = 0,
): Promise<void> {
   return new Promise<void>((resolve) => {
      async function runStep(): Promise<void> {
         const res = await fn();
         if (res) {
            resolve();
         } else {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            globalThis.setTimeout(runStep, stepWait);
         }
      }
      runStep();
   });
}

/**
 * Externally wrap a promise as a deferred.
 *
 * Useful to externally wait and trigger resolution of a Promise.
 */
export class Deferred<T> {
   promise: Promise<T>;

   protected settledVal: boolean = false;

   protected resolveCb!: (v: T) => void;
   protected rejectCb!: (r?: any) => void;

   /** Return the current settled state of the promise. */
   get settled(): boolean {
      return this.settledVal;
   }

   constructor() {
      this.promise = new Promise<T>((resolve, reject) => {
         this.resolveCb = resolve;
         this.rejectCb = reject;
      });
   }

   /** Resolve the promise with the given value. */
   resolve(v: T): void {
      this.settledVal = true;
      this.resolveCb(v);
   }

   /** Cause the promise to reject. */
   reject(r: any): void {
      this.settledVal = true;
      this.rejectCb(r);
   }
}
