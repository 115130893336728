import {Component, OnInit} from '@angular/core';


/**
 * Main application component.
 */
@Component({
   selector: 'tr-app-root',
   template: ` <router-outlet></router-outlet> `,
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
   ngOnInit() {
      //injectMfScript();
   }
}
