import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV15 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

enum BadgeColorTypeV15 {
   BLUE = 'b',
}

// Only define the parts needed
// V15
interface IBoardConfigV15 {
   version: number;
   fields: (IFieldInfoV15 | IListFieldV15)[];
}

interface IFieldInfoV15 {
   name: string;
   type: FieldTypeV15;
}

interface IListFieldV15 extends IFieldInfoV15 {
   type: FieldTypeV15.LIST;
}

// V16
interface IBoardConfigV16 {
   version: number;
   fields: (IFieldInfoV16 | IListFieldV16)[];
}

interface IFieldInfoV16 {
   name: string;
   type: FieldTypeV15;
}

interface IListFieldV16 extends IFieldInfoV16 {
   type: FieldTypeV15.LIST;
   emptyColor: BadgeColorTypeV15 | null;
}

/**
 * Add empty color for lists.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addListDefaultColor_15_16: IFieldDataMigration = {
   targetVersion: 16,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 15);

      const incoming = c as unknown as IBoardConfigV15;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV16;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV15.LIST) {
            const f_out = f as IListFieldV16;
            f_out.emptyColor = null;
         }
      }

      // Set final version
      c.version = 16;
      return c;
   },
};
