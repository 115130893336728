interface IFirebaseError extends Error {
   code: string;
   message: string;
   stack?: string;
}

/** Check if we have a firebase error. */
export function isFirebaseError(err: any): err is IFirebaseError {
   return err?.code != null; // && err.code.startsWith('auth/');
}

/**
 * Common Firebase Error Codes.
 */
export enum FbErrorCodes {
   PERMISSION_DENIED = 'permission-denied',
   AUTH_NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
}
