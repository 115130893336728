import {ICardData, IFieldInfo} from '@shared/domain_types';
import {addVersion_0_1} from './1_add_version_data';

/**
 * A function that maps from one version of card fields.
 */
export interface ICardDataMigration {
   targetVersion: number;
   f: (c: ICardData, fieldCfgs: IFieldInfo[]) => ICardData;
}

export const CARD_DATA_MIGRATIONS: ICardDataMigration[] = [
   // 0 --> 1
   addVersion_0_1,
];
