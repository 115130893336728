import {Injectable} from '@angular/core';

import {Auth, User, signOut, authState, onAuthStateChanged} from '@angular/fire/auth';
import {defer, filter, Observable, take} from 'rxjs';
import {SentrySrv} from '@app/sentry.srv';
import {Deferred, runLater} from '@util/async';
import {appLog, breadcrumb} from './logging';

/**
 * Base service for wrapping and interfacing with Firebase Authentication
 * and the user management for it.
 */
@Injectable({
   providedIn: 'root',
})
export class FbAuthSrv {
   // --- Internal Firebase -- //
   //protected _fbClaims: ParsedToken | null = null;

   constructor(protected fbAuth: Auth, protected sentrySrv: SentrySrv) {
      //  monitor for firebase claims
      /*
      this.fbUserState$
         .pipe(
            switchMap(
               async (user): Promise<IdTokenResult | null> =>
                  user != null ? user.getIdTokenResult(false) : Promise.resolve(null),
            ),
            // Attempt to retry the login.
            retry({
               count: 30 * 2, // 2 minutes
               delay: 2000,
            }),
         )
         .subscribe({
            next: (token: IdTokenResult | null) => {
               // Get latest copy of claims

               breadcrumb('FbAuthSrv: updating token claims', {
                  data: {token, user: this.currentFbUser?.toJSON() ?? null},
               });
               if (token != null) {
                  this._fbClaims = token.claims;
               } else {
                  this._fbClaims = {};
               }
            },
            error: (e: any) => {
               breadcrumb('FbAuthSrv: ERROR waiting for fbUserState$ and claims', {
                  data: {msg: e.message},
               });
               this.sentrySrv.handleError(e, 'FbAuthSrv:fbUserState$ claim monitoring - ');
            },
         });
      */
   }

   // ====================[ FIREBASE ]======================================= //
   get fireAuth(): Auth {
      return this.fbAuth;
   }

   /**
    * Immediately return the currently known firebase user.
    * This may be null while login is in progress.
    *
    * To wait for current user to be logged in, use currentFbUserReady().
    */
   get currentFbUser(): User | null {
      return this.fireAuth.currentUser;
   }

   /**
    * Stream of firebase User objects based upon authentication state.
    * When user is authenticated, then != null if logged out, then null.
    * - uses onAuthStatechanged.
    */
   get fbUserState$(): Observable<User | null> {
      return authState(this.fireAuth);
   }

   /** emits a single time when we have a logged in user. May be immediate or may wait. */
   get hasLoggedInUser$(): Observable<User> {
      return this.fbUserState$.pipe(
         filter((u): u is User => u != null),
         // take instead of first: https://stackoverflow.com/a/55380827/205103
         take(1),
      );
   }

   /**
    * Force an async check for the current firebase user
    * with firebase.
    *
    * This can be used before everything is setup to know if we are going
    * to have automatically logged in because it waits for in progress logins.
    * If we aren't logged in then it will return null.
    *
    * See: https://github.com/firebase/firebase-js-sdk/issues/462
    */
   // eslint-disable-next-line @typescript-eslint/promise-function-async
   currentFbUserReady(timeoutMs?: number): Promise<User | null> {
      appLog.log('FbAuthSrv:getCurrentFbUser: called');

      const deferred = new Deferred<User | null>();

      if (this.fireAuth.currentUser != null) {
         deferred.resolve(this.fireAuth.currentUser);
      }

      if (timeoutMs != null) {
         runLater(timeoutMs)
            .then(() => {
               if (!deferred.settled) {
                  breadcrumb('FbAuthSrv:currentFbUserReady: timeout');
                  // XXX: REMOVE
                  //this.sentrySrv.captureMessage('FbAuthSrv:currentFbUserReady: timeout');
                  deferred.resolve(null);
               }
            })
            .catch(() => {
               appLog.error('FBUserReady:timeout: failure detected');
            });
      }

      const unsubscribe = onAuthStateChanged(
         this.fireAuth,
         (user) => {
            unsubscribe();
            if (!deferred.settled) {
               deferred.resolve(user);
            }
         },
         // NEVER CALLED
         (e: any) => {
            if (!deferred.settled) {
               breadcrumb('currentFbUserReady: ERROR waiting for currentFbUserReady', {
                  data: {msg: e.message},
               });
               deferred.reject(e);
            }
         },
      );

      return deferred.promise;

      /*
      return new Promise((resolve, reject) => {
         if (this.fireAuth.currentUser != null) {
            resolve(this.fireAuth.currentUser);
         }
         const unsubscribe = onAuthStateChanged(
            this.fireAuth,
            (user) => {
               unsubscribe();
               resolve(user);
            },
            (e: any) => {
               breadcrumb('currentFbUserReady: ERROR waiting for currentFbUserReady', {
                  data: {msg: e.message},
               });
               reject(e);
            },
         );
      });
      */
   }

   /**
    * Return observable that will emit once after a user is logged in.
    */
   currentFbUserReady$(): Observable<User | null> {
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      return defer(() => this.currentFbUserReady());
   }

   /**
    * Refresh the id token so we get updated claims.
    * - We have to do this sometimes because the claims come from the backend user rep
    *   and if that has changed we may still have cached credentials client side
    *
    * note: this will cause all firestore queries to refresh (due to new token?)
    */
   /*
   async refreshFbUserClaims(): Promise<void> {
      breadcrumb('FbAuthSrv:refreshFbUserClaims: requesting full token');
      if (this.currentFbUser != null) {
         try {
            // note: this appears to trigger a auth.user update as well
            const token_results = await this.currentFbUser.getIdTokenResult(true);
            this._fbClaims = token_results.claims;
            appLog.log('token results', token_results);
         } catch (e: any) {
            breadcrumb('refreshFbUserclaims: ERROR caught - ', {data: {msg: e.message}});
            throw e;
         }
      }
   }
   */

   /**
    * Manually trigger firebase auth logout.
    */
   async logoutFirebase(): Promise<void> {
      appLog.log('FbAuthSrv:logoutFirebase');
      await signOut(this.fireAuth);
   }
}
