import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV14 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
// V14
interface IBoardConfigV14 {
   version: number;
   fields: (IFieldInfoV14 | INumberFieldV14)[];
}

interface IFieldInfoV14 {
   name: string;
   type: FieldTypeV14;
}

export enum NumberShowTypeV14 {
   NUMBER = 'N',
   PROGRESS_BAR = 'P',
}

export interface IProgressBarSettingsV14 {
   linkChecklist: boolean;
}

interface INumberFieldV14 extends IFieldInfoV14 {
   type: FieldTypeV14.NUMBER;

   showAs: NumberShowTypeV14;
   bar?: IProgressBarSettingsV14;
}

// V15

interface IBoardConfigV15 {
   version: number;
   fields: (IFieldInfoV15 | INumberFieldV15)[];
}

interface IFieldInfoV15 {
   name: string;
   type: FieldTypeV14;
}

export interface IProgressBarSettingsV15 {
   linkChecklist: boolean;

   /** Add filter for checklist. */
   checklistFilter: string | null;
}

interface INumberFieldV15 extends IFieldInfoV15 {
   type: FieldTypeV14.NUMBER;
   showAs: NumberShowTypeV14;

   // Add step size.  Default to 1.0
   step?: number;

   bar?: IProgressBarSettingsV15;
}

/**
 * Add number stepping size and checklist filter string.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addStepAndChecklistFilter_14_15: IFieldDataMigration = {
   targetVersion: 15,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 14);

      const incoming = c as unknown as IBoardConfigV14;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV15;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV14.NUMBER) {
            const f_out = f as INumberFieldV15;
            f_out.step = 1.0;

            if (f_out.showAs === NumberShowTypeV14.PROGRESS_BAR && f_out.bar != null) {
               f_out.bar.checklistFilter = '';
            }
         }
      }

      // Set final version
      c.version = 15;
      return c;
   },
};
