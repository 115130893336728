import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IBoardConfigV7 {
   version: number;
   fields: IFieldInfoV7[];
}

interface IBoardConfigV8 {
   version: number;
   fields: IFieldInfoV8[];
}

interface IFieldInfoV7 {
   name: string;
}

interface IFieldInfoV8 {
   name: string;

   perms: {
      view: MemberTypeV8;
      edit: MemberTypeV8;
   };
}

enum MemberTypeV8 {
   ADMIN = 'A',
   NORMAL = 'N',
   OBSERVER = 'O',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addFieldPerms_7_8: IFieldDataMigration = {
   targetVersion: 8,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 7);

      const incoming = c as IBoardConfigV7;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV8;

      for (const f of outgoing.fields) {
         f.perms = {
            view: MemberTypeV8.OBSERVER,
            edit: MemberTypeV8.NORMAL,
         };
      }

      // Set final version
      c.version = 8;
      return c;
   },
};
