import {IBoardConfig} from '@shared/domain_types';
import {addTextInputMasksConfig_9_10} from './10_add_text_inputmask';
import {addListMultiselect_10_11} from './11_add_multiselect';
import {addLinkCheckList_11_12} from './12_add_checklist_link';
import {addCustFields_12_13} from './13_cust_fields';
import {addFieldCalcs_13_14} from './14_field_calcs';
import {addStepAndChecklistFilter_14_15} from './15_step_and_checklist_filter';
import {addListDefaultColor_15_16} from './16_list_default_color';
import {addVisCalc_16_17} from './17_vis_calc';
import {addDateFieldCalcs_17_18} from './18_date_calcs';
import {showFrontRefactorMigration_18_19} from './19_show_front_changes';

import {addEditorMigration_1_2} from './2_add_editor_size';
import {addHiddenDefaultMigration_2_3} from './3_add_hidden_defaults';
import {addBoardSectionAndAdminFilters_3_4} from './4_add_board_section_and_admin_filter';
import {refactorProgressBarConfigMigration_4_5} from './5_refactor_progress_bar';
import {addEditorTypeConfigMigration_5_6} from './6_add_text_editor_type';
import {refactorDateRules_6_7} from './7_refactor_date_rules';
import {addFieldPerms_7_8} from './8_add_field_perms';
import {makeBgColorDefault_8_9} from './9_make_bg_color_default';

/** A function that maps from one version to another. */
export interface IFieldDataMigration {
   targetVersion: number;
   f: (c: IBoardConfig) => IBoardConfig;
}

export const BOARD_CONFIG_MIGRATIONS: IFieldDataMigration[] = [
   // 1 --> 2
   addEditorMigration_1_2,
   // 2 --> 3
   addHiddenDefaultMigration_2_3,
   // 3 --> 4
   addBoardSectionAndAdminFilters_3_4,
   // 4 --> 5
   refactorProgressBarConfigMigration_4_5,
   // 5 --> 6
   addEditorTypeConfigMigration_5_6,
   // 6 --> 7
   refactorDateRules_6_7,
   // 7 --> 8
   addFieldPerms_7_8,
   // 8 --> 9
   makeBgColorDefault_8_9,
   // 9 --> 10
   addTextInputMasksConfig_9_10,
   // 10 --> 11
   addListMultiselect_10_11,
   // 11 --> 12
   addLinkCheckList_11_12,
   // 12 --> 13
   addCustFields_12_13,
   // 13 --> 14
   addFieldCalcs_13_14,
   // 14 --> 15
   addStepAndChecklistFilter_14_15,
   // 15 --> 16
   addListDefaultColor_15_16,
   // 16 --> 17
   addVisCalc_16_17,
   // 17 --> 18
   addDateFieldCalcs_17_18,
   // 18 --> 19
   showFrontRefactorMigration_18_19,
];
