import {Injectable} from '@angular/core';

import {Trello} from 'src/trello';

import {TrelloSrv} from '@app/services/trello.srv';
import {assert} from '@util/assert';

/**
 * Ids to use for tracking the variants of subscription call to action.
 */
export enum SubscribeVariant {
   /** Direct, hard signup. */
   SUB_V1 = 'sub_v1',

   /** Soft signup, with percent need. */
   SUB_V2 = 'sub_v2',

   /** Soft desc, no pricing */
   SUB_V3 = 'sub_v3',

   /** Select pricing. */
   SUB_V4 = 'sub_v4',

   /** Buy coffee size */
   SUB_V5 = 'sub_v5',

   /** Select pricing higher */
   SUB_V6 = 'sub_v6',

   /** More expensive coffee. */
   SUB_V7 = 'sub_v7',

   /** Individual or Enterprise. */
   SUB_V10 = 'sub_v10',

   /** Individual or Enterprise with Feature Table*/
   SUB_V11 = 'sub_v11',

   /** Individual S,M,L or Enterprise S,M,L with Feature Table v2 */
   SUB_V12 = 'sub_v12',

   /** Individual M,L,XL or Enterprise M,L,XL with Feature Table v2 */
   SUB_V13 = 'sub_v13',

   /** Individual S,M,L or Enterprise S,M,L with Feature Table v2 with note (v12+note) */
   SUB_V14 = 'sub_v14',

   /** Individual M,L,XL or Enterprise M,L,XL with Feature Table v2 with note (v13+note) */
   SUB_V15 = 'sub_v15',

   /** Individual M,L,XL or Enterprise S,M,L with Feature Table v2 */
   SUB_V16 = 'sub_v16',

   /** Std Grid: Ind S,M,L / Ent S,M,L table - show guarantee */
   SUB_V17 = 'sub_v17',

   /** Std Grid: Ind S,M,L / Ent S,M,L table - show guarantee and limited time */
   SUB_V18 = 'sub_v18',

   /** Std Grid: Ind M,L,XL / Ent S,M,L table - show guarantee */
   SUB_V19 = 'sub_v19',

   /** No mention of subscription */
   SUB_EMPTY = 'sub_empty',
}

/**
 * The variants that we are allowed to choose from.
 */
const SUB_ACTIVE_VARIANTS = [
   //SubscribeVariant.SUB_V1,
   //SubscribeVariant.SUB_V2,
   //SubscribeVariant.SUB_V3,
   //SubscribeVariant.SUB_EMPTY,
   //SubscribeVariant.SUB_V4,
   //SubscribeVariant.SUB_V5,
   //SubscribeVariant.SUB_V7,

   //SubscribeVariant.SUB_V6,
   //SubscribeVariant.SUB_V10,
   //SubscribeVariant.SUB_V11,
   //SubscribeVariant.SUB_V12,
   //SubscribeVariant.SUB_V13,
   //SubscribeVariant.SUB_V14,
   //SubscribeVariant.SUB_V15,

   SubscribeVariant.SUB_V16,
   //SubscribeVariant.SUB_V17,
   //SubscribeVariant.SUB_V18,
   SubscribeVariant.SUB_V19,
];

// --- VARIANTS FOR FIELDS SETTINGS VIEW -- //
export enum FieldSettingsNudgeVariant {
   EMPTY = 'fields_nudge_empty',
   V1 = 'fields_nudge_v1',
   V2 = 'fields_nudge_v2',
   V3 = 'fields_nudge_v3',

   FOOTER_V1 = 'footer_nudge_v1',
}

const FIELDS_NUDGES_ACTIVE_VARIANTS = [
   FieldSettingsNudgeVariant.V1,
   FieldSettingsNudgeVariant.V2,
   FieldSettingsNudgeVariant.V3,
];

export enum OnboardingVariant {
   EMPTY = 'onboard_empty',
   NONE = 'onboard_none',
   V1 = 'onboard_v1',
   V2 = 'onboard_v2',
   V3 = 'onboard_v3',
}

const ONBARDING_ACTIVE_VARIANTS = [
   //
   OnboardingVariant.NONE,
   OnboardingVariant.V1,
   OnboardingVariant.V2,
];

export interface IVariantTesting {
   /** The subscription variant to use. */
   subVariant: SubscribeVariant;

   /** Fields Settings Nudge to use. */
   fieldSettingVariant: FieldSettingsNudgeVariant;

   /** Onboarding variant to use. */
   onboardVariant: OnboardingVariant;
}

/**
 * Service to register and manage user variants.
 */
@Injectable({
   providedIn: 'root',
})
export class SegmentSrv {
   protected _initialized: boolean = false;

   /** Current variant. */
   protected _settings: IVariantTesting = {
      subVariant: SubscribeVariant.SUB_EMPTY,
      fieldSettingVariant: FieldSettingsNudgeVariant.EMPTY,
      onboardVariant: OnboardingVariant.EMPTY,
   };

   constructor(protected trelloSrv: TrelloSrv) {
      //
   }

   get settings(): IVariantTesting {
      return this._settings;
   }
   get initialized(): boolean {
      return this._initialized;
   }

   /** Initialize and share the variant information with analytics. */
   initialize(t?: Trello.PowerUp.IFrame): void {
      this._initialized = true;
      const t_frame = this.trelloSrv.initialized ? this.trelloSrv.frame : t;
      assert(t_frame != null);

      this._settings.subVariant = getCohortVariant(t_frame, SUB_ACTIVE_VARIANTS);
      this._settings.fieldSettingVariant = getCohortVariant(t_frame, FIELDS_NUDGES_ACTIVE_VARIANTS);
      this._settings.onboardVariant = getCohortVariant(t_frame, ONBARDING_ACTIVE_VARIANTS);

      //this._settings.subVariant = SubscribeVariant.SUB_V12;

      // Now store on the user.
      /*
      this.analytics.setUserProperties({
         [GA_SUBSCRIPTION_VAR_PARAM_NAME]: this._settings.subVariant,
         [GA_FIELD_SETTINGS_VAR_PARAM_NAME]: this._settings.fieldSettingVariant,
         [GA_APP_VERSION_PARAM_NAME]: environment.app_version,
      });
      */
   }
}

/**
 * Return a variant of the options passed index based upon user_id modding.
 *
 * goal: provide even distribution across all users.
 *
 * Computed by taking the last 4 hex values of the user id,
 * and then moding that to the range.
 */
function getCohortVariant<ENUM_T>(t: Trello.PowerUp.IFrame, variantOpts: ENUM_T[]): ENUM_T {
   const user_id = t.getContext()?.member ?? '0000';
   const user_id_num = parseInt(user_id.slice(-4), 16);
   const opt_len = variantOpts.length;
   const opt_idx = user_id_num % opt_len;
   return variantOpts[opt_idx];
}
