import {isError} from 'lodash-es';

/** Strings in the error.name  */
export enum PostMessageIOError {
   PLUGIN_DISABLED = 'PostMessageIO:PluginDisabled',
   INVALID_CONTEXT = 'PostMessageIO:InvalidContext',
   NOT_HANDLED = 'PostMessageIO:NotHandled',
   UNSUPPORTED = 'PostMessageIO:UnsupportedCommand',
}



/** Return true iff this is a trello error of some type. */
export function isTrelloError(e: any): e is Error {
   return (
      isError(e) &&
      (e.name === PostMessageIOError.PLUGIN_DISABLED ||
         e.name === PostMessageIOError.INVALID_CONTEXT ||
         e.name === PostMessageIOError.NOT_HANDLED ||
         e.name === PostMessageIOError.UNSUPPORTED)
   );
}

/**
 * Custom error class.
 */
export class AppError extends Error {
   constructor(message?: string) {
      super(message);
      Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
      this.name = new.target.name;
   }
}

export function isAppError(e: any) {
   return e instanceof AppError;
}

/**
 * Sometimes Trello will have the powerup disabled.
 * This is the error we take and throw to the top.
 */
export class DisabledError extends AppError {
   constructor(message?: string) {
      super(message);
   }
}

export function isDisabledError(e: any) {
   return e instanceof DisabledError;
}

/**
 * Sometimes Trello will have issues initializing.
 * This error is meant to handle that case and continue on.
 */
export class InitError extends AppError {
   constructor(message?: string) {
      super(message);
   }
}

export function isInitError(e: any) {
   return e instanceof InitError;
}
