import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV11 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
// V11
interface IBoardConfigV11 {
   version: number;
   fields: (IFieldInfoV11 | INumberFieldV11)[];
}

interface IFieldInfoV11 {
   name: string;
   type: FieldTypeV11;
}

export enum NumberShowTypeV11 {
   NUMBER = 'N',
   PROGRESS_BAR = 'P',
}

export interface IProgressBarSettingsV11 {
   //barStyle: ProgressBarStyle;
   showNum: boolean;
   /*
   min: number;
   max: number;
   width: number;
   customBars?: string[];
   */
}

interface INumberFieldV11 extends IFieldInfoV11 {
   type: FieldTypeV11.NUMBER;

   showAs: NumberShowTypeV11;
   bar?: IProgressBarSettingsV11;
}

// V12

interface IBoardConfigV12 {
   version: number;
   fields: (IFieldInfoV12 | INumberFieldV12)[];
}

interface IFieldInfoV12 {
   name: string;
   type: FieldTypeV11;
}

export interface IProgressBarSettingsV12 {
   //barStyle: ProgressBarStyle;
   showNum: boolean;
   /*
   min: number;
   max: number;
   width: number;
   customBars?: string[];
   */

   /** Link to a checklist */
   linkChecklist: boolean;
}

interface INumberFieldV12 extends IFieldInfoV12 {
   type: FieldTypeV11.NUMBER;

   showAs: NumberShowTypeV11;
   bar?: IProgressBarSettingsV12;
}

/**
 * Adding options for linking to checklists
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addLinkCheckList_11_12: IFieldDataMigration = {
   targetVersion: 12,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 11);

      const incoming = c as unknown as IBoardConfigV11;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV12;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV11.NUMBER) {
            const f_out = f as INumberFieldV12;
            if (f_out.showAs === NumberShowTypeV11.PROGRESS_BAR && f_out.bar != null) {
               f_out.bar.linkChecklist = false;
            }
         }
      }

      // Set final version
      c.version = 12;
      return c;
   },
};
