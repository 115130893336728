import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IFieldConfigV16 {
   version: number;
   fields: IFieldInfoV16[];
}

interface IFieldInfoV16 {
   name: string;
}

interface IFieldConfigV17 {
   version: number;
   fields: IFieldInfoV17[];
}

interface IFieldInfoV17 {
   name: string;

   visCalc: {
      enabled: boolean;
      formula: any[];
   };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addVisCalc_16_17: IFieldDataMigration = {
   targetVersion: 17,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 16);

      const incoming = c as IFieldConfigV16;
      assert(incoming != null);

      const outgoing = c as IFieldConfigV17;

      // add hideCalc settings
      for (const f of outgoing.fields) {
         f.visCalc = {
            enabled: false,
            formula: [],
         };
      }

      // Set final version
      c.version = 17;
      return c;
   },
};
