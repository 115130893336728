import {APP_VERSION_ENV, firebase_staging, IN_PLAYWRIGHT} from './environment_base';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const environment = {
   production: false,
   local: false,
   firebase: firebase_staging,
   testSuite: false,
   e2eTest: IN_PLAYWRIGHT,
   app_version: APP_VERSION_ENV.version,
   app_hash: APP_VERSION_ENV.hash,
};
