import * as formulajs from '@formulajs/formulajs';
import {
   Formula,
   FormulaPartType,
   FormulaParts,
   RefParts,
   isRefPart,
   FieldDataTypes,
   ICardData,
   isTextExpr,
   ITextExpr,
   FieldType,
   IFieldInfo,
   UUID,
   supportsFieldValCalc,
   IFieldRef,
   CalcedFieldTypes,
   ICardDataValues,
} from '@shared/domain_types';
import dayjs from 'dayjs';
import jexl from 'jexl';
import {remove} from 'lodash-es';
import {assert} from '@util/assert';
import {isBlankString, isBoolean, isDate, isEmptyString, isNumber, isString} from '@util/utils';
import {ICardDataExt} from './card_data.srv';
import {convertToDateObj, getListTextFromValues} from './data_helpers';

/**
 * Custom Error type for formula.
 */
export class FormulaError extends Error {
   formula: Formula;

   constructor(formula: Formula = [], ...params: any[]) {
      // Pass remaining arguments (including vendor specific ones) to parent constructor
      super(...params);

      // Maintains proper stack trace for where our error was thrown (only available on V8)
      if (Error.captureStackTrace != null) {
         Error.captureStackTrace(this, FormulaError);
      }

      this.name = 'FormulaError';
      this.formula = formula;
   }
}

export function isFormulaError(e: any): e is FormulaError {
   return e instanceof FormulaError;
}

/** Context can have a Date object. */
export interface ICardFieldContext {
   [key: UUID]: FieldDataTypes | Date | object;
}

export interface IExtraContext {
   card: Pick<
      ICardDataExt,
      'desc' | 'dueComplete' | 'id' | 'idShort' | 'name' | 'url' | 'listName' | 'address'
   > & {due: Date | null; start: Date | null};
}

/** Helper to build extra context that we can add on to the card formula evaluation. */
export function buildExtraContext(card?: ICardDataExt): IExtraContext {
   function buildDate(d: string | null | undefined): Date | null {
      try {
         return isEmptyString(d) ? null : new Date(d!);
      } catch (e: any) {
         return null;
      }
   }

   const card_data: IExtraContext['card'] = {
      address: card?.address ?? '',
      desc: card?.desc ?? '',
      start: buildDate(card?.start),
      due: buildDate(card?.due),
      dueComplete: false,
      id: card?.id ?? '',
      idShort: card?.idShort ?? 1,
      name: card?.name ?? '',
      listName: card?.listName ?? '',
      url: card?.url ?? '',
   };
   return {card: card_data};
}

/**
 * Return a new formula with the part removed and repaired.
 */
export function removePart(inFormula: Formula, idx: number): Formula {
   // remove it
   const formula = [...inFormula];
   formula.splice(idx, 1);
   return repairFormula(formula);
}

/**
 * Insert a new ref into the formula in place at the given spot
 */
export function insertRef(
   inFormula: Formula,
   opts: {textIdx: number; insertPos: number; ref: RefParts},
): Formula {
   const formula = [...inFormula];
   // find the text to replace
   const cur_elt = formula[opts.textIdx];
   assert(cur_elt.type === FormulaPartType.TEXT);

   // split it
   const cur_text = cur_elt.text;
   const left_part = cur_text.substring(0, opts.insertPos);
   const right_part = cur_text.substring(opts.insertPos);

   const new_items: FormulaParts[] = [
      {type: FormulaPartType.TEXT, text: left_part},
      opts.ref,
      {type: FormulaPartType.TEXT, text: right_part},
   ];
   // put ref in the middle
   formula.splice(opts.textIdx, 1, ...new_items);

   // Repair any damage
   return repairFormula(formula);
}

/**
 * Return a new formula with repairs in place.
 *
 * Fixes:
 *  - Make sure there is at least one entry as text.
 *  - Remove duplicate text parts in a row
 *  - ensure there is a text at the start and end
 *  - ensure their is a text between any two refs
 */
export function repairFormula(inFormula: Formula): Formula {
   const formula = [...inFormula];

   if (formula.length === 0) {
      formula.push({type: FormulaPartType.TEXT, text: ''});
      return formula;
   }

   // make sure text at start and end
   if (formula[0].type !== FormulaPartType.TEXT) {
      formula.unshift({type: FormulaPartType.TEXT, text: ''});
   }
   if (formula[formula.length - 1].type !== FormulaPartType.TEXT) {
      formula.push({type: FormulaPartType.TEXT, text: ''});
   }

   // make sure no two refs back to back
   let first_idx;
   do {
      first_idx = formula.findIndex((_, i) => {
         return isRefPart(formula[i]) && formula[i + 1] != null && isRefPart(formula[i + 1]);
      });
      if (first_idx >= 0) {
         formula.splice(first_idx + 1, 0, {type: FormulaPartType.TEXT, text: ''});
      }
   } while (first_idx >= 0);

   // make sure no two text back to back
   do {
      first_idx = formula.findIndex((_, i) => {
         return isTextExpr(formula[i]) && formula[i + 1] != null && isTextExpr(formula[i + 1]);
      });
      if (first_idx >= 0) {
         const text_1 = formula[first_idx] as ITextExpr;
         const text_2 = formula[first_idx + 1] as ITextExpr;
         formula.splice(first_idx, 2, {
            type: FormulaPartType.TEXT,
            text: `${text_1.text}${text_2.text}`,
         });
      }
   } while (first_idx >= 0);

   return formula;
}

/**
 * Given a field definition, return the default value to
 * use for that field type in formulas.
 */
function getFieldFormulaDefaultValue(f: IFieldInfo): FieldDataTypes {
   if (f.type === FieldType.BOOL) {
      return false;
   } else if (f.type === FieldType.NUMBER) {
      return 0.0;
   } else if (f.type === FieldType.TEXT) {
      return '';
   } else if (f.type === FieldType.DATE) {
      return new Date(0).toISOString();
   } else if (f.type === FieldType.LIST) {
      return f.multi.enabled ? [] : '';
   }
   return null;
}

/** Return true if the given type can be used as a field input in a formula */
export function fieldTypeCanBeFormulaInput(fType: FieldType): boolean {
   return [
      FieldType.BOOL,
      FieldType.NUMBER,
      FieldType.TEXT,
      FieldType.DATE,
      FieldType.LIST,
   ].includes(fType);
}

/**
 * Evaluate all the formulas for fields on a given card.
 *
 * Sorts dependencies between the values and returns the new values after
 * evaluation
 */
export function computeCardFieldsWithCalcs(
   fields: IFieldInfo[],
   data: ICardData,
   extraContext: IExtraContext,
): ICardData {
   const calc_fields = fields.filter(
      (f): f is CalcedFieldTypes => supportsFieldValCalc(f) && f.calc.enabled,
   );
   if (calc_fields.length === 0) {
      return data;
   }

   // --- TOPO DEPS SORT -- //
   // Topo sort of calc fields based upon field deps to ensure calc in order
   const available_field_ids = fields
      .map((f) => f.id)
      .filter((fid) => calc_fields.find((x) => x.id === fid) == null);
   const sorted_calc_fields: IFieldInfo[] = [];

   /** True iff the deps for the formula are currently fullfilled */
   const depsFullfilled = (formula: Formula): boolean => {
      const dep_fids = formula
         .filter((p): p is IFieldRef => p.type === FormulaPartType.FIELD_REF)
         .map((p) => p.fid);
      return dep_fids.every((fid) => available_field_ids.includes(fid));
   };

   let last_calc_len = -1;
   while (calc_fields.length > 0 && last_calc_len !== calc_fields.length) {
      last_calc_len = calc_fields.length;

      // if dependencies fullfilled, move to sorted and available and continue
      for (const f of calc_fields) {
         if (f.calc.formula == null || depsFullfilled(f.calc.formula)) {
            sorted_calc_fields.push(f);
            available_field_ids.push(f.id);
            remove(calc_fields, (x) => x.id === f.id);
            break; // go back to the while
         }
      }
   }

   // if some could not sort, just add them on
   if (calc_fields.length > 0) {
      sorted_calc_fields.push(...calc_fields);
   }

   // -- PERFORM CALCULATIONS -- //
   // Perform the calculation using the sorted calc fields
   const out_data = {...data};

   for (const f of sorted_calc_fields) {
      assert(supportsFieldValCalc(f));
      const formula = f.calc.formula;
      if (formula != null) {
         const res = evalCardFormula(formula, out_data, f.type, fields, {
            fieldId: f.id,
            fieldName: f.name,
            extraContext,
         });
         out_data[f.id] = res;
      }
   }

   return out_data;
}

/**
 * Attempt to evaluate formula.
 *
 * If formula is empty, then return null.
 *
 * Note:
 *   - Adds NULL as a variable to the context to allow returning a null value.
 *
 * Throw exception if there are errors parsing or looking up data.
 *
 * Note: If a variable is referenced that doesn't exist it will just
 *       be undefined.  (see: https://github.com/TomFrost/Jexl/issues/117)
 */
export function evalCardFormula(
   formula: Formula,
   fieldData: ICardDataValues,
   destType: FieldType,
   fieldDefs: IFieldInfo[],
   opts: {fieldId: string; extraContext: IExtraContext; fieldName?: string},
): FieldDataTypes {
   assert(
      destType === FieldType.TEXT ||
         destType === FieldType.NUMBER ||
         destType === FieldType.BOOL ||
         destType === FieldType.DATE,
      'Invalid card formula dest type',
   );

   const varName = (fid: string) => `f_${fid.replace(/-/g, '')}`;

   /** Return the default value for the given field id. (used when value is null) */
   const getDefaultFieldVal = (fid: string): FieldDataTypes | undefined => {
      const f_def = fieldDefs.find((x) => x.id === fid);
      return f_def == null ? undefined : getFieldFormulaDefaultValue(f_def);
   };

   // build up context with safe variable names
   const context: ICardFieldContext = {
      // add preset NULL value
      NULL: null,
      // Add current value to the mix
      CURRENT_VALUE: fieldData[opts.fieldId] ?? null,

      // add extra context
      ...(opts.extraContext ?? {}),
   };

   for (const f of fieldDefs) {
      // Note: this may be undefined
      const fval = fieldData[f.id];
      const var_name = varName(f.id);
      context[var_name] = fval;

      // convert date from string to date object in context
      if (f.type === FieldType.DATE && fval != null) {
         context[var_name] = convertToDateObj(fval);
      }
      // List will be either a string or a string[] depending if multi
      else if (f.type === FieldType.LIST && fval != null) {
         let list_val: string | string[] = '';
         if (Array.isArray(fval)) {
            const string_vals = getListTextFromValues(f, fval);
            if (f.multi.enabled) {
               list_val = string_vals;
            } else {
               list_val = string_vals.length === 0 ? '' : string_vals[0];
            }
         }
         context[var_name] = list_val;
      }
   }

   // Build up the parts of the formula as strings to evaluate against the context
   const str_parts: string[] = [];

   for (const p of formula) {
      if (p.type === FormulaPartType.TEXT) {
         str_parts.push(p.text);
      } else if (p.type === FormulaPartType.FIELD_REF) {
         const f_var_name = varName(p.fid);
         // If we reference a field with null or missing value, attempt to fill it in
         if (context[f_var_name] == null) {
            const def_val = getDefaultFieldVal(p.fid);
            if (def_val === undefined) {
               const field_name_str = opts.fieldName != null ? `'${opts.fieldName}' ` : '';
               throw new FormulaError(
                  formula,
                  `Field ${field_name_str}formula includes a removed or missing field.`,
               );
            }
            context[f_var_name] = def_val;
         }

         str_parts.push(` ${f_var_name} `);
      }
   }
   const expr_str = str_parts.join(' ');

   // evaluate expression
   try {
      // If we have an "empty" formula, then return null
      if (isBlankString(expr_str)) {
         return null;
      }

      const res: any = jexl.evalSync(expr_str, context);

      if (destType === FieldType.DATE) {
         const date_obj = isDate(res) ? res : isString(res) ? convertToDateObj(res) : null;
         return date_obj == null ? null : date_obj.toISOString();
      } else if (destType === FieldType.TEXT) {
         return isString(res) || res == null ? res ?? null : `${res}`;
      } else if (destType === FieldType.BOOL) {
         if (isBoolean(res) || res === null) {
            return res;
         } else {
            try {
               return Boolean(res);
            } catch (e: any) {
               throw new Error(`Expected boolean value, got value '${res}'`);
            }
         }
      } else if (destType === FieldType.NUMBER) {
         if (isNumber(res) || res === null) {
            return res;
         } else if (isString(res)) {
            throw new Error(`Expected number value, got text value '${res}'`);
         } else if (res === Infinity || isNaN(res)) {
            throw new Error(`Out of range numeric result: ${res}`);
         }
      }

      throw new Error(`Unexpected result. ${res}`);

      // Attempt to convert to correct type
      //return res as FieldDataTypes;
   } catch (err: any) {
      const field_name_details = opts?.fieldName == null ? '' : `field: [${opts.fieldName}] `;
      throw new FormulaError(formula, `Formula failed: ${field_name_details}${err?.message}`);
   }
}

/**
 * Test calling the formula with fake data.
 *
 * Purpose is to see if any exceptions get thrown.
 */
export function testCardFormula(
   formula: Formula,
   destType: FieldType,
   fieldId: string,
   fields: IFieldInfo[],
): FieldDataTypes {
   const fake_values: ICardDataValues = {};

   for (const f of fields) {
      if (f.type === FieldType.TEXT) {
         fake_values[f.id] = 'str_val';
      } else if (f.type === FieldType.NUMBER) {
         fake_values[f.id] = 1.0;
      } else if (f.type === FieldType.BOOL) {
         fake_values[f.id] = true;
      } else if (f.type === FieldType.DATE) {
         fake_values[f.id] = new Date(0).toISOString();
      } else if (f.type === FieldType.LIST) {
         fake_values[f.id] = [];
      } else {
         fake_values[f.id] = null;
      }
   }

   return evalCardFormula(formula, fake_values, destType, fields, {
      fieldId,
      // Use default content
      extraContext: buildExtraContext(undefined),
   });
}

const JEXL_ADDONS_INIT = {
   FormulaJsLoaded: false,
   DateFuncLoaded: false,
};

function addFormulaJsFuncs(): void {
   if (!JEXL_ADDONS_INIT.FormulaJsLoaded) {
      JEXL_ADDONS_INIT.FormulaJsLoaded = true;

      /** eslint-disable @typescript-eslint/no-unsafe-assignment */
      /** eslint-disable @typescript-eslint/no-unsafe-member-access */

      // -- MATH -- //
      jexl.addFunctions({
         ABS: formulajs.ABS,
         ACOS: formulajs.ACOS,
         ACOSH: formulajs.ACOSH,
         ACOT: formulajs.ACOT,
         ACOTH: formulajs.ACOTH,
         AGGREGATE: formulajs.AGGREGATE,
         ARABIC: formulajs.ARABIC,
         ASIN: formulajs.ASIN,
         ASINH: formulajs.ASINH,
         ATAN: formulajs.ATAN,
         ATAN2: formulajs.ATAN2,
         ATANH: formulajs.ATANH,
         BASE: formulajs.BASE,
         CEILING: formulajs.CEILING,
         CEILINGMATH: formulajs.CEILINGMATH,
         CEILINGPRECISE: formulajs.CEILINGPRECISE,
         COMBIN: formulajs.COMBIN,
         COMBINA: formulajs.COMBINA,
         COS: formulajs.COS,
         COSH: formulajs.COSH,
         COT: formulajs.COT,
         COTH: formulajs.COTH,
         CSC: formulajs.CSC,
         CSCH: formulajs.CSCH,
         DECIMAL: formulajs.DECIMAL,
         ERF: formulajs.ERF,
         ERFC: formulajs.ERFC,
         EVEN: formulajs.EVEN,
         EXP: formulajs.EXP,
         FACT: formulajs.FACT,
         FACTDOUBLE: formulajs.FACTDOUBLE,
         FLOOR: formulajs.FLOOR,
         FLOORMATH: formulajs.FLOORMATH,
         FLOORPRECISE: formulajs.FLOORPRECISE,
         GCD: formulajs.GCD,
         INT: formulajs.INT,
         ISEVEN: formulajs.ISEVEN,
         //ISOCEILING: formulajs.ISOCEILING,
         ISODD: formulajs.ISODD,
         LCM: formulajs.LCM,
         LN: formulajs.LN,
         LOG: formulajs.LOG,
         LOG10: formulajs.LOG10,
         MOD: formulajs.MOD,
         MROUND: formulajs.MROUND,
         MULTINOMIAL: formulajs.MULTINOMIAL,
         ODD: formulajs.ODD,
         POWER: formulajs.POWER,
         PRODUCT: formulajs.PRODUCT,
         QUOTIENT: formulajs.QUOTIENT,
         RADIANS: formulajs.RADIANS,
         RAND: formulajs.RAND,
         RANDBETWEEN: formulajs.RANDBETWEEN,
         ROUND: formulajs.ROUND,
         ROUNDDOWN: formulajs.ROUNDDOWN,
         ROUNDUP: formulajs.ROUNDUP,
         SEC: formulajs.SEC,
         SECH: formulajs.SECH,
         SIGN: formulajs.SIGN,
         SIN: formulajs.SIN,
         SINH: formulajs.SINH,
         SQRT: formulajs.SQRT,
         SQRTPI: formulajs.SQRTPI,
         SUBTOTAL: formulajs.SUBTOTAL,
         SUM: formulajs.SUM,
         SUMIF: formulajs.SUMIF,
         SUMIFS: formulajs.SUMIFS,
         SUMPRODUCT: formulajs.SUMPRODUCT,
         SUMSQ: formulajs.SUMSQ,
         SUMX2MY2: formulajs.SUMX2MY2,
         SUMX2PY2: formulajs.SUMX2PY2,
         SUMXMY2: formulajs.SUMXMY2,
         TAN: formulajs.TAN,
         TANH: formulajs.TANH,
         TRUNC: formulajs.TRUNC,
      });

      // -- TEXT -- //
      jexl.addFunctions({
         CHAR: formulajs.CHAR,
         CLEAN: formulajs.CLEAN,
         CODE: formulajs.CODE,
         CONCATENATE: formulajs.CONCATENATE,
         EXACT: formulajs.EXACT,
         FIND: formulajs.FIND,
         LEFT: formulajs.LEFT,
         LEN: formulajs.LEN,
         LOWER: formulajs.LOWER,
         MID: formulajs.MID,
         NUMBERVALUE: formulajs.NUMBERVALUE,
         PROPER: formulajs.PROPER,
         REGEXEXTRACT: formulajs.REGEXEXTRACT,
         REGEXMATCH: formulajs.REGEXMATCH,
         REGEXREPLACE: formulajs.REGEXREPLACE,
         REPLACE: formulajs.REPLACE,
         REPT: formulajs.REPT,
         RIGHT: formulajs.RIGHT,
         ROMAN: formulajs.ROMAN,
         SEARCH: formulajs.SEARCH,
         SPLIT: formulajs.SPLIT,
         SUBSTITUTE: formulajs.SUBSTITUTE,
         T: formulajs.T,
         TRIM: formulajs.TRIM,
         UNICHAR: formulajs.UNICHAR,
         UNICODE: formulajs.UNICODE,
         UPPER: formulajs.UPPER,
      });

      // -- LOGICAL -- //
      jexl.addFunctions({
         AND: formulajs.AND,
         FALSE: formulajs.FALSE,
         IF: formulajs.IF,
         IFS: formulajs.IFS,
         IFERROR: formulajs.IFERROR,
         IFNA: formulajs.IFNA,
         NOT: formulajs.NOT,
         OR: formulajs.OR,
         SWITCH: formulajs.SWITCH,
         TRUE: formulajs.TRUE,
         XOR: formulajs.XOR,
      });

      // -- FINANCIAL -- //
      jexl.addFunctions({
         ACCRINT: formulajs.ACCRINT,
         CUMIPMT: formulajs.CUMIPMT,
         CUMPRINC: formulajs.CUMPRINC,
         DB: formulajs.DB,
         DDB: formulajs.DDB,
         DOLLARDE: formulajs.DOLLARDE,
         DOLLARFR: formulajs.DOLLARFR,
         EFFECT: formulajs.EFFECT,
         FV: formulajs.FV,
         FVSCHEDULE: formulajs.FVSCHEDULE,
         IPMT: formulajs.IPMT,
         IRR: formulajs.IRR,
         ISPMT: formulajs.ISPMT,
         MIRR: formulajs.MIRR,
         NOMINAL: formulajs.NOMINAL,
         NPER: formulajs.NPER,
         NPV: formulajs.NPV,
         PDURATION: formulajs.PDURATION,
         PMT: formulajs.PMT,
         PPMT: formulajs.PPMT,
         PV: formulajs.PV,
         RATE: formulajs.RATE,
      });

      // -- DATE -- //
      jexl.addFunctions({
         DATE: formulajs.DATE,
         DATEDIF: formulajs.DATEDIF,
         DATEVALUE: formulajs.DATEVALUE,
         DAY: formulajs.DAY,
         DAYS: formulajs.DAYS,
         DAYS360: formulajs.DAYS360,
         EDATE: formulajs.EDATE,
         EOMONTH: formulajs.EOMONTH,
         HOUR: formulajs.HOUR,
         MINUTE: formulajs.MINUTE,
         ISOWEEKNUM: formulajs.ISOWEEKNUM,
         MONTH: formulajs.MONTH,
         NETWORKDAYS: formulajs.NETWORKDAYS,
         NETWORKDAYSINTL: formulajs.NETWORKDAYSINTL,
         NOW: formulajs.NOW,
         SECOND: formulajs.SECOND,
         TIME: formulajs.TIME,
         TIMEVALUE: formulajs.TIMEVALUE,
         TODAY: formulajs.TODAY,
         WEEKDAY: formulajs.WEEKDAY,
         YEAR: formulajs.YEAR,
         WEEKNUM: formulajs.WEEKNUM,
         WORKDAY: formulajs.WORKDAY,
         WORKDAYINTL: formulajs.WORKDAYINTL,
         YEARFRAC: formulajs.YEARFRAC,
      });
   }
}

function addDateFunctions(): void {
   if (!JEXL_ADDONS_INIT.DateFuncLoaded) {
      JEXL_ADDONS_INIT.DateFuncLoaded = true;

      jexl.addFunction(
         'DATE_ADD',
         (
            startDate: Date | string | null,
            count: number,
            unit: dayjs.ManipulateType,
         ): Date | null => {
            if (startDate == null) {
               return null;
            }

            return dayjs(startDate).add(count, unit).toDate();
         },
      );

      jexl.addFunction(
         'DATE_SUBTRACT',
         (
            startDate: Date | string | null,
            count: number,
            unit: dayjs.ManipulateType,
         ): Date | null => {
            if (startDate == null) {
               return null;
            }

            return dayjs(startDate).subtract(count, unit).toDate();
         },
      );

      jexl.addFunction(
         'DATE_STARTOF',
         (startDate: Date | string | null, unit: dayjs.OpUnitType): Date | null => {
            if (startDate == null) {
               return null;
            }

            return dayjs(startDate).startOf(unit).toDate();
         },
      );

      jexl.addFunction(
         'DATE_ENDOF',
         (startDate: Date | string | null, unit: dayjs.OpUnitType): Date | null => {
            if (startDate == null) {
               return null;
            }

            return dayjs(startDate).endOf(unit).toDate();
         },
      );

      jexl.addFunction(
         'DATE_DIFF',
         (
            startDate: Date | string | null,
            endDate: Date | string | null,
            unit: dayjs.QUnitType | dayjs.OpUnitType,
            float: boolean = false,
         ): number | null => {
            if (startDate == null || endDate == null) {
               return null;
            }

            return dayjs(endDate).diff(startDate, unit, float);
         },
      );
   }
}

// Add the functions on first import
addFormulaJsFuncs();
addDateFunctions();
