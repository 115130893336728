/* eslint-disable @typescript-eslint/promise-function-async */
import {Injectable} from '@angular/core';
import {
   FieldType,
   ICardData,
   ICardDataValues,
   ICardHiddenFields,
   ICardRefFieldData,
} from '@shared/domain_types';
import {IChecklistsResp} from '@shared/trello_rest_types';
import axios from 'axios';
import {SentrySrv} from '@app/sentry.srv';
import {appLog, breadcrumb} from '@app/services/logging';
import {TrelloSrv} from '@app/services/trello.srv';
import {Trello} from '@trello/trello_powerup_client';
import {assert} from '@util/assert';
import {HttpStatus} from '@util/http_status';
import {cutoffString, isBoolean, isNumber, isString} from '@util/utils';
import {BoardConfigSrv} from './board_config.store';
import {CardDataStorageSrv, CURRENT_CARD_DATA_VERSION} from './card_data_storage.srv';
import {CustomFieldsSrv} from './custom_fields.srv';
import {isDataField, isHeaderField, isTabField} from './data_helpers';

import {
   buildExtraContext,
   computeCardFieldsWithCalcs,
   evalCardFormula,
   IExtraContext,
   isFormulaError,
} from './func_helpers';

export interface IChecklistSummary {
   /** Name of the checklist. */
   name: string;

   /** Total checklist items. */
   totalItems: number;

   /** Total completed. */
   completed: number;
}

/** Data for using in formulas. */
export interface ICardDataExt extends Trello.PowerUp.Card {
   listName: string;
}

/**
 * Service to manage user level access to card data.
 */
@Injectable({providedIn: 'root'})
export class CardDataSrv {
   constructor(
      protected cardDataStorageSrv: CardDataStorageSrv,
      protected boardSrv: BoardConfigSrv,
      protected custFieldSrv: CustomFieldsSrv,
      protected trelloSrv: TrelloSrv,
      protected sentrySrv: SentrySrv,
   ) {
      this.custFieldSrv.setCardDataSrv(this);
   }

   /**
    * Return the value for all known fields.
    *
    * Note: this may return more or less than currently configured.
    *
    * If card is passed, then pull out of plugin data.
    */
   async getCardFields(
      cardFrame: Trello.PowerUp.IFrame,
      cardOrId?: Pick<Trello.PowerUp.Card, 'id' | 'pluginData' | 'customFieldItems'> | string,
   ): Promise<ICardData | null> {
      const field_cfgs = this.boardSrv.query.getAll();
      const field_data = await this.cardDataStorageSrv.loadFields(cardFrame, field_cfgs, cardOrId);

      // Extend this to get custom fields as overrides as well
      const cust_fields = this.boardSrv.query.getCustomFields();
      if (cust_fields.length > 0) {
         const cf_values = await this.custFieldSrv.getCardCustomFieldValues({cardOrId, cardFrame});
         for (const cf of cust_fields) {
            field_data[cf.id] = cf_values[cf.id] ?? null;
         }
      }

      return field_data;
   }

   /**
    * Set the card values as requested.
    *
    * Returns final values set on cards after calculations and updates
    */
   async setCardFields(
      frame: Trello.PowerUp.IFrame,
      dataIn: ICardDataValues,
      cardId: string,
      opts: {
         computeCalcs: boolean;
         computeVis: boolean;
         overwriteCfFromNull?: boolean;

         /** If true or empty, apply the patch to custom fields as well. */
         writeCfFields?: boolean;
      },
   ): Promise<ICardData | null> {
      try {
         breadcrumb('CardDataStorageSrv:setCardFields', {data: {context: frame.getContext()}});
         const board_id = frame.getContext().board;
         const update_custom_fields = opts.writeCfFields ?? true;

         // Get the central server time (note: this is estimated)
         const cur_server_time_ms = (await this.custFieldSrv.getServerTimeMsec()) ?? 0;
         assert(isNumber(cur_server_time_ms));

         // NOTE: datain has AMF values as known in the UI and thus should have
         //      the latest CF values as well.  For patch case we just read all fiels including CF.
         dataIn.__version ??= CURRENT_CARD_DATA_VERSION;
         dataIn.__boardId = board_id;
         dataIn.__lastEditSrvMs = cur_server_time_ms;

         const card_data_in = dataIn as ICardData;
         let card_extra_content: IExtraContext = buildExtraContext(undefined);

         let card_data: ICardDataExt | null = null;

         if (opts.computeCalcs || opts.computeVis) {
            card_data = await this.getTrelloCardData(frame, cardId);
            card_extra_content = buildExtraContext(card_data ?? undefined);
         }

         appLog.info(`setCardFields: id: [${cardId}] card name: ${card_data?.name}`);

         // --- Evaluate Computed Fields --- //
         let calced_values: ICardData;
         try {
            if (opts.computeCalcs) {
               calced_values = this.computeCardFieldsWithCalcs(card_data_in, card_extra_content);
            } else {
               calced_values = card_data_in;
            }
         } catch (e: any) {
            if (isFormulaError(e)) {
               void this.trelloSrv.frame.alert({
                  message: cutoffString(`${e.message}`, 139),
                  display: 'error',
                  duration: 30,
               });
               // make a copy and continue with it
               calced_values = {...card_data_in};
            } else {
               throw e;
            }
         }

         // -- Update hidden fields in parallel if needed -- //
         const update_vis_prom = opts.computeVis
            ? this.computeHiddenFieldCalcs(frame, cardId, calced_values, card_extra_content)
            : Promise.resolve();

         // Store data
         await this.cardDataStorageSrv.storeCardFields(frame, calced_values, cardId);

         // Update custom fields with values
         if (update_custom_fields) {
            await this.custFieldSrv.updateCfValuesForCard(frame, calced_values, cardId, {
               overwriteCfFromNull: opts.overwriteCfFromNull,
            });
         }

         await update_vis_prom;
         return calced_values;
      } catch (e: unknown) {
         this.handleCommonErrors(frame, e);
      }
      return null;
   }

   /**
    * Apply the attached card values as a patch to the current card values.
    */
   async patchCardField(
      cardFrame: Trello.PowerUp.IFrame,
      patch: ICardDataValues,
      cardId: string,
      o?: {
         /** see: updateCfValuesForCard */
         overwriteCfFromNull?: boolean;

         /** If true or empty, apply the patch to custom fields as well. */
         writeCfFields?: boolean;
      },
   ): Promise<void> {
      breadcrumb('CardDataSrv:patchCardField', {data: {context: cardFrame.getContext()}});
      const cur_fields = await this.getCardFields(cardFrame, cardId);
      const new_field_values = {...cur_fields, ...patch};
      await this.setCardFields(cardFrame, new_field_values, cardId, {
         computeCalcs: true,
         computeVis: true,
         overwriteCfFromNull: o?.overwriteCfFromNull ?? true,
         writeCfFields: o?.writeCfFields,
      });
   }

   /**
    * Return the list of hidden fields
    *
    * Note: this may return more or less than currently configured.
    */
   async getHiddenFields(
      cardFrame: Trello.PowerUp.IFrame,
      cardId?: string,
   ): Promise<ICardHiddenFields> {
      const card_id = cardId ?? 'card';

      let retval: ICardHiddenFields | null = await this.cardDataStorageSrv.loadHiddenFields(
         cardFrame,
         card_id,
      );

      // If nothing defined or we had failured decoding, then lookup defaults
      if (retval == null) {
         const f_cfg = this.boardSrv.query.getAll();
         retval = {
            hidden: f_cfg
               .filter((f) => {
                  // note: default is set here until visCalc may come in
                  if (isDataField(f)) {
                     return f.show.hide;
                  } else if (isTabField(f) || isHeaderField(f)) {
                     return f.hide;
                  } else {
                     return false;
                  }
               })
               .map((f) => f.id),
         };
      }

      assert(retval != null, 'Hidden fields request returned invalid data.');
      return retval;
   }

   /**
    * Set the card values that are hidden;
    */
   setHiddenFields(
      frame: Trello.PowerUp.IFrame,
      cardId: string,
      data: ICardHiddenFields,
   ): Promise<void> {
      return this.cardDataStorageSrv.storeHiddenFields(frame, cardId, data);
   }

   /**
    * Set the hidden state of one field on a card.  Automatically updates
    * the hidden field list.
    */
   async setFieldHidden(
      frame: Trello.PowerUp.IFrame,
      cardId: string,
      fieldId: string,
      setHidden: boolean,
   ): Promise<void> {
      const data = await this.getHiddenFields(frame, cardId);

      if (setHidden) {
         if (!data.hidden.includes(fieldId)) {
            data.hidden.push(fieldId);
            await this.setHiddenFields(frame, cardId, data);
         }
      } else {
         if (data.hidden.includes(fieldId)) {
            data.hidden = data.hidden.filter((i) => i !== fieldId);
            await this.setHiddenFields(frame, cardId, data);
         }
      }
   }

   /**
    * Return summary of checklist data for the given card.
    */
   async getCardChecklists(
      cardFrame: Trello.PowerUp.IFrame,
      cardId?: string,
   ): Promise<IChecklistSummary[] | null> {
      const card_id = cardId ?? cardFrame.getContext().card ?? null;

      // If we don't have a valid card or if we are not authorized to make request, then return null
      if (card_id == null || !this.trelloSrv.authorized) {
         return null;
      } else {
         try {
            const api = this.trelloSrv.apiRetry;
            const resp = await api.get<IChecklistsResp[]>(`/cards/${card_id}/checklists`);
            const data = resp.data;

            const summary: IChecklistSummary[] = data.map((cl) => {
               return {
                  name: cl.name,
                  totalItems: cl.checkItems.length,
                  completed: cl.checkItems.filter((i) => i.state === 'complete').length,
               };
            });
            return summary;
         } catch (e: any) {
            appLog.warn('Card checklist request failed: ', e);
            if (axios.isAxiosError(e)) {
               if (e.response) {
                  // If not found, then assume card is configured incorrectly
                  // If unauthorized, then we can't read it anyway yet
                  // If too many requests, then nothing we can do right now
                  if (
                     e.response.status === HttpStatus.NOT_FOUND ||
                     e.response.status === HttpStatus.UNAUTHORIZED ||
                     e.response.status === HttpStatus.TOO_MANY_REQUESTS
                  ) {
                     return null;
                  }
                  breadcrumb('getCardChecklists: AxiosError: ', {
                     data: {
                        message: e.message,
                        headers: e.response.headers,
                        status: e.response.status,
                        statusText: e.response.statusText,
                        perms: this.trelloSrv.permissions,
                        authorized: this.trelloSrv.authorized,
                     },
                  });
               }
            }
            this.sentrySrv.handleError(e, 'getCardChecklists');
            return null;
         }
      }
   }

   /**
    * Return details of referenced cards
    */
   async getCardRefCards(data: ICardRefFieldData | undefined): Promise<Trello.PowerUp.Card[]> {
      const ret_val: Trello.PowerUp.Card[] = [];
      if (data == null) {
         return [];
      }

      for (const card_id of data.ids) {
         try {
            const card_resp = await this.trelloSrv.apiRetry.get<Trello.PowerUp.Card>(
               `/cards/${card_id}`,
               {
                  params: {
                     fields: 'name,idBoard',
                  },
               },
            );
            const card_data = card_resp.data;
            ret_val.push(card_data);
         } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
               if (e.response?.status === HttpStatus.UNAUTHORIZED) {
                  ret_val.push({name: 'Unauthorized Card', id: card_id} as Trello.PowerUp.Card);
               }
            }
         }
      }

      return ret_val;
   }

   /**
    * Given a set of card fields, return new set of field values with any calculations updated.
    */
   computeCardFieldsWithCalcs(data: ICardData, extraContext: IExtraContext): ICardData {
      const fields = this.boardSrv.query.getAll();
      return computeCardFieldsWithCalcs(fields, data, extraContext);
   }

   /**
    * Given a set of card fields, recompute field visibility as needed
    */
   async computeHiddenFieldCalcs(
      cardFrame: Trello.PowerUp.IFrame,
      cardId: string,
      data: ICardData,
      extraContext: IExtraContext,
   ): Promise<void> {
      const fields = this.boardSrv.query.getAll();
      const vis_calc_fields = fields.filter((f) => f.visCalc.enabled);
      if (vis_calc_fields.length === 0) {
         return;
      }

      const hidden_fields = await this.getHiddenFields(cardFrame, cardId);

      const setVisState = (fid: string, shouldShow: boolean): void => {
         const is_hidden = hidden_fields.hidden.includes(fid);

         if (is_hidden && shouldShow) {
            // remove id from hidden set
            hidden_fields.hidden = hidden_fields.hidden.filter((x) => x !== fid);
         } else if (!is_hidden && !shouldShow) {
            // add to hidden set
            hidden_fields.hidden.push(fid);
         }
      };

      for (const f of vis_calc_fields) {
         const formula = f.visCalc.formula;
         if (formula != null) {
            const vis_resp = evalCardFormula(formula, data, FieldType.BOOL, fields, {
               fieldId: f.id,
               fieldName: `visibility:${f.name}`,
               extraContext,
            });
            if (!Array.isArray(vis_resp)) {
               const show = isBoolean(vis_resp)
                  ? vis_resp
                  : isString(vis_resp)
                  ? vis_resp !== ''
                  : isNumber(vis_resp)
                  ? vis_resp !== 0
                  : false;
               setVisState(f.id, show);
            }
         }
      }

      await this.setHiddenFields(cardFrame, cardId, hidden_fields);
   }

   /**
    * Return card data when we only have a frame with a board.
    *
    * Note: this should be the same as t.card('all') but we can't use that on just a board.
    */
   async getTrelloCardData(
      cardFrame: Trello.PowerUp.IFrame,
      cardId: string,
   ): Promise<ICardDataExt | null> {
      const [all_cards, lists] = await Promise.all([
         cardFrame.cards('all'),
         cardFrame.lists('id', 'name'),
      ]);

      for (const c of all_cards) {
         if (c.id === cardId) {
            const card: ICardDataExt = {...c, listName: ''};
            const found_list = lists.find((l) => l.id === card.idList);
            card.listName = found_list?.name ?? '';
            return card;
         }
      }
      return null;
   }

   /**
    * Either handle the error or rethrow it.
    *
    * When handling, we may present an alert to the user.
    */
   protected handleCommonErrors(t: Trello.PowerUp.IFrame, e: unknown): void {
      // Trello set errors are of type Error
      if (isFormulaError(e)) {
         void t.alert({
            message: cutoffString(`Error in formula. ${e.message}`, 138),
            display: 'error',
            duration: 30,
         });
      } else if (e instanceof Error) {
         if (/PluginData length.*exceeded/.test(e.message)) {
            appLog.warn('Card data storage exceeded. Save failed.');
            void t.alert({
               message:
                  'Card Storage Exceeded: Trello limits the amount of data stored in cards.  Please try to reduce the size of the fields.',
               display: 'error',
               duration: 30,
            });
         } else if (e.message.includes('Scope not editable')) {
            appLog.warn('Card data save failed because scope is not editable.');
            void t.alert({
               message:
                  'You do not have permission to edit card data. Please check your board permissions.',
               display: 'error',
               duration: 30,
            });
         } else {
            this.sentrySrv.handleError(e, 'Error Saving Card Data [A]');
            void t.alert({
               message: 'Error saving card data. Please refresh and try again. [DataSave-A]',
               display: 'error',
               duration: 30,
            });

            //e.message = `Error Saving Card Data [A]: ${e?.message}`;
            //throw e;
         }
      } else {
         this.sentrySrv.handleError(e, 'Error Saving Card Data [B]');
         void t.alert({
            message: 'Error saving card data. Please refresh and try again. [DataSave-B]',
            display: 'error',
            duration: 30,
         });
         //throw e;
      }
   }
}
