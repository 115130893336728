/**
 * Pseudo UUID from: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
 */
export function uuidv4(): string {
   /* eslint-disable */
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {

      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
   });
   /* eslint-enable */
}

export function canAccessLocalStorage(): boolean {
   try {
      window.localStorage.setItem('amz_fields_test', 'success');
      return true;
   } catch (e: any) {
      return false;
   }
}

/** Some browsers (firefox) have issue initializing due to browser security settings
 * when 3rd party cookies are disabled.
 */
export function canFirebaseInitWithBrowserSecurity(): boolean {
   try {
      // @ts-expect-error don't need to use value
      const val = typeof indexedDB === 'object';
      return true;
   } catch (e: any) {
      return false;
   }
}

/** Return true if the value is a function. */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: any): value is Function {
   return typeof value === 'function';
}

/** Return true iff the value is a boolean. */
export function isBoolean(value: any): value is boolean {
   return typeof value === 'boolean';
}

/** Return true if we have a Date. */
export function isDate(value: any): value is Date {
   return value instanceof Date; //&& isFunction(value.getMonth);
}

/** Test if is string. */
export function isString(value: any): value is string {
   return typeof value === 'string';
}

/** Return true if a number. */
export function isNumber(value: any): value is number {
   return !isNaN(value - parseFloat(value));
}

/** Return true if the string is '' or all blanks. */
export function isBlankString(s: string): boolean {
   return s === '' || s.trim() === '';
}

/** Return true if the string is '' or null/undefined */
export function isEmptyString(s: string | null | undefined): boolean {
   return s === '' || s == null;
}

/**
 * Compare two things equal deeply.
 *
 * from: https://stackoverflow.com/questions/48596405
 *
 * @deprecated use lodash-es isEqual
 */
export function jsonEq(a: any, b: any): boolean {
   return JSON.stringify(a) === JSON.stringify(b);
}

export function isValidDate(d: Date | any): d is Date {
   return d instanceof Date && !isNaN(d as any);
}

export function isUrlLike(u: string): boolean {
   return (
      u.startsWith('http://') ||
      u.startsWith('https://') ||
      u.startsWith('file:/') ||
      u.startsWith('mailto:') ||
      u.startsWith('tel:')
   );
}

/**
 * Cuttoff the length of a string and add elipsis to the end if
 * it exceeds the length.
 */
export function cutoffString(val: string, maxLen: number): string {
   return val.length > maxLen ? `${val.substring(0, maxLen - 1)}…` : val;
}
