import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
// V6
interface IBoardConfigV6 {
   version: number;
   fields: (IFieldInfoV6 | IDateFieldV6)[];
}

enum FieldTypeV6 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

interface IFieldInfoV6 {
   name: string;
   type: FieldTypeV6;
}

interface IDateFieldV6 extends IFieldInfoV6 {
   type: FieldTypeV6.DATE;
   color?: IDateColorSettingsV6;
}

interface IDateColorSettingsV6 {
   enabled: boolean;
   base: string | null;
   rules: IDateColorRuleV6[];
}

interface IDateColorRuleV6 {
   op: DateRuleOpV6;
   val: Date | KnownDatesV6;
   color: string;
}

enum DateRuleOpV6 {
   IS = 'i',
   BEFORE = 'b',
   AFTER = 'a',
}

enum KnownDatesV6 {
   TODAY = 't',
   YESTERDAY = 'y',
   PAST = 'p',
   FUTURE = 'f',
   TOMORROW = 'w',
}

// V7
interface IBoardConfigV7 {
   version: number;
   fields: (IFieldInfoV7 | IDateFieldV7)[];
}

interface IFieldInfoV7 {
   name: string;
   type: FieldTypeV6;
}

interface IDateFieldV7 extends IFieldInfoV7 {
   type: FieldTypeV6.DATE;
   color?: IDateColorSettingsV7;
}

interface IDateColorSettingsV7 {
   enabled: boolean;
   base: string | null;
   rules: IDateColorRuleV7[];
}

interface IDateColorRuleV7 {
   op: DateRuleOpV6;
   offset: number;
   unit: DateUnitsV7;
   color: string;
}

enum DateUnitsV7 {
   MINUTE = 'm',
   HOUR = 'h',
   DAY = 'D',
   WEEK = 'S',
   MONTH = 'M',
   YEAR = 'Y',
}

/**
 * Change date rules to use date offsets.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const refactorDateRules_6_7: IFieldDataMigration = {
   targetVersion: 7,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 6);

      const incoming = c as unknown as IBoardConfigV6;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV7;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV6.DATE) {
            const f_in = f as IDateFieldV6;
            const f_out = f as IDateFieldV7;

            if (f_in.color != null) {
               const rules = f_in.color.rules;
               const new_rules: IDateColorRuleV7[] = rules.map(
                  (r): IDateColorRuleV7 => ({
                     op: r.op,
                     color: r.color,
                     offset:
                        r.val === KnownDatesV6.TODAY
                           ? 0
                           : r.val === KnownDatesV6.YESTERDAY
                           ? -1
                           : r.val === KnownDatesV6.TOMORROW
                           ? 1
                           : r.val === KnownDatesV6.PAST
                           ? -1
                           : r.val === KnownDatesV6.FUTURE
                           ? 1
                           : 0,
                     unit: DateUnitsV7.DAY,
                  }),
               );
               assert(f_out.color != null);
               f_out.color.rules = new_rules;
            }
         }
      }

      // Set final version
      c.version = 7;
      return c;
   },
};
