import {Component, Injectable} from '@angular/core';
import {
   GA_LOCAL_STORAGE_FAIL_DISABLE_EVENT_NAME,
   GA_LOCAL_STORAGE_FAIL_ENABLE_EVENT_NAME,
   GA_LOCAL_STORAGE_FAIL_INIT_EVENT_NAME,
} from '@shared/shared_analytics_events';
import {Trello} from 'src/trello';
import {AMAZING_FIELDS_APP_NAME} from 'src/trello/powerup_settings';

import {AMAZING_FIELDS_SUPPORT_EMAIL, SECTION_ICON} from '@app/app_config';
import {SentrySrv} from '@app/sentry.srv';
import {BackendFunctionsSrv} from '@app/services/backend_functions.srv';
import {appLog, breadcrumb} from '@app/services/logging';

import {IHookFunctions} from './hook_interfaces';

/**
 * Component to show when localStorage can't be accessed.
 */
@Component({
   selector: 'tr-cookies-warning',
   template: `<div>
      <h1>Power-Up Loading Failed</h1>
      <p>Your current browser settings prevent Trello from initializing power-ups.</p>

      <p>
         Trello uses the browser to store local authorization settings. Your browser's current
         settings deny this access.</p
      >

      <p
         >To continue using Trello power-ups, please follow the instructions for
         <a [href]="supportLink" target="_blank">enabling localStorage access</a>. If you need
         further assistance please contact
         <a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a
         >.
      </p>

      <div trCenter>
         <a [href]="supportLink" target="_blank"
            ><button class="mod-primary">Trello Support Article</button></a
         >
      </div>
   </div>`,
   styleUrls: ['./cookies_warning.comp.scss'],
})
export class CookieWarningComponent {
   /** Link to the Trello article about how to resolve. */
   supportLink: string = 'https://help.trello.com/article/1093-enabling-localstorage';

   supportEmail: string = AMAZING_FIELDS_SUPPORT_EMAIL;
}

/**
 * All the hooks for initializing with Trello to take actions.
 */
@Injectable({
   providedIn: 'root',
})
export class CookieFailCallbacksSrv implements IHookFunctions {
   fnSrv: BackendFunctionsSrv;

   constructor(protected sentrySrv: SentrySrv) {
      breadcrumb('CookieFailCallbackSrv:constructor: called');

      this.fnSrv = new BackendFunctionsSrv(sentrySrv, undefined);

      // Log an event so we know how many people are seeing this issue.
      this.fnSrv.logEvent({
         message: 'LocalStorge failure detected',
         eventName: GA_LOCAL_STORAGE_FAIL_INIT_EVENT_NAME,
         sendToGa: true,
      });
   }

   /**
    * Called when powerup is enabled.
    */
   async onEnableHook(t: Trello.PowerUp.IFrame): Promise<void> {
      appLog.log('Powerup enabled');
      // Log an event so we know how many people are seeing this issue.
      this.fnSrv.logEvent({
         message: 'Powerup Enabled with Local Storage Failure',
         eventName: GA_LOCAL_STORAGE_FAIL_ENABLE_EVENT_NAME,
         sendToGa: true,
      });

      return this.showSettingsHook(t);
   }

   // eslint-disable-next-line @typescript-eslint/require-await
   async boardButtonsHook(t: Trello.PowerUp.IFrame): Promise<Trello.PowerUp.BoardButtonCallback[]> {
      const section_icon_path = `${window.location.origin}${SECTION_ICON}`;

      const settings_btn: Trello.PowerUp.BoardButtonCallback = {
         text: 'Fields',
         icon: {dark: section_icon_path, light: section_icon_path},
         callback: async () => {
            return this.showSettingsHook(t);
         },
         condition: 'edit',
      };

      return [settings_btn];
   }

   /**
    * User asked for settings, show we show them warning.
    */
   // eslint-disable-next-line @typescript-eslint/require-await
   async showSettingsHook(t: Trello.PowerUp.IFrame): Promise<void> {
      void t.modal({
         url: './init/cookie_warning',
         title: `Trello Power-Up Error`,
         height: 250,
         fullscreen: false,
         accentColor: 'orange', //'#026AA7', //'business-blue',
         callback: () => appLog.log('cookie warning closed'),
      });
      return;
   }

   /**
    * Redirect card back to a cookie warning message.
    */
   async cardBackSectionHook(t: Trello.PowerUp.IFrame): Promise<Trello.PowerUp.CardBackSection> {
      await Promise.resolve(true);

      const section_icon_path = `${window.location.origin}${SECTION_ICON}`;

      return {
         title: AMAZING_FIELDS_APP_NAME,
         icon: section_icon_path,
         content: {
            type: 'iframe',
            url: t.signUrl('./init/cookie_warning'),
            height: 300,
         },
      };
   }

   onDisableHook(t: Trello.PowerUp.IFrame): void {
      // Log an event so we know how many people are seeing this issue.
      this.fnSrv.logEvent({
         message: 'Powerup Disabled after Local Storage Failure',
         eventName: GA_LOCAL_STORAGE_FAIL_DISABLE_EVENT_NAME,
         sendToGa: true,
      });

      void t.alert({
         message: `Thanks for trying ${AMAZING_FIELDS_APP_NAME}. Please let us know how we can improve. ${AMAZING_FIELDS_SUPPORT_EMAIL}`,
         duration: 30,
      });
   }

   /** Used for a null hook */
   doNothing(_t: Trello.PowerUp.IFrame): void {
      //
   }
}
