/**
 * Override marked renderer to make safe links.
 *
 * See: https://github.com/jfcere/ngx-markdown/issues/332
 */

import {MarkedOptions, MarkedRenderer} from 'ngx-markdown';

/**
 * function that returns `MarkedOptions` with renderer override
 *
 * See: see: https://marked.js.org/using_advanced#options
 */
export function markedOptionsFactory(): MarkedOptions {
   const renderer = new MarkedRenderer();

   const linkRenderer = renderer.link;
   renderer.link = (href, title, text) => {
      const html = linkRenderer.call(renderer, href, title, text);
      return html.replace(/^<a /, '<a target="_blank" rel="nofollow noreferrer noopener" ');
   };

   return {
      renderer,

      gfm: true,
      breaks: true,
      pedantic: false,

      smartLists: true,
      smartypants: false,
   };
}
