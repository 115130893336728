import {Injectable} from '@angular/core';
import {
   FieldType,
   ICardData,
   ICardDataValues,
   IDataFieldBase,
   IListOption,
} from '@shared/domain_types';
import {
   ICustomFieldDesc,
   ICustomFieldOption,
   ICustomFieldOptionDef,
   TrelloCustomFieldTypes,
} from '@shared/trello_rest_types';
import {isArray, isEqual} from 'lodash-es';
import {TrelloSrv} from 'src/app/services/trello.srv';
import {HttpStatus, isString} from 'src/util';

import {SentrySrv} from '@app/sentry.srv';
import {BackendFunctionsSrv} from '@app/services/backend_functions.srv';
import {appLog} from '@app/services/logging';
import {AppError} from '@trello/trello_errors';
import {Trello} from '@trello/trello_powerup_client';
import {assert} from '@util/assert';
import {uuidv4} from '@util/utils';
import {BoardConfigSrv} from './board_config.store';

import {CardDataSrv} from './card_data.srv';
import {
   buildCfValForAmf,
   getCustomFieldType,
   getAmfValFromCf,
   getCustomFieldColor,
   isDataField,
   getAmfColorFromCustFieldColor,
} from './data_helpers';

/**
 * Used to identify fields that we created.
 * This allows the fields to be hidden by browser extensions.
 */
const CUST_FIELD_NAME_PREFIX = '_';

export interface ICreateCustomFieldOptions {
   name: string;
   type: TrelloCustomFieldTypes;
   pos: 'top' | 'bottom';
   display_cardFront: boolean;
}

export interface IUpdateCustomFieldOptions {
   name?: string;
   pos?: 'top' | 'bottom';
   display_cardFront?: boolean;
}

/** True if the name may be one we created. */
export function custFieldNameMayBeAmfOwned(name: string): boolean {
   return name.startsWith(CUST_FIELD_NAME_PREFIX);
}

/** Progress callback that takes a percent value 0.0-1.0 of completion. */
type ProgressCbFunc = (pct: number) => void;

/**
 * Wrapper for accessing and querying the custom field
 * data from the Trello Plugin.
 */
@Injectable({
   providedIn: 'root',
})
export class CustomFieldsSrv {
   protected _fieldCache: ICustomFieldDesc[] = [];

   /** The last checked set of custom field cards.
    * Note: this is not always card data, sometimes it is random data to force recheck.
    */
   protected lastCustomFieldCards: any = [];

   /** Value to add to local time to get server time. */
   protected srvTimeOffset: number = 0;

   // Service dependencies that are a cycle.
   protected boardCfgSrv: BoardConfigSrv | null = null;
   protected cardDataSrv: CardDataSrv | null = null;

   constructor(
      protected trelloSrv: TrelloSrv,
      protected fnSrv: BackendFunctionsSrv,
      protected sentrySrv: SentrySrv,
   ) {}

   get fields(): ICustomFieldDesc[] {
      return this._fieldCache;
   }

   setBoardCfgSrv(b: BoardConfigSrv): void {
      this.boardCfgSrv = b;
   }
   setCardDataSrv(c: CardDataSrv): void {
      this.cardDataSrv = c;
   }

   /**
    * Return true if we think the board could support custom fields.
    *
    * This uses a heuristic of if a free board with no custom fields,
    * then probably doesn't support them.
    */
   async boardSupportsCustomFields(frame: Trello.PowerUp.IFrame): Promise<boolean> {
      //return this.trelloSrv.member != null && this.trelloSrv.member.paidStatus !== 'free';
      try {
         const board_details = await frame.board('all');
         const free_board = board_details.paidStatus === 'free';
         const has_fields = (board_details.customFields?.length ?? 0) > 0;
         return !free_board || has_fields;
      } catch (e: any) {
         // If we can't get board details it likely means bad context, so we can't get custom fields
         return false;
      }
   }

   /**
    * Return UTC server epoch time in milliseconds or null if we can't determine it.
    */
   async getServerTimeMsec(allowEst = true): Promise<number | null> {
      const getRemoteServerTime = async (): Promise<number | null> => {
         const server_time = await this.fnSrv.serverTime();
         return server_time?.time ?? null;
      };

      if (!allowEst) {
         const server_time = await getRemoteServerTime();
         return server_time;
      } else if (this.srvTimeOffset === 0) {
         const srv_time = await getRemoteServerTime();
         if (srv_time != null) {
            const local_time = Date.now();
            this.srvTimeOffset = srv_time - local_time;
            appLog.debug(
               `Setting srv time offset: ${this.srvTimeOffset}  - local: ${local_time}  srv: ${srv_time}`,
            );
         }
         return srv_time;
      } else {
         const cur_local_time_ms = Date.now();
         const est_srv_time_ms = cur_local_time_ms + this.srvTimeOffset;
         return est_srv_time_ms;
      }
   }

   // ---- API WRAPPERS ---- //
   // Calls that help configure, read, or write custom fields.

   /**
    * Get the value of all custom fields for the given card.
    *
    * @param cardId The card id to get custom fields for.  If null, then pull from context.
    *
    * Note: pulling from context is *massively* faster.
    */
   async getCardCustomFields(opts: {
      cardOrId?: string | Pick<Trello.PowerUp.Card, 'id' | 'pluginData' | 'customFieldItems'>;
      cardFrame?: Trello.PowerUp.IFrame;
      useRest?: boolean;
   }): Promise<Trello.PowerUp.CustomField[]> {
      const use_rest = opts.useRest ?? false;
      const card_or_id = opts.cardOrId ?? null;
      const card_frame = opts.cardFrame ?? null;

      if (use_rest && isString(card_or_id)) {
         const api = this.trelloSrv.apiRetry;
         const resp = await api.get<Trello.PowerUp.CustomField[]>(
            `/cards/${card_or_id}/customFieldItems`,
         );
         return resp.data;
      } else if (isString(card_or_id)) {
         assert(card_frame != null);
         // Seems heavy, but prevents a rest call from happening
         const all_cards = await card_frame.cards('id', 'customFieldItems');
         const card = all_cards.find((c) => c.id === card_or_id);
         const cf_items = card?.customFieldItems ?? [];
         return cf_items;
      } else if (card_or_id == null) {
         assert(card_frame != null);
         const card = await card_frame.card('id', 'customFieldItems');
         const cf_items = card.customFieldItems;
         return cf_items;
      } else {
         const cf_items = card_or_id.customFieldItems;
         return cf_items;
      }
   }

   /**
    * Get the value of custom fields for the given card.
    */
   async getAllBoardCards(
      boardId: string,
   ): Promise<Pick<Trello.PowerUp.Card, 'id' | 'name' | 'customFieldItems' | 'pluginData'>[]> {
      const api = this.trelloSrv.apiRetry;
      const resp = await api.get<Trello.PowerUp.Card[]>(`/boards/${boardId}/cards`, {
         params: {
            // get name and id
            fields: 'name',
            customFieldItems: 'true',
            pluginData: 'true',
         },
      });
      return resp.data;
   }

   /**
    * Set the value of a single custom field
    */
   async setCardCustomFieldValue(
      cardId: string,
      fieldId: string,
      /** Used to set the value when a base type field. */
      value?: Trello.PowerUp.CustomFieldValue | null,
      /** Used when we have a dropdown type field. */
      idValue?: string,
   ): Promise<any> {
      const api = this.trelloSrv.apiRetry;
      const patch = idValue === undefined ? {value: value == null ? '' : value} : {idValue};
      const resp = await api.put<any>(`/cards/${cardId}/customField/${fieldId}/item`, patch);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return resp.data;
   }

   /**
    * Get a list of all the custom field configs for the current board.
    * If there are none, then returns empty list.  If failure then
    * return null.
    *
    * TODO: Think about caching
    */
   async getAllCustomFields(): Promise<ICustomFieldDesc[] | null> {
      const api = this.trelloSrv.apiRetry;
      const board = this.trelloSrv.context.board;

      const cust_field_resp = await api.get<ICustomFieldDesc[]>(`/boards/${board}/customFields`);

      if (cust_field_resp.status === HttpStatus.OK) {
         const results: ICustomFieldDesc[] = cust_field_resp.data;
         return results;
      } else {
         return null;
      }
   }

   /**
    * Create a new custom field on the given board.
    *
    * Handle the case where we can't create one because of too many fields.
    *
    * @throws Axios errors related to the call.
    */
   async createCustomField(opts: ICreateCustomFieldOptions): Promise<ICustomFieldDesc> {
      const api = this.trelloSrv.apiRetry;
      const board = this.trelloSrv.context.board;

      const resp = await api.post<ICustomFieldDesc>(`/customFields`, {
         ...opts,
         idModel: board,
         modelType: 'board',
      });
      const results: ICustomFieldDesc = resp.data;
      return results;
   }

   /**
    * Update the given custom field definition.
    */
   async updateCustomField(id: string, opts: IUpdateCustomFieldOptions): Promise<ICustomFieldDesc> {
      const api = this.trelloSrv.apiRetry;
      const resp = await api.put<ICustomFieldDesc>(`/customFields/${id}`, {
         ...opts,
         ...(opts.display_cardFront == null ? {} : {'display/cardFront': opts.display_cardFront}),
      });
      const results: ICustomFieldDesc = resp.data;
      return results;
   }

   /**
    * Delete the custom field.
    *
    * WARNING: this will delete all the data associated with the custom field across all boards.
    */
   async deleteCustomField(id: string): Promise<void> {
      const api = this.trelloSrv.apiRetry;
      await api.delete(`/customFields/${id}`);
   }

   /**
    * Create a dropdown option for the given custom field.
    */
   async createCustomFieldOption(
      cfId: string,
      opt: ICustomFieldOptionDef,
   ): Promise<ICustomFieldOption> {
      const api = this.trelloSrv.apiRetry;
      const resp = await api.post<ICustomFieldOption>(`customField/${cfId}/options`, opt);
      const results: ICustomFieldOption = resp.data;
      return results;
   }

   /**
    * Update a dropdown item option for the given custom field and option id.
    */
   async updateCustomFieldOption(
      cfId: string,
      optId: string,
      opt: ICustomFieldOptionDef,
   ): Promise<ICustomFieldOption> {
      const api = this.trelloSrv.apiRetry;
      const resp = await api.put<ICustomFieldOption>(`customField/${cfId}/options/${optId}`, opt);
      const results: ICustomFieldOption = resp.data;
      return results;
   }

   /**
    * Delete the custom field option for the the given field and custom field option.
    */
   async deleteCustomFieldOption(cfId: string, optId: string): Promise<void> {
      const api = this.trelloSrv.apiRetry;
      await api.delete(`customField/${cfId}/options/${optId}`);
   }

   /** Use to make a new field name unique. */
   async buildUniqCfName(baseName: string): Promise<string> {
      const cust_fields = await this.getAllCustomFields();
      let ret_name = baseName.startsWith('_') ? baseName : `_${baseName}`;
      let name_post_fix = 0;

      const isExistingName = (n: string): boolean => {
         return cust_fields?.find((cf) => cf.name === n) != null;
      };

      while (isExistingName(ret_name)) {
         name_post_fix += 1;
         ret_name = `_${baseName}_${name_post_fix}`;
      }
      return ret_name;
   }

   // -------------- CUSTOM FIELDS DATA MANAGEMENT ------------------------ //
   async getCardCustomFieldValues(opts: {
      cardFrame: Trello.PowerUp.IFrame;
      cardOrId?: string | Pick<Trello.PowerUp.Card, 'id' | 'pluginData' | 'customFieldItems'>;
   }): Promise<ICardDataValues> {
      const cf_values = await this.getCardCustomFields({
         cardFrame: opts.cardFrame,
         cardOrId: opts.cardOrId,
      });
      const cust_fields = this.boardCfgSrv?.query.getCustomFields() ?? [];

      const ret: ICardDataValues = {};
      for (const f of cust_fields) {
         const cf_val = cf_values.find((c) => c.idCustomField === f.cust.id!);
         if (cf_val != null) {
            if (f.type !== FieldType.LIST) {
               ret[f.id] = getAmfValFromCf(f, cf_val.value);
            } else {
               // Return list of AMF field ids
               const amf_list_opt_ids: string[] = [];
               const cf_id_val = cf_val.idValue;
               const amf_id = f.cust.options?.find((o) => o.cfId === cf_id_val)?.amfId ?? null;
               if (amf_id != null) {
                  amf_list_opt_ids.push(amf_id);
               }
               ret[f.id] = amf_list_opt_ids;
            }
         }
      }
      return ret;
   }

   /**
    * Update the custom field values on the given card with the AMF data
    * passed as input.
    */
   async updateCfValuesForCard(
      cardFrame: Trello.PowerUp.IFrame,
      data: ICardData,
      cardId: string,
      opts: {
         /** When true (default) overwrite the custom field if the AMF is null.
          * - this is used when newly created cards are discovered and should keep their CF.
          */
         overwriteCfFromNull?: boolean;
      },
   ): Promise<void> {
      const overwrite_cf_from_null = opts.overwriteCfFromNull ?? true;

      try {
         assert(this.boardCfgSrv != null);

         // Apply any changes to custom fields
         const enabled_cust_fields = this.boardCfgSrv.query.getCustomFields();

         if (enabled_cust_fields.length > 0) {
            const set_proms: Promise<any>[] = [];
            // Get the current values of custom fields
            const [cf_values, cf_cfgs] = await Promise.all([
               this.getCardCustomFields({cardFrame, cardOrId: cardId}),
               this.getAllCustomFields(),
            ]);
            appLog.debug('==> updatingCustomFieldValuesForCard: cur cf values:', cf_values);

            if (cf_cfgs == null) {
               throw new AppError('Failed to get custom field configuration');
            }

            // For each field, if value has a change, then set it.
            for (const f of enabled_cust_fields) {
               // Find the configuration for the custom field we are updating
               const cf_cfg = cf_cfgs?.find((c) => c.id === f.cust.id!);

               // handle case of bad config where we can't find a custom field
               if (cf_cfg == null || f.cust.id == null) {
                  void this.trelloSrv.frame.alert({
                     message: `AmazingFields: '${f.name}' has invalid custom field. Did someone delete it? Adjust wrapper in settings to update.`,
                     display: 'warning',
                     duration: 30,
                  });
               }
               // Have valid config, so now go update it
               else {
                  const cf_val = cf_values.find((c) => c.idCustomField === f.cust.id!);
                  const cur_value = cf_val?.value ?? null;

                  // -- Update Data field types and it has changed
                  if (f.type !== FieldType.LIST) {
                     const new_value = buildCfValForAmf(f, data[f.id]);
                     if (
                        // If values don't match
                        !isEqual(cur_value, new_value) &&
                        // AND we either overwrite from null or new_value is not null
                        (overwrite_cf_from_null || new_value != null)
                     ) {
                        //appLog.debug(
                        //   `Field ${f.id} [${f.name}]: updating custom field [${f.cust.id}]`,
                        //   {cur_value, new_value, cardData: data},
                        //);
                        set_proms.push(this.setCardCustomFieldValue(cardId, f.cust.id, new_value));
                     }
                  }
                  // -- Update List field types if it has changed
                  else {
                     // Value is null or a string array of option ids
                     const amf_opts = data[f.id];
                     const amf_opt_id =
                        isArray(amf_opts) && amf_opts.length > 0 ? amf_opts[0] : null;
                     const desired_cf_opt_id =
                        f.cust.options?.find((o) => o.amfId === amf_opt_id)?.cfId ?? null;
                     const cur_cf_idval = cf_val?.idValue ?? null;

                     if (cur_cf_idval !== desired_cf_opt_id) {
                        set_proms.push(
                           this.setCardCustomFieldValue(
                              cardId,
                              f.cust.id,
                              undefined,
                              desired_cf_opt_id ?? '',
                           ),
                        );
                     }
                  }
               }
            }

            // note: because we collect all promises, some updates may pass if others fail
            await Promise.all(set_proms);
         }
      } catch (e: any) {
         this.sentrySrv.handleError(e, 'updateCfValuesForCard');
         // Re-throw so we can handle
         throw e;
      }
   }

   /**
    * Given an Amazing Field that is a list field config, update the associated
    * custom field definition to have the same list options defined.
    */
   async syncListFieldOptionsToCustomField(amfFieldId: string): Promise<void> {
      type CfOptsMapArray = Exclude<IDataFieldBase['cust']['options'], undefined>;

      assert(this.boardCfgSrv != null);
      const field_info = this.boardCfgSrv.query.getEntity(amfFieldId);
      assert(field_info != null && field_info.type === FieldType.LIST);

      // Early exit if this field doesn't have custom field enabled
      if (!field_info.cust.enabled) {
         return;
      }

      const cf_id = field_info.cust.id;
      const all_cfs = await this.getAllCustomFields();
      const cf_cfg = all_cfs?.find((c) => c.id === cf_id);
      assert(cf_cfg != null, 'Custom field must exist');

      // Store the old mapping we have for options on this field
      const old_map: CfOptsMapArray = field_info.cust.options ?? [];

      // -- Update Outline -- //
      // Create a new AMF opts map with only options we still have in AMF definitons
      // - then add to it below with any new ones
      // note: this cleans out old AMF options
      // - then remove any old CF options that should not be there anymore
      // Update the CF option details in the process
      const initial_cf_opt_ids = cf_cfg.options?.map((o) => o.id) ?? [];

      // rebuild the mapping array with only known options we should have
      const updated_map: CfOptsMapArray = [];

      // For each AMF option we should have, make sure we do have it and that config is correct
      // - build up the desired config we should have
      // - try to find it and update it OR create a new CF option of that type
      let pos = 1;
      for (const o of field_info.options) {
         const desired_cf_opt: ICustomFieldOptionDef = {
            pos: pos++,
            color: getCustomFieldColor(o.color),
            value: {
               text: o.text,
            },
         };

         let existing_mapping = old_map.find((m) => m.amfId === o.id);

         // Try to update the mapping
         if (existing_mapping != null) {
            try {
               await this.updateCustomFieldOption(cf_cfg.id, existing_mapping.cfId, desired_cf_opt);
               updated_map.push({amfId: o.id, cfId: existing_mapping.cfId});
            } catch (e: any) {
               // update failed, so rebuild instead
               existing_mapping = undefined;
            }
         }

         // CF option not found (or upate failed), so create a new one
         if (existing_mapping == null) {
            const new_opt = await this.createCustomFieldOption(cf_cfg.id, desired_cf_opt);
            updated_map.push({amfId: o.id, cfId: new_opt.id});
         }
      }

      // Delete any old CF options that are not referenced anymore
      const unused_cf_opt_ids = initial_cf_opt_ids.filter(
         (cfOptId) => updated_map.find((x) => x.cfId === cfOptId) == null,
      );
      for (const id of unused_cf_opt_ids) {
         await this.deleteCustomFieldOption(cf_cfg.id, id);
      }

      // Now save out the final mapping to use
      this.boardCfgSrv.updateField(field_info.id, (s) => {
         assert(isDataField(s));
         s.cust.options = updated_map;
      });
   }

   /**
    * Rebuild the AMF field list options based upon the values option values for custom field.
    *
    * This is used *only* when wrapping an existing custom field.
    */
   async buildListFieldOptionsFromCustomField(amfFieldId: string): Promise<void> {
      assert(this.boardCfgSrv != null);
      const field_info = this.boardCfgSrv.query.getEntity(amfFieldId);
      assert(field_info != null && field_info.type === FieldType.LIST);

      const cf_id = field_info.cust.id;
      const all_cfs = await this.getAllCustomFields();
      const cf_cfg = all_cfs?.find((c) => c.id === cf_id);
      assert(cf_cfg != null, 'Custom field must exist');

      const opts_map: Exclude<IDataFieldBase['cust']['options'], undefined> = [];
      const amf_list_opts: IListOption[] = [];

      for (const cf_opt of cf_cfg.options ?? []) {
         const amf_opt: IListOption = {
            id: uuidv4(),
            text: cf_opt.value.text,
            color: getAmfColorFromCustFieldColor(cf_opt.color),
         };
         opts_map.push({amfId: amf_opt.id, cfId: cf_opt.id});
         amf_list_opts.push(amf_opt);
      }

      // Update the AMF field with the new options
      this.boardCfgSrv.updateField(field_info.id, (s) => {
         assert(isDataField(s) && s.type === FieldType.LIST);
         s.options = amf_list_opts;
         s.cust.options = opts_map;
      });
   }

   // --- WRAPPING HELPERS ---- //

   /**
    * Create a new custom field and wrap it for the given field.
    */
   async createAndWrapCustomFieldForField(opt: {
      amfFieldId: string;
      cfName: string;
      progressCb: ProgressCbFunc;
   }): Promise<void> {
      assert(this.boardCfgSrv != null);
      const field_info = this.boardCfgSrv.query.getEntity(opt.amfFieldId);
      assert(field_info != null);
      const cf_type = getCustomFieldType(field_info.type);
      assert(cf_type != null, 'Can not be called with a field that cant be mapped');

      try {
         // NOTE: we pause config saving because we don't want other
         //      people on other boards to see this field until we are done with it
         this.boardCfgSrv.setDisableSaveMonitoring(true);

         // 1) Create a new custom field with the settings we need
         // - name that starts with _, move to top, don't show on front,
         const cf_desc = await this.createCustomField({
            name: opt.cfName,
            type: cf_type,
            pos: 'top',
            display_cardFront: false,
         });

         // 2) Update to have an AMF field configured
         // - save the configuration so it is active and known to be linked
         this.boardCfgSrv.updateField(field_info.id, (s) => {
            assert(isDataField(s));
            s.cust.id = cf_desc.id;
            s.cust.enabled = true;
         });

         // - if list type, get the CF options created
         if (field_info.type === FieldType.LIST) {
            await this.syncListFieldOptionsToCustomField(opt.amfFieldId);
         }

         // 3) Now copy the AMF view value from all cards over for the custom field
         await this.copyAmfFieldToCustomField({
            amfFieldId: field_info.id,
            cfDesc: cf_desc,
            progressCb: opt.progressCb,
         });
      } finally {
         this.boardCfgSrv.setDisableSaveMonitoring(false, {forceSave: true});
      }
   }

   /**
    * Wrap and existing custom field
    */
   async wrapExistingCustomFieldForField(opt: {
      amfFieldId: string;
      cfDesc: ICustomFieldDesc;
      progressCb: ProgressCbFunc;
   }): Promise<void> {
      assert(this.boardCfgSrv != null);
      const field_info = this.boardCfgSrv.query.getEntity(opt.amfFieldId);
      assert(field_info != null);
      const cf_desc = opt.cfDesc;

      try {
         appLog.debug('wrapExisting: Wrapping custom field for AMF field', {
            amfFieldId: opt.amfFieldId,
            cfDesc: cf_desc,
         });

         // NOTE: we pause config saving because we don't want other
         //      people on other boards to see this field until we are done with it
         this.boardCfgSrv.setDisableSaveMonitoring(true);

         // 1) Update the existing custom field with the settings we need
         // - name that starts with _, move to top, don't show on front,
         let updated_field_name = cf_desc.name;
         if (!updated_field_name.startsWith('_')) {
            updated_field_name = await this.buildUniqCfName(updated_field_name);
         }

         await this.updateCustomField(cf_desc.id, {
            name: updated_field_name,
            display_cardFront: false,
            pos: 'top',
         });

         // 2) Update AMF config with the mapping to custom field
         this.boardCfgSrv.updateField(field_info.id, (s) => {
            assert(isDataField(s));
            s.cust.id = cf_desc.id;
            s.cust.enabled = true;
         });

         // If we are list type field, then initialize AMF options with mapping to CF values
         if (field_info.type === FieldType.LIST) {
            await this.buildListFieldOptionsFromCustomField(opt.amfFieldId);
         }

         // 3) Now update the value of the AMF fields with the value of the custom field
         await this.copyCustomFieldValuesToAmfField(opt);
      } finally {
         this.boardCfgSrv.setDisableSaveMonitoring(false, {forceSave: true});
      }
   }

   /**
    * Unwrap a custom field.
    */
   async unwrapCustomFieldForField(opt: {
      amfFieldId: string;
      cfDesc: ICustomFieldDesc;
      progressCb: ProgressCbFunc;
   }): Promise<void> {
      assert(this.boardCfgSrv != null);
      const field_info = this.boardCfgSrv.query.getEntity(opt.amfFieldId);
      assert(field_info != null);
      const cf_desc = opt.cfDesc;

      // 1) Copy all the custom field data over to the AMF field so we keep it around
      await this.copyCustomFieldValuesToAmfField(opt);

      // 2) Disconnect the custom field configuration for the field
      this.boardCfgSrv.updateField(field_info.id, (s) => {
         assert(isDataField(s));
         s.cust.id = null;
         s.cust.enabled = false;
         s.cust.options = undefined;
         //s.cust.outOnly = undefined;
      });

      // 3) Update custom field to show it again
      await this.updateCustomField(cf_desc.id, {
         display_cardFront: true,
      });
   }

   /**
    * Copy the value from the given custom field to the given amazing field for
    * all cards on the board.
    *
    * Used for initialization and unwrapping helpers.
    */
   async copyCustomFieldValuesToAmfField(opt: {
      amfFieldId: string;
      cfDesc: ICustomFieldDesc;
      progressCb: ProgressCbFunc;
   }): Promise<void> {
      assert(this.boardCfgSrv != null);
      const frame = this.trelloSrv.frame;

      const field_info = this.boardCfgSrv.query.getEntity(opt.amfFieldId);
      assert(
         field_info != null && isDataField(field_info) && field_info.cust.enabled,
         'Must be a data field with custom fields enabled and mapped.',
      );
      const all_cards = await frame.cards('all');
      appLog.debug(`copyCFtoAMF: Founds cards: ${all_cards.length}`);

      // Loop over all the cards and update them
      let i = 0;
      for (const card of all_cards) {
         i += 1;
         const pct = i / all_cards.length;
         opt.progressCb(pct);
         appLog.debug(`[${i}/${all_cards.length}] copyCFtoAMF: ${card.id}:${card.name}`);
         await this.cardDataSrv?.patchCardField(frame, {}, card.id, {writeCfFields: false});
      }
   }

   /**
    * Copy the value from the given AMF field to the custom fields on all cards.
    *
    * Used for initialization of created fields.
    */
   async copyAmfFieldToCustomField(opt: {
      amfFieldId: string;
      cfDesc: ICustomFieldDesc;
      progressCb: ProgressCbFunc;
   }): Promise<void> {
      assert(this.boardCfgSrv != null);
      assert(this.cardDataSrv != null);
      const frame = this.trelloSrv.frame;

      const field_info = this.boardCfgSrv.query.getEntity(opt.amfFieldId);
      assert(
         field_info != null && isDataField(field_info) && field_info.cust.enabled,
         'Must be a data field with custom fields enabled and mapped.',
      );

      const all_cards = await frame.cards('all');
      appLog.debug(`copyAMFtoCF: Founds cards: ${all_cards.length}`);

      // Loop over all the cards and update them
      let i = 0;
      for (const card of all_cards) {
         i += 1;
         const pct = i / all_cards.length;
         opt.progressCb(pct);
         appLog.debug(`[${i}/${all_cards.length}] copyAMFtoCF: ${card.id}:${card.name}`);

         // Save out custom field data
         const cur_amf_data = await this.cardDataSrv.getCardFields(this.trelloSrv.frame, card.id);
         if (cur_amf_data != null) {
            await this.updateCfValuesForCard(frame, cur_amf_data, card.id, {
               overwriteCfFromNull: true,
            });
         }
      }
   }
}
