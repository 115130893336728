import {Injectable} from '@angular/core';
import {BOARD_CFG_HASH, BOARD_CFG_IDX, IBoardConfig} from '@shared/domain_types';
import * as lz_string from 'lz-string';
import {TrelloSrv} from 'src/app/services/trello.srv';

import {SentrySrv} from '@app/sentry.srv';
import {appLog, breadcrumb, timingEnd, timingStart} from '@app/services/logging';
import {assert} from '@util/assert';
import {uuidv4} from '@util/utils';

import {BOARD_CONFIG_MIGRATIONS} from './board_migrations';

/** The current configuration version for the board configuration. */
export const CURRENT_BOARD_CONFIG_VERSION = 19;

/** Build and return a default configuration for new boards. */
export function buildDefaultBoardConfig(opts: {boardId: string | null}): IBoardConfig {
   return {
      version: CURRENT_BOARD_CONFIG_VERSION,
      sectionName: 'Amazing Fields',
      adminOnlyFilters: false,
      custFieldIds: [],
      fields: [],
      boardId: opts.boardId,
   };
}

const EMPTY_BOARD_HASH_TOKEN = 'NA';
const EMPTY_BOARD_CONFIG_TOKEN = '--EMPTY_CONFIG--';
const UNSAVED_BOARD_HASH_TOKEN = 'NEW_CONFIG';

/**
 * Service for managing configuration data storage on boards
 *
 * This is stored on the board.
 *
 * Note: boards allow 8196 bytes of data to be stored  (>300 default fields)
 */
@Injectable({providedIn: 'root'})
export class BoardDataStorageSrv {
   /** The size of the board configuration in compressed format. */
   compressedSize: number | null = null;

   /** Keep track of the last hash we loaded/saved so we can determine if
    * the config was changed behind our back and abort a save.
    */
   protected lastKnownHash: string | null = null;

   constructor(public trelloSrv: TrelloSrv, protected sentrySrv: SentrySrv) {
      //
   }

   /**
    * Save the given configuration into the trello board shared area.
    *
    * ASSERT: The structure of this config MUST match the current version
    *    defined in types.  Saving a Config that does not match version
    *    is a major error.
    *    (note: this is enforced by only saving configs that have been loaded from here too.)
    */
   async saveConfig(config: IBoardConfig, ignoreVersion: boolean = false): Promise<string> {
      if (config.version !== CURRENT_BOARD_CONFIG_VERSION && !ignoreVersion) {
         appLog.error('Config version mismatch');
      }

      breadcrumb(
         'BoardDataStorageSrv:saveConfig',
         {data: {context: this.trelloSrv.context}},
         {log: false},
      );
      appLog.log('saving config...', config);

      // Verify no one else has saved a different config
      const current_stored_hash = await this.getCfgHash();

      // If we have been saved before, and the current saved
      // version does not match what we expect...
      if (
         current_stored_hash !== EMPTY_BOARD_HASH_TOKEN &&
         current_stored_hash !== this.lastKnownHash
      ) {
         breadcrumb('Board cfg conflict: ', {
            data: {
               current_stored_hash,
               last_known_hash: this.lastKnownHash,
            },
         });
         this.sentrySrv.handleWarning('BoardDataStorageSrv: board cfg conflict detected.');
         void this.trelloSrv.frame.alert({
            message:
               'Saving field settings conflicts with more recently saved settings.  Please reload browser to resolve.',
            display: 'error',
            duration: 30,
         });
         return this.lastKnownHash ?? '';
      }

      timingStart('configCompress');
      const config_str = JSON.stringify(config);
      const compressed_str = lz_string.compressToUTF16(config_str);
      this.compressedSize = compressed_str.length;
      timingEnd('configCompress');

      const new_hash = uuidv4();

      try {
         await this.trelloSrv.frame.set('board', 'shared', BOARD_CFG_IDX, compressed_str);
         await this.trelloSrv.frame.set('board', 'shared', BOARD_CFG_HASH, new_hash);
      } catch (e: any) {
         breadcrumb(
            'BoardDataStorageSrv:saveConfig:Exception',
            {data: {context: this.trelloSrv.context, errorMsg: e?.message ?? ''}},
            {log: false},
         );
         if (this.trelloSrv.context?.board != null) {
            await this.trelloSrv.frame.alert({
               message:
                  'Board Storage Exceeded: Trello limits the amount of data stored on boards. Please remove or reduce complexity of fields.',
               display: 'error',
               duration: 30,
            });
         }
         throw new Error(`Error Saving Board Config: ${e?.message}`);
      }

      appLog.log('  cfg size: ', config_str.length);
      appLog.log('  cfg_hash: ', new_hash);
      appLog.log('  compressed size: ', this.compressedSize);
      this.lastKnownHash = new_hash;
      return new_hash;
   }

   /**
    * Load the powerup configuration from the shared board area.
    *
    * Either returns a valid configuration OR throws an exception for bad format.
    */
   async loadConfig(targetVersion?: number): Promise<{cfg: IBoardConfig; hash: string}> {
      let cur_config: IBoardConfig;
      const frame = this.trelloSrv.frame;

      breadcrumb('BoardDataStorageSrv:loadConfig', {data: {context: this.trelloSrv.context}});
      const raw_val = await frame.get<string>(
         'board',
         'shared',
         BOARD_CFG_IDX,
         EMPTY_BOARD_CONFIG_TOKEN,
      );
      let cfg_hash = await this.getCfgHash();

      assert(raw_val != null, 'Board config was null. Is network disconnected?');

      if (raw_val === EMPTY_BOARD_CONFIG_TOKEN) {
         cur_config = buildDefaultBoardConfig({boardId: this.trelloSrv.context.board});
         cfg_hash = UNSAVED_BOARD_HASH_TOKEN;
      } else {
         // If we have JSON string, then load that
         // Q: does this ever happen??
         if ((raw_val as any).version != null) {
            return {cfg: raw_val as unknown as IBoardConfig, hash: cfg_hash};
         }

         try {
            timingStart('configDecompress');
            const config_str = lz_string.decompressFromUTF16(raw_val);
            if (config_str == null) {
               breadcrumb('BoardDataStorageSRV:loadConfig: raw_val: ', {data: {raw_val}});
               this.sentrySrv.captureMessage('Invalid board config found', {
                  tags: {
                     board: frame.getContext().board,
                  },
               });
            }
            assert(config_str != null, 'Bad configuration found');

            cur_config = JSON.parse(config_str);
            assert(cur_config != null, 'Invalid configuration found. Can not parse.');
            if (cur_config != null) {
               cur_config = this.migrateConfig(cur_config, targetVersion);
            }

            this.compressedSize = raw_val.length;
            timingEnd('configDecompress');
            appLog.log('  compressed size: ', this.compressedSize);
         } catch (e: unknown) {
            // BE CAREFUL HERE. we MUST NOT allow null to come back and lose data
            appLog.error('Board Config Load Error: ', e);
            throw e;
         }
      }
      appLog.debug('config: ', cur_config);
      appLog.debug('config hash: ', cfg_hash);
      this.lastKnownHash = cfg_hash;
      return {cfg: cur_config, hash: cfg_hash};
   }

   /**
    * Return the unique hash associated with the current board configuration.
    */
   async getCfgHash(): Promise<string> {
      const cfg_hash =
         (await this.trelloSrv.frame.get<string>('board', 'shared', BOARD_CFG_HASH)) ??
         EMPTY_BOARD_HASH_TOKEN;
      return cfg_hash;
   }

   /** Check if configuration has old version and needs migrated.
    *
    */
   migrateConfig(config: IBoardConfig, targetVersion?: number): IBoardConfig {
      let final_config = config;
      const target_version = targetVersion ?? CURRENT_BOARD_CONFIG_VERSION;

      if (final_config.version > target_version) {
         appLog.warn(
            `Field config is version ${final_config.version}, code expected: ${target_version}`,
         );
      }
      // Migrate until we get to targetted version
      while (final_config.version < target_version) {
         const next_version = config.version + 1;
         const migration = BOARD_CONFIG_MIGRATIONS.find((m) => m.targetVersion === next_version);
         assert(migration != null, `Unable to find migration for version: ${next_version}`);
         final_config = migration.f(final_config);
         assert(final_config.version === next_version, 'Should migrate to the target version');
      }
      return final_config;
   }
}
