const ZONE_CONTEXT_PROP = 'trs-zone-context';

export function enterZone<T>(ctxName: string, callback: (...args: any[]) => T, applyThis: any): T {
   const new_zone = Zone.current.fork({
      name: `ctx-${ctxName}`,
      properties: {
         [ZONE_CONTEXT_PROP]: ctxName,
      },
   });
   return new_zone.run<T>(callback, applyThis);
}

/**
 * Get the zone context path we are inside of right now.
 */
export function getZoneContextPath(): string | null {
   const cur_zone = Zone.current;
   const parts = [];

   let next_zone = cur_zone.getZoneWith(ZONE_CONTEXT_PROP);
   while (next_zone != null) {
      const p = next_zone.get(ZONE_CONTEXT_PROP) as string | undefined;
      if (p != null) {
         parts.unshift(p);
      }
      next_zone = next_zone.parent != null ? next_zone.parent.getZoneWith(ZONE_CONTEXT_PROP) : null;
   }

   return parts.length === 0 ? null : parts.join(':');
}
