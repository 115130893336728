import {FieldType, ICardData, IFieldInfo} from '@shared/domain_types';
import {assert} from '@util/assert';

import {isString} from '@util/utils';
import {ICardDataMigration} from '.';

type UUID = string;
type FieldDataTypesV0 = string | string[] | number | boolean | null;

interface IFieldDataV0 {
   [key: UUID]: FieldDataTypesV0;
}

interface IFieldDataV1 {
   __version: number;
   [key: UUID]: FieldDataTypesV0;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addVersion_0_1: ICardDataMigration = {
   targetVersion: 1,
   f: (d: ICardData, fieldCfgs: IFieldInfo[]): ICardData => {
      // Verify we have valid input data
      assert(d.__version === 0);

      const incoming = d as IFieldDataV0;
      assert(incoming != null);

      const outgoing = d as IFieldDataV1;

      // Handle old list / multi-select migration
      // - case where list type was single string and should be a list of strings
      for (const f of fieldCfgs) {
         if (f.type === FieldType.LIST) {
            const val = outgoing[f.id];
            if (val == null || isString(val)) {
               //appLog.warn(
               //   'Amazing Fields: Old card data found: migrating it to latest version',
               //);
               outgoing[f.id] = val == null ? [] : [val];
            }
         }
      }

      // Set final version
      outgoing.__version = 1;
      return outgoing as ICardData;
   },
};
