/**
 * This file contains the types for the production events that are sent to PostHog.
 */

import {SubscriptionTypes} from './shared_db_types';

// See naming convention: https://posthog.com/product-engineers/5-ways-to-improve-analytics-data#1-implement-a-naming-convention
//

export enum ProdEvents {
   /** Special event to identify and link the user */
   IDENTIFY = 'identify',

   PAGE_VIEW = '$pageview',
   ATTRIB_REDIRECT = 'attrib_redirect',

   // Core User Actions
   ENABLE = 'enable',
   DISABLE = 'disable',
   AUTH_NEED_PRESENTED = 'auth_need_presented',
   AUTH_START = 'auth_start',
   AUTH_SUCCESS = 'auth_success',
   AUTH_ABORT = 'auth_abort',
   ADD_FIELD = 'add_field',
   OPEN_TEMPLATE = 'open_template',
   OPEN_HELP = 'open_help',
   REMOVE_FIELD = 'remove_field',
   REMOVE_POWERUP_DATA = 'remove_powerup_data',
   EDIT_FIELD = 'edit_field',
   SUBSCRIPTION_START = 'sub_start',
   SUBSCRIPTION_REDIRECT = 'sub_stripe_redirect',
   SUBSCRIPTION_SUCCESS = 'sub_success',
   SUBSCRIPTION_ABORT = 'sub_abort',
   SUBSCRIPTION_FAIL = 'sub_fail',

   BOARD_OPEN = 'board_open',
   SETTINGS_OPEN = 'settings_open',
   TABLE_OPEN = 'table_open',

   // Subscription Change Events
   // - these are across the entire system
   SUBSCRIPTION_CHANGE = 'subscription_change',
   SUBSCRIPTIONS_UPDATED = 'subscriptions_updated',
}

export type IProdEvents =
   | IProdIdentifyEvent
   | IProdPageViewEvent
   | IProdOpenTemplateEvent
   | IProdAttribRedirectEvent
   | IProdEnableEvent
   | IProdDisableEvent
   | IProdRemovePowerupDataEvent
   | IProdAuthStartEvent
   | IProdAuthAbortEvent
   | IProdAuthSuccessEvent
   | IProdAddFieldEvent
   | IProdEditFieldEvent
   | IProdRemoveFieldEvent
   | IProdBoardOpenEvent
   | IProdSettingsOpenEvent
   | IProdTableOpenEvent
   | IProdOpenHelpEvent
   | IProdAuthNeedPresentedEvent
   | IProdSubscriptionStartEvent
   | IProdSubscriptionRedirectEvent
   | IProdSubscriptionSuccessEvent
   | IProdSubscriptionAbortEvent
   | IProdSubscriptionFailEvent
   | IProdSubscriptionChangeEvent
   | IProdSubscriptionsUpatedEvent;

/**
 * Optional user props to send as updated along with an event.
 *
 * If they are set, then we make a call to store them.
 */
export interface IUserProps {
   avatar?: string;

   // Trello related
   email?: string;
   username?: string;
   name?: string;
   trello_paid_status?: string;
   organization?: string;
   enterprise?: string;

   // other...
   created?: string; // iso8601 date time of created
   last_edit?: string; // iso8601 date time of last edit

   editor?: boolean; // Have they used editing tools

   subscription_type?: SubscriptionTypes | null;

   /** Number of boards where we have enabled. */
   enabled_board_count?: number;

   /** Number of boards where we have disabled. */
   disabled_board_count?: number;

   /** Number of days accessed. */
   accessed_days?: number;

   /** Number of days edited a setting. */
   edited_days?: number;
}

export interface IProdEventBase {
   type: ProdEvents;

   /** Trello user id / AMF user id. */
   uid?: string;

   /** Trello board id */
   board_id?: string;

   /** Hash used to identify anonymous user.
    * Used in case we don't have a uid and need a distinct id.  Computed dyanmically on server.
    */
   anon_user_hash?: string;

   /** Area for untyped properties. (from posthog and server) */
   extraProps?: Record<string, any>;

   /** Area for typed properties. */
   props: Record<string, any>;

   /** Optional user props to pass.  If passed, then set them as part of event processing. */
   userProps?: IUserProps;
}

/**
 * Send with a uid, and will associated with anon id with this uid.
 */
export interface IProdIdentifyEvent extends IProdEventBase {
   type: ProdEvents.IDENTIFY;
   uid: string;

   // Not optional for this event.
   userProps: IUserProps;
}

/**
 * Event to keep track of a user visiting a page.
 */
export interface IProdPageViewEvent extends IProdEventBase {
   type: ProdEvents.PAGE_VIEW;
   props: {
      page_name: string;

      /** The variant of the page that we are viewing. */
      variant?: string;
   };
}

/**
 * Track an attribution redirect (ads, landing pages, etc)
 */
export interface IProdAttribRedirectEvent extends IProdEventBase {
   type: ProdEvents.ATTRIB_REDIRECT;
   props: {
      attrib_source: string;
      destination_url: string;
   };
}

// note: add board_id when possible

export interface IProdEnableEvent extends IProdEventBase {
   type: ProdEvents.ENABLE;

   props: {
      /** True iff we were already authorized when ENABLE event sent. */
      is_authorized: boolean;
   };
}

export interface IProdDisableEvent extends IProdEventBase {
   type: ProdEvents.DISABLE;
}
export interface IProdRemovePowerupDataEvent extends IProdEventBase {
   type: ProdEvents.REMOVE_POWERUP_DATA;
}

export interface IProdAuthNeedPresentedEvent extends IProdEventBase {
   type: ProdEvents.AUTH_NEED_PRESENTED;
   props: {
      page_name: string;
   }
}

export interface IProdAuthStartEvent extends IProdEventBase {
   type: ProdEvents.AUTH_START;
}
export interface IProdAuthAbortEvent extends IProdEventBase {
   type: ProdEvents.AUTH_ABORT;
}
export interface IProdAuthSuccessEvent extends IProdEventBase {
   type: ProdEvents.AUTH_SUCCESS;
}
export interface IProdAddFieldEvent extends IProdEventBase {
   type: ProdEvents.ADD_FIELD;
   props: {
      field_name: string;
   };
}
export interface IProdEditFieldEvent extends IProdEventBase {
   type: ProdEvents.EDIT_FIELD;
}
export interface IProdRemoveFieldEvent extends IProdEventBase {
   type: ProdEvents.REMOVE_FIELD;
}

export interface IProdBoardOpenEvent extends IProdEventBase {
   type: ProdEvents.BOARD_OPEN;
}
export interface IProdSettingsOpenEvent extends IProdEventBase {
   type: ProdEvents.SETTINGS_OPEN;
}
export interface IProdTableOpenEvent extends IProdEventBase {
   type: ProdEvents.TABLE_OPEN;
}

export interface IProdOpenTemplateEvent extends IProdEventBase {
   type: ProdEvents.OPEN_TEMPLATE;
   props: {
      template_name: string;

      /** The area where the user chose to open the template. */
      location: string;
   };
}

export interface IProdOpenHelpEvent extends IProdEventBase {
   type: ProdEvents.OPEN_HELP;
   props: {
      /** Destination area in the help. */
      help_dest: string;
      /** Optional search in the help area. */
      help_search?: string;
   };
}

interface IBaseProdSubEvent extends IProdEventBase {
   props: {
      variant: string;
   };
}
export interface IProdSubscriptionStartEvent extends IBaseProdSubEvent {
   type: ProdEvents.SUBSCRIPTION_START;
}
export interface IProdSubscriptionRedirectEvent extends IBaseProdSubEvent {
   type: ProdEvents.SUBSCRIPTION_REDIRECT;
}
export interface IProdSubscriptionSuccessEvent extends IBaseProdSubEvent {
   type: ProdEvents.SUBSCRIPTION_SUCCESS;
}
export interface IProdSubscriptionAbortEvent extends IBaseProdSubEvent {
   type: ProdEvents.SUBSCRIPTION_ABORT;
}
export interface IProdSubscriptionFailEvent extends IBaseProdSubEvent {
   type: ProdEvents.SUBSCRIPTION_FAIL;
}

// Backend Subscription events

export interface IProdSubscriptionChangeEvent extends IProdEventBase {
   type: ProdEvents.SUBSCRIPTION_CHANGE;

   props: {
      change_type: 'new' | 'expired' | 'cancelled' | 'processed' | 'change' | 'unknown';

      status:
         | 'active'
         | 'canceled'
         | 'incomplete'
         | 'incomplete_expired'
         | 'past_due'
         | 'trialing'
         | 'unpaid'
         | null;

      product_name: string | null;
      interval: string | null;
      unit_amount: number | null;
      value_mrr: number | null;

      /** Descriptive message of what happened. */
      message: string;

      sub_before_raw: any;
      sub_after_raw: any;
   };
}

export interface IProdSubscriptionsUpatedEvent extends IProdEventBase {
   type: ProdEvents.SUBSCRIPTIONS_UPDATED;

   props: {
      /** Total aggregate MRR. */
      total_mrr: number;

      /** Number of active subscriptions. */
      subscription_count: number;

      /** Total MRR cancelling at the end of the current period. */
      canceling_end_of_period_mrr: number;

      new_subscriptions_today: number;

      new_mrr_today: number;
   };
}
