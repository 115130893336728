import {Injectable} from '@angular/core';
import {
   ActiveState,
   EntityState,
   EntityStore,
   isFunction,
   QueryEntity,
   StoreConfig,
   UpdateStateCallback,
} from '@datorama/akita';
import {
   EditorLayout,
   EditorWidth,
   FieldType,
   IBoardConfig,
   IDataFieldTypes,
   IFieldInfo,
   ITextField,
   MemberType,
   TextEditorStyle,
   TextMaskOption,
} from '@shared/domain_types';
import {isEqual} from 'lodash-es';
import {merge, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {SAVE_DELAY_MS} from 'src/app/app_config';
import {assert, uuidv4} from 'src/util';

import {SentrySrv} from '@app/sentry.srv';
import {appLog, breadcrumb} from '@app/services/logging';
import {TrelloSrv} from '@app/services/trello.srv';

import {BoardDataStorageSrv, buildDefaultBoardConfig} from './board_data_storage.srv';
import {CustomFieldsSrv} from './custom_fields.srv';
import {isDataField} from './data_helpers';

/** Remove the fields from the default config. */
function filterFieldsFromConfig(cfg: IBoardConfig): Omit<IBoardConfig, 'fields'> {
   const config: Omit<IBoardConfig, 'fields'> = {...cfg};
   delete (config as Partial<IBoardConfig>).fields;
   return config;
}

/** The type of the entity in the field store. */
export interface IBoardEntityState extends EntityState<IFieldInfo, string>, ActiveState {
   // work around type bug: https://github.com/datorama/akita/issues/645
   active: string;

   /** Additional board configuration. */
   config: Omit<IBoardConfig, 'fields'>;

   version: number;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'fields', idKey: 'id', resettable: true})
export class BoardConfigStore extends EntityStore<IBoardEntityState> {
   constructor() {
      super({config: filterFieldsFromConfig(buildDefaultBoardConfig({boardId: null}))});
   }
}

/**
 * Standard query for the fields.
 */
@Injectable({providedIn: 'root'})
export class BoardConfigQuery extends QueryEntity<IBoardEntityState> {
   constructor(protected store: BoardConfigStore) {
      super(store);
   }

   getConfig(): Omit<IBoardConfig, 'fields'> {
      return this.getValue().config;
   }

   /** Return number of fields. */
   getFieldCount(): number {
      return this.getAll().length;
   }

   getDataFields(): IDataFieldTypes[] {
      return this.getAll().filter((f): f is IDataFieldTypes => isDataField(f));
   }

   /** Return true if we have some custom fields to handle */
   hasCustomFields(): boolean {
      return this.getDataFields().some((f) => f.cust.enabled);
   }

   /** Return getDataFields() filterd to only ones with custom fields enabled. */
   getCustomFields(): IDataFieldTypes[] {
      return this.getDataFields().filter((f) => f.cust.enabled);
   }
}

/**
 * Service to manage the access and modification of the field configuration data.
 *
 * See: https://datorama.github.io/akita/
 *
 */
@Injectable({providedIn: 'root'})
export class BoardConfigSrv {
   /** The currently active field. */
   protected activeFieldId: string | null = null;

   /**
    * True iff we are currently loading the configuration from persistent storage
    * or doing anything else where save change monitoring should be paused.
    */
   protected disableSaveMonitoring: boolean = false;

   protected saveConfigSub: Subscription | null = null;

   /** True iff we are in the process of updating the active record. */
   protected _updatingActive: boolean = false;

   /** Token to use with local storage to track if we are currently saving. */
   protected savingConfigToken: string = 'AMF:saving_config';

   constructor(
      public query: BoardConfigQuery,
      public store: BoardConfigStore,
      protected boardDataStorageSrv: BoardDataStorageSrv,
      protected trelloSrv: TrelloSrv,
      protected customFieldSrv: CustomFieldsSrv,
      protected sentrySrv: SentrySrv,
   ) {
      this.customFieldSrv.setBoardCfgSrv(this);
   }

   /**
    * Return the hash of the config for the current board.
    */
   async getConfigHash(): Promise<string> {
      const hash = await this.boardDataStorageSrv.getCfgHash();
      return hash;
   }

   /** Return the best estimate of configuration size. */
   getCompressedSize(): number | null {
      return this.boardDataStorageSrv.compressedSize;
   }

   /**
    * Set the flag that lets us know config is actively saving.
    */
   setSavingConfig(v: boolean): void {
      localStorage.setItem(this.savingConfigToken, v ? 'Y' : 'N');
   }

   /**
    * Return true iff config is actively in the process of saving.
    */
   isConfigSaving(): boolean {
      const is_saving =
         (localStorage.getItem(this.savingConfigToken) ?? 'N') === 'Y' ? true : false;
      return is_saving;
   }

   /**
    * Temporarily enable/disable save monitoring.
    * The invalidation string is used to trigger cache invalidation when re-enabled.
    */
   setDisableSaveMonitoring(v: boolean, opts?: {invalidate?: string; forceSave?: boolean}): void {
      this.disableSaveMonitoring = v;

      // Allows setting a value that will cause the config
      // save to trigger when re-enabled later.
      const invalidate_value = opts?.invalidate ?? undefined;
      this.store.update((state) => {
         if (state.config.invalidate !== undefined) {
            if (invalidate_value === undefined) {
               delete state.config.invalidate;
            } else {
               state.config.invalidate = invalidate_value;
            }
         }
      });

      const force_save = opts?.forceSave ?? false;
      if (force_save) {
         appLog.info('forcing save of board config');
         this.saveConfig().catch((e) =>
            this.sentrySrv.handleError(e, 'BoardConfig:saveConfig$_forced'),
         );
      }
   }

   /**
    * Load the configuration from persistent storage.
    */
   async loadConfig(): Promise<void> {
      this.setDisableSaveMonitoring(true);
      try {
         appLog.info('==> BoardConfigSrv:loadConfig()');
         const {cfg: loaded_config, hash: current_hash} =
            await this.boardDataStorageSrv.loadConfig();
         assert(current_hash != null);
         assert(loaded_config != null, 'config must not be null');

         // Setup the store with the configuration
         // todo: find way to do this all at once.
         const field_entities = loaded_config.fields;
         this.store.set(field_entities, {
            activeId: field_entities.length > 0 ? field_entities[0].id : null,
         });
         this.store.update({config: filterFieldsFromConfig(loaded_config)});

         // Now that it is loaded, monitor it to save back out
         if (this.saveConfigSub == null) {
            this.saveConfigSub = merge(
               // don't save if we don't have actual changes to fields or base config
               this.query.selectAll().pipe(distinctUntilChanged(isEqual)),
               this.query.select((s) => s.config).pipe(distinctUntilChanged(isEqual)),
            )
               .pipe(
                  filter(() => !this.disableSaveMonitoring && !this.isConfigSaving()),
                  debounceTime(SAVE_DELAY_MS),
               )
               .subscribe((c) => {
                  breadcrumb('BoardConfigSrv:board config stream updated: ');
                  appLog.debug('new board config: ', c);
                  this.saveConfig().catch((e) =>
                     this.sentrySrv.handleError(e, 'BoardConfig:saveConfig$'),
                  );
               });
         }
      } finally {
         this.setDisableSaveMonitoring(false);
      }
   }

   /**
    * Save out the current field configuration to persistent storage.
    */
   async saveConfig(): Promise<void> {
      breadcrumb('💾 BoardConfigSrv:saveConfig');
      try {
         this.setSavingConfig(true);
         await this.boardDataStorageSrv.saveConfig(this.getConfigSnapshot());
      } catch (e: any) {
         this.sentrySrv.handleError(e, 'BoardConfigSrv:saveConfig:');
      } finally {
         this.setSavingConfig(false);
      }
   }

   /**
    * Used to merge configuration from another board into this board.
    *
    * This will apply it to the current board and ensure the board uses the new configuration.
    * note: this will result in a save happening as well to update everything
    */
   mergeConfig(newConfig: IBoardConfig): void {
      // We update the local configuration and then allow the store to do it's thing
      const non_field_config = filterFieldsFromConfig(newConfig);
      this.store.update((s) => {
         s.config.sectionName = non_field_config.sectionName;
         s.config.adminOnlyFilters = non_field_config.adminOnlyFilters;
         s.config.adminOnlyConfig = non_field_config.adminOnlyConfig;
      });

      // Update the fields taking into account custom field links
      // - clear any custom field ids because they need recreated
      const field_entities = newConfig.fields;
      for (const f of field_entities) {
         if (isDataField(f) && f.cust.enabled) {
            f.cust.id = null;
            f.cust.options = [];
         }

         // If field exists, update
         if (this.query.hasEntity(f.id)) {
            this.store.update(f.id, f);
         }
         // Else, create
         else {
            this.store.add(f);
         }
      }

      /*
      Update active entity
      this.store.set(field_entities, {
         activeId: field_entities.length > 0 ? field_entities[0].id : null,
      });
      */
   }

   /**
    * Used to update a configuration that came from another board.
    *
    * This happens in the case of creating a board from a template or clone.
    *
    * Processes the config and ensures any initial configuration changes needed
    * for the new board are put in place and active.
    */
   async updateConfigToBoard(curBoardId: string): Promise<void> {
      breadcrumb('BoardConfigSrv:updateConfigToBoard: start');
      appLog.info('updateCfgToBrd: initial board config: ', this.getConfigSnapshot());
      await this.trelloSrv.frame.alert({
         message: 'Migrating board configuration. Please wait...',
         duration: 30,
      });

      // Handle special case of old boards with no config id
      const config_board_id = this.query.getConfig().boardId;
      if (config_board_id == null) {
         this.store.update((s) => {
            s.config.boardId = curBoardId;
         });
         await this.saveConfig();
      }
      // -- Migrate all board data from a previous board configuration -- //
      else {
         try {
            // stop saving from processing when we trigger store updates below
            this.setDisableSaveMonitoring(true);

            // Remove any custom fields we had created in initial board that are now duplicated
            // into this board.
            const cust_fields = (await this.customFieldSrv.getAllCustomFields()) ?? [];
            appLog.info('updateCfgToBrd: found cust fields: ', cust_fields);

            // Set the correct board id and clear the custom fields out
            // since we need ones that match this board
            // and disable backups in case this was a copy from a backup
            this.store.update((s) => {
               s.config.boardId = curBoardId;
               s.config.backup = undefined;
            });

            // manually trigger a save and wait for the update process to complete
            await this.saveConfig();
         } finally {
            this.setDisableSaveMonitoring(false);
         }
      }

      breadcrumb('BoardConfigSrv:updateConfigToBoard: end');

      await this.trelloSrv.frame.hideAlert();
      await this.trelloSrv.frame.alert({
         message: 'Board config migration completed.  Please refresh your browser.',
         duration: 30,
      });

      // If this board was a backup then give users a better description of what just happened
      const board_details = await this.trelloSrv.frame.board('name');
      const board_name = board_details.name;
      if (board_name.includes('backup')) {
         await this.trelloSrv.frame.alert({
            message: 'Board backup created successfully. Please close this tab or window.',
            duration: 30,
         });
      }
   }

   /** Return a snapshot of the current configuration ready for storing
    *  as the new configuration.
    */
   getConfigSnapshot(): IBoardConfig {
      const result: IBoardConfig = {
         ...this.query.getConfig(),
         fields: this.query.getAll(),
      };
      assert(result.version > 0, 'Invalid config version when saving');
      return result;
   }

   /**
    * Create a new field and return it.
    */
   createNewField(
      name: string,
      opts?: {templateField?: IFieldInfo; afterActive?: boolean},
   ): string {
      // Create a default empty field with text type.
      const move_after_active = opts?.afterActive ?? false;
      const default_field = this.getDefaultTextField();
      default_field.name = name;

      // Create a new field and add it
      const template_field = opts?.templateField ?? default_field;
      const new_field: IFieldInfo = {
         ...template_field,
         id: uuidv4(),
         name,
      };
      this.store.add(new_field);

      // Move into position right after the currently active field in the list.
      if (move_after_active) {
         const active_id = this.query.getActiveId();
         if (active_id != null) {
            const active_idx = this.getIndexOfId(active_id);
            const new_idx = this.getIndexOfId(new_field.id);
            if (active_idx != null && new_idx != null) {
               this.moveField(new_idx, active_idx + 1);
            }
         }
      }
      return new_field.id;
   }

   /** Remove the field with the given id. */
   deleteField(id: string): void {
      this.store.remove(id);
   }

   /** Delete the currently active field and move to the next one. */
   deleteActiveField(): string | null {
      const cur_id = this.query.getActiveId();
      if (cur_id != null) {
         this.store.setActive({next: true});
         this.store.remove(cur_id);
      }
      return this.query.getActiveId() ?? null;
   }

   /**
    * Update the given field with new datails.
    */
   updateField(
      id: string,
      newStateOrFn: Partial<IFieldInfo> | UpdateStateCallback<IFieldInfo>,
   ): void {
      if (this.query.hasEntity(id)) {
         if (!isFunction(newStateOrFn)) {
            assert(newStateOrFn.type === undefined, 'Can not set type through updateField');
            this.store.update(id, newStateOrFn);
         } else {
            this.store.update(id, newStateOrFn);
         }
      }
   }

   updateActiveField(newStateOrFn: Partial<IFieldInfo> | UpdateStateCallback<IFieldInfo>): void {
      // NOTE: good hook to debug updates we don't want to propogate
      // console.error('--> UpdateActiveField: called');
      assert(
         isFunction(newStateOrFn) || newStateOrFn.type === undefined,
         'Can not set type through updateField',
      );
      this._updatingActive = true;
      this.store.updateActive(newStateOrFn);
      this._updatingActive = false;
   }

   /**
    * Return true when in middle of updating active.
    * Useful for filtering updates to prevent local updates.
    */
   get updatingActive(): boolean {
      return this._updatingActive;
   }

   /**
    * Move the given field in the list of fields.
    */
   moveField(prevIdx: number, newIdx: number): void {
      this.store.move(prevIdx, newIdx);
   }

   /**
    * Reset the configuration in the backend. (lose all data)
    */
   resetConfig(): void {
      this.store.reset();
   }

   getIndexOfId(id: string): number | null {
      const all_items = this.query.getAll();
      const found_idx = all_items.findIndex((x) => x.id === id);
      return found_idx === -1 ? null : found_idx;
   }

   /**
    * Return a common default text field
    *
    * Used to create basic field with defaults.
    */
   getDefaultTextField(): ITextField {
      const default_field: ITextField = {
         id: uuidv4(),
         name: 'New Field',
         type: FieldType.TEXT,
         ed: TextEditorStyle.INPUT,
         show: {
            frontValue: true,
            frontName: true,
            activity: false,
            hide: false,
         },
         editor: {
            width: EditorWidth.THIRD,
            layout: EditorLayout.LABEL_ON_TOP,
         },
         perms: {
            view: MemberType.OBSERVER,
            edit: MemberType.NORMAL,
         },
         cust: {
            enabled: false,
            id: null,
         },
         color: {
            base: null,
            rules: [],
         },
         mask: {
            opt: TextMaskOption.NONE,
            custom: null,
         },
         calc: {
            enabled: false,
            formula: [],
         },
         visCalc: {
            enabled: false,
            formula: [],
         },
      };
      return default_field;
   }
}
