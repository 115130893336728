/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import {ErrorHandler, NgModule} from '@angular/core';

import {provideFirebaseApp, initializeApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {provideFirestore, getFirestore} from '@angular/fire/firestore';
import {provideFunctions, getFunctions} from '@angular/fire/functions';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoPreloading, Router, RouterModule, Routes} from '@angular/router';

import * as Sentry from '@sentry/angular';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {environment} from 'src/environments/environment';

import {AppComponent} from './app.component';
import {CustomErrorHandler} from './error_handler.srv';
import {CookieWarningComponent} from './init/cookies_warning.comp';
import {InitComponent} from './init/init.comp';
import {appLog} from './services/logging';
import {markedOptionsFactory} from './services/marked_renderer';
import {TrelloSrvResolver} from './services/trello.srv';

const routes: Routes = [
   // note: init component has embedded trello service initialization.
   {path: 'init/cookie_warning', component: CookieWarningComponent},
   {path: 'init', component: InitComponent},
   {
      path: 'settings',
      loadChildren: async () => import('./settings/settings.module').then((m) => m.SettingsModule),
      resolve: {trelloInit: TrelloSrvResolver},
      data: {preloadFlags: true},
   },
   // Don't use trello powerup in this path
   {
      path: 'subscribe',
      loadChildren: async () =>
         import('./subscribe/subscribe.module').then((m) => m.SubscribeModule),
      //resolve: {trelloInit: TrelloSrvResolver},
   },
   {
      path: 'card_back',
      loadChildren: async () =>
         import('./card_back/card_back.module').then((m) => m.CardBackModule),
      resolve: {trelloInit: TrelloSrvResolver},
      data: {preloadFlags: false},
   },
   {
      path: 'table',
      loadChildren: async () => import('./table/table.module').then((m) => m.TableModule),
      resolve: {trelloInit: TrelloSrvResolver},
      data: {preloadFlags: false},
   },
   {
      path: 'admin',
      loadChildren: async () => import('./admin/admin.module').then((m) => m.AdminModule),
      resolve: {trelloInit: TrelloSrvResolver},
      data: {preloadFlags: false},
   },

   {path: '', redirectTo: '/init', pathMatch: 'full'},
];

const COMPS = [
   // Primary app component
   AppComponent,
   InitComponent,

   // Core components
   CookieWarningComponent,
];

// -- FIREBASE SETUP --
// Done this way to handle case of failures due to exceptions
// firefox with 3rd party cookies disabled fails inside fb initialization
let gFbInitWorks = true;
try {
   // @ts-ignore
   const val = typeof indexedDB === 'object';
} catch (e: any) {
   gFbInitWorks = false;
   Sentry.captureMessage('AppModule: gFbInitWorks: false');
}
appLog.log('fbAuthWorks: ', gFbInitWorks);

@NgModule({
   declarations: [...COMPS],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,

      RouterModule.forRoot(routes, {
         //enableTracing: !environment.production,
         preloadingStrategy: NoPreloading,
      }),

      // ngx-markdown module support
      MarkdownModule.forRoot({
         markedOptions: {
            provide: MarkedOptions,
            useFactory: markedOptionsFactory,
         },
      }),

      // Provide firebase related services
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => getFirestore()),
      ...(gFbInitWorks ? [provideFunctions(() => getFunctions())] : []),
      ...(gFbInitWorks ? [provideAuth(() => getAuth())] : []),
      /*
      ...(environment.production
         ? []
         : [AkitaNgDevtools.forRoot({logTrace: false, shallow: false, name: 'akita'})]),
      */

      BrowserAnimationsModule,
   ],
   providers: [
      // Custom error handler to collect analytics
      {provide: ErrorHandler, useClass: CustomErrorHandler},

      // Register sentry tracing for angular
      // see: https://docs.sentry.io/platforms/javascript/guides/angular/
      {provide: Sentry.TraceService, deps: [Router]},
   ],
   bootstrap: [AppComponent],
})
export class AppModule {
   constructor(_trace: Sentry.TraceService) {
      //
   }
}

// Initialize application services before component is started up and module initialized
/*
export function initAppFactory(injector: Injector): () => Promise<void> {
   return async (): Promise<void> => {
      console.time('appInitFactory');
      const trello_srv = injector.get(TrelloSrv);
      await trello_srv.init();
      console.timeEnd('appInitFactory');
   };
}
*/
