export const ADMIN_USERS: {email: string; id: string}[] = [
   {email: 'powerup-bot@amazingpowerups.com', id: '60e05acec6f953559012a486'},
   {email: 'allen@trailridgesolutions.com', id: '6032bf48b2c18a0cd3aa2c5a'},
];

/**
 * The string put into the Role from the Stripe integration
 *
 * Comes from: Product metadata firebaseRole.
 */
export enum StripeProductRole {
   /** V1 subscription for the powerup.  Old license that allows supporters on all board sizes. */
   V1_AMF_USER_POWERUP = 'amz_fields_powerup',

   // -- V2 SUBSCRIPTION TYPES -- //
   V2_AMF_INDIVIDUAL = 'amf_v2_individual',
   V2_AMF_ENTERPRISE = 'amf_v2_enterprise',
}

// IDS of the installed powerups
export const AMF_PROD_POWERUP_ID = '60e068efb294647187bbe4f5';
export const AMF_STAGING_POWERUP_ID = '62419f3402698b1b3400ce17';
export const AMF_LOCAL_POWERUP_ID = '62419ec8a4a8793e9befbe4e';
