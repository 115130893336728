import {akitaConfig, enableAkitaProdMode} from '@datorama/akita';
import {produce} from 'immer';


/**
 * Initialize akita to our standard settings.
 */
export function initAkita(opts: {production: boolean}): void {
   akitaConfig({
      // Use immer for akita production
      producerFn: produce,

      resettable: true,
   });

   if (opts.production) {
      enableAkitaProdMode();
   }
}
