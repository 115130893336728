/**
 * Simple logging service to let us keep logging code in the application
 * at production to help with debugging as needed.
 *
 * Uses loglevel.  https://github.com/pimterry/loglevel
 *  (this is basically the code we would write if we had to)
 *
 * TODO:
 *  - Add support for location.pathname prefix so we know which page
 *    is doing the logging.
 */

import * as Sentry from '@sentry/browser';
import log from 'loglevel';
import {environment} from 'src/environments/environment';
import {getZoneContextPath} from '@util/zone/zone_context-ext';

// Set the default logging
log.setDefaultLevel(environment.production ? 'WARN' : 'TRACE');

// Expose loglevel so we can set it in production debugging cases
// use: trsLog.setLevel(..) to set a level that will stick with localStorage
(window as any).trsLog = log;

/**
 * Common application logger
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const appLog = log.getLogger('app');

/**
 * Add a breadcrumb to the tracing.
 */
export function breadcrumb(msg: string, crumb?: Sentry.Breadcrumb, opts?: {log?: boolean}): void {
   const context_path = getZoneContextPath();
   const context_prefix = context_path == null ? '' : `${context_path}:`;

   const default_crumb: Sentry.Breadcrumb = {
      message: `${context_prefix}${msg}`,
      type: 'default',
   };
   const final: Sentry.Breadcrumb = {...default_crumb, ...(crumb ?? {})};
   Sentry.addBreadcrumb(final);
   if (opts?.log ?? true) {
      appLog.info(`🐾 ${final.message}`, ...(final?.data == null ? [] : [final.data]));
   }
}

// Common timing wrappers so we remove them in production code.
export function timingStart(label: string): void {
   if (log.getLevel() <= log.levels.DEBUG) {
      console.time(label);
   }
}

export function timingEnd(label: string): void {
   if (log.getLevel() <= log.levels.DEBUG) {
      console.timeEnd(label);
   }
}

export function timingStamp(label: string): void {
   if (log.getLevel() <= log.levels.DEBUG) {
      // eslint-disable-next-line no-console
      console.timeStamp(label);
   }
}
