import {Injectable} from '@angular/core';
//import {getDiff} from 'json-difference';
import {ICardData} from '@shared/domain_types';
import {detailedDiff} from 'deep-object-diff';
import {isEqual} from 'lodash-es';
import {CardDataSrv} from '@app/data/card_data.srv';

import {SentrySrv} from '@app/sentry.srv';
import {appLog, breadcrumb, timingEnd, timingStart} from '@app/services/logging';

import {isTrelloError, PostMessageIOError} from '@trello/trello_errors';
import {Trello} from '@trello/trello_powerup_client';
import {assert} from '@util/assert';
import {runLater} from '@util/async';
import {enterZone} from '@util/zone/zone_context-ext';

/**
 * Track the state of one card of data before or after a change.
 */
export interface ICardState {
   card: Trello.PowerUp.Card | null;
   data: ICardData | null;
}

/**
 * Tracking the state of previous and new cards.
 */
export interface ICardChange {
   before: ICardState | null;
   after: ICardState;
   type: 'create' | 'load' | 'copy' | 'change' | 'no_change';
   diff: {
      added: any | undefined;
      deleted: any | undefined;
      updated: any | undefined;
   } | null;
}

export type ICardChangeCB = (f: Trello.PowerUp.IFrame, c: ICardChange) => Promise<void>;

interface IFrameCardPair {
   f: Trello.PowerUp.IFrame;
   cardId: string;
}

type ChangeMonitorState =
   /** The next run has been scheduled, and we are waiting for it. */
   | 'waiting'
   /** We are currently in an active loop running through the current batch. */
   | 'running'
   /** We have finished running and are waiting for the next queue entry to be added. */
   | 'quiet';

/**
 * Service for keeping track of the state of cards on the board
 * so we can be notified when there is a change and the type of change.
 *
 * The service works by building up a list of cards that may have changed
 * and that should be checked.  These are queued up and then processed
 * in a batch after a delay.
 *
 * IMPORTANT: this only triggers off the cardBadge callback so it will not
 *            trigger until the first time the card is shown on screen.
 */
@Injectable({
   providedIn: 'root',
})
export class DataChangeMonitorSrv {
   /** List of call backs to use for each card. */
   protected cardChangeCallbacks: ICardChangeCB[] = [];

   /** Historical map of the cards and the last state we know. */
   protected lastState: Map<string, ICardState> = new Map();

   /** List of items waiting to process a detection. */
   protected pendingDetections: IFrameCardPair[] = [];

   /** Time to wait for batch to accumulate. */
   protected batchingDelayMs: number = 500;

   /** What is the state of processing change detections. */
   protected procState: ChangeMonitorState = 'quiet';

   /** Keep track of the current board we are wathing. */
   protected currentBoardId: string = '';

   constructor(protected cardDataSrv: CardDataSrv, protected sentrySrv: SentrySrv) {}

   /**
    * Add a callback to be hit when there is a card with changes.
    */
   registerCardChangeCallback(cb: ICardChangeCB): void {
      this.cardChangeCallbacks.push(cb);
   }

   /**
    * Called when we think the board has changed.
    * Needs to clear the card states and remove any history from the board.
    */
   onBoardChange(boardId: string): void {
      appLog.info(`DCM: boardChanged: ${boardId}`);
      this.currentBoardId = boardId;
      this.lastState = new Map();
      this.pendingDetections = [];
   }

   /**
    * Add a card with potential for a change.
    */
   enqueueCardForDetection(f: Trello.PowerUp.IFrame, cardId: string): void {
      // Note: handle case when we have not reset the board yet.
      if (this.currentBoardId === '') {
         appLog.error('DCM: enqueuedChange without board set.');
         this.currentBoardId = f.getContext().board;
      }

      // If we are waiting or running, we just queue up another one.
      if (this.procState === 'waiting' || this.procState === 'running') {
         // just add another if we don't have it already
         if (this.pendingDetections.find((x) => x.cardId === cardId) === undefined) {
            this.pendingDetections.push({f, cardId});
         }
      }
      // We are first of new batch, so schedule processing in the future
      // note: track board id in case we wake up to a different board
      else if (this.procState === 'quiet') {
         this.pendingDetections.push({f, cardId});
         this.procState = 'waiting';
         const last_board_id = this.currentBoardId;
         runLater(this.batchingDelayMs)
            .then(async (): Promise<void> => {
               if (last_board_id !== this.currentBoardId) {
                  this.pendingDetections = [];
                  this.procState = 'quiet';
               } else {
                  await enterZone('processChangeBatch', this.processBatch, this);
                  assert(this.procState === 'quiet');
               }
            })
            .catch((e: any) => {
               // error
               this.sentrySrv.handleError(e, 'DataChangeMonitorSrv:batchDelayCall');
            });
      }
   }

   /**
    * Process the current batch of pending changes.
    */
   protected async processBatch(): Promise<void> {
      try {
         appLog.log('---------------[DCM: BATCH START]------------------------------');
         breadcrumb('DCM: processing batch started...');
         timingStart('DCM:processBatch');
         assert(this.procState === 'waiting');

         this.procState = 'running';
         let num_processed = 0;
         while (this.pendingDetections.length > 0) {
            // Make local copy of the pending detections
            const to_detect = this.pendingDetections;
            this.pendingDetections = [];

            // Get a copy of all card data to save time getting individually
            const top_frame = to_detect[0].f;
            const all_cards = await top_frame.cards('all');
            breadcrumb(
               `DCM: batchIteration: checking: ${to_detect.length} of ${all_cards.length} cards`,
            );

            for (const x of to_detect) {
               const f = x.f;
               const card_id = x.cardId;
               const card_data = all_cards.find((c) => c.id === card_id) ?? null;
               num_processed += 1;

               if (card_data != null) {
                  await this.processCardDetection(f, card_id, card_data);
               }
            }
         }
         timingEnd('DCM:processBatch');
         breadcrumb(`DCM: finished batch size: ${num_processed}`);
         appLog.log('---------------[BATCH END]------------------------------');
      } catch (e: any) {
         // Can happen when we have a pending set of changes and switch away from board
         if (
            isTrelloError(e) &&
            (e.name === PostMessageIOError.NOT_HANDLED ||
               e.name === PostMessageIOError.PLUGIN_DISABLED ||
               e.name === PostMessageIOError.INVALID_CONTEXT)
         ) {
            // failed to lookup details, so skip
         } else {
            this.sentrySrv.handleError(e, 'DataChangeMonitorSrv:processBatch');
         }
      } finally {
         // Done running
         this.procState = 'quiet';
      }
   }

   /**
    * Called with active context when there may have been a card change.
    */
   protected async processCardDetection(
      frame: Trello.PowerUp.IFrame,
      cardId: string,
      cardData: Trello.PowerUp.Card,
   ): Promise<void> {
      try {
         assert(cardId != null && cardData != null);
         const board_id = frame.getContext().board;

         // TODO: may be able to improve performance here by getting all cards at once,
         //       but that would require a REST round-trip,
         //       so postMsg to cache may be faster like this
         const amf_data = await this.cardDataSrv.getCardFields(frame, cardId);

         const cur_state = {card: cardData, data: amf_data};
         const prev_state = this.lastState.get(cardId) ?? null;

         const change_record: ICardChange = {
            before: prev_state,
            after: cur_state,
            type: 'change',
            diff: null,
         };

         if (prev_state == null) {
            if (amf_data?.__boardId == null) {
               change_record.type = 'create';
            } else if (amf_data.__boardId === board_id) {
               change_record.type = 'load';
            } else if (amf_data.__boardId !== board_id) {
               change_record.type = 'copy';
            }
         } else {
            if (
               isEqual(prev_state.card, cur_state.card) &&
               isEqual(prev_state.data, cur_state.data)
            ) {
               change_record.type = 'no_change';
            }
         }

         if (change_record.type !== 'no_change') {
            // todo: improve typing on this area
            const detailed_diff = prev_state == null ? null : detailedDiff(prev_state, cur_state);
            change_record.diff = detailed_diff as any;

            appLog.debug(
               `DCM: processCardChange: ${cardId} ${change_record.type} [${cardData.name}]`,
               {
                  data: {
                     change: JSON.parse(JSON.stringify(change_record)),
                     context: frame.getContext(),
                  },
               },
            );
            for (const cb of this.cardChangeCallbacks) {
               await cb(frame, change_record);
            }
         }

         this.lastState.set(cardId, cur_state);
      } catch (e: any) {
         // Can happen when we have a pending set of changes and switch away from board
         if (
            e.name === PostMessageIOError.NOT_HANDLED ||
            e.name === PostMessageIOError.PLUGIN_DISABLED ||
            e.name === PostMessageIOError.INVALID_CONTEXT
         ) {
            appLog.info(`Skipping card ${cardId} because of error: ${e.name}`);
         } else {
            breadcrumb(`processCardDetection: exception on cardId: ${cardId}`, {
               data: {context: frame.getContext(), cardData},
            });
            this.sentrySrv.handleError(e, 'DataChangeMonitorSrv:processCardDetection');
         }
      }
   }
}
