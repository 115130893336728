import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IFieldConfigV18 {
   version: number;
   fields: IFieldInfoV18[];
}

interface IFieldInfoV18 {
   name: string;
   show?: {
      front: boolean;
      back: boolean;
      name: boolean;
      activity: boolean;
      hide: boolean;
   };
}

interface IFieldConfigV19 {
   version: number;
   fields: IFieldInfoV19[];
}

interface IFieldInfoV19 {
   name: string;

   show?: {
      frontValue: boolean;
      frontName: boolean;
      activity: boolean;

      hide?: boolean;
      front?: boolean;
      name?: boolean;
   };
}

/**
 * Refator the way show on front is calculated
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const showFrontRefactorMigration_18_19: IFieldDataMigration = {
   targetVersion: 19,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 18);

      const incoming = c as IFieldConfigV18;
      assert(incoming != null);

      const outgoing = c as IFieldConfigV19;

      for (const f of outgoing.fields) {
         if (f.show != null) {
            const show_things_front = f.show.front!;
            f.show.frontValue = show_things_front;

            // if we used to say don't show front, then both should be false now
            f.show.frontName = show_things_front ? f.show.name! : false;

            delete f.show.hide;
            delete f.show.front;
            delete f.show.name;
         }
      }

      // Set final version
      c.version = 19;
      return c;
   },
};
