/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */

import {environment} from 'src/environments/environment';

const INITIAL_ENABLED_KEY = 'amf:initial_enable_dt';

/**
 * how long do we consider a user "new"
 *
 * 2 hours
 */
const NEW_USER_RANGE_MS = 1000 * 60 * 60 * 2;

export const MF_TIERED_PRICING_FEEDBACK_V1 = 'nffWnLTLxUSEweJLEB4aKw';

/**
 * Called to store information to let us know that we are just enabled.
 */
export function mfOnPowerupEnabled(): void {
   try {
      const already_enabled = localStorage.getItem(INITIAL_ENABLED_KEY) != null;
      if (!already_enabled) {
         const cur_dt = new Date().toISOString();
         localStorage.setItem(INITIAL_ENABLED_KEY, cur_dt);
      }
   } catch (e: any) {
      // pass silently
   }
}

/**
 * True if we think this is a new user that just enabled.
 */
export function mfIsNewUser(): boolean {
   try {
      const initial_enabled_dt = localStorage.getItem(INITIAL_ENABLED_KEY);
      if (initial_enabled_dt != null) {
         const enabled_dt = new Date(initial_enabled_dt);
         const ms_since_enabled = Date.now() - enabled_dt.getTime();
         return ms_since_enabled < NEW_USER_RANGE_MS;
      }
   } catch (e: any) {
      //return false;
   }

   return false;
}

export function injectMfScript(opts?: {id?: string}): void {
   // HACK: Attempt to directly inject google tags
   const win = window as any;
   const doc = document;

   // If not already loaded
   if (win._mfq == null) {
      win._mfq = [];

      if (!environment.production) {
         win.mouseflowDebug = true;
      }

      // XXX: THIS CODE MUST CHANGE

      const mf_elt = doc.createElement('script');
      mf_elt.type = 'text/javascript';
      mf_elt.defer = true;
      mf_elt.src = '//cdn.mouseflow.com/projects/b2b73fbf-0f31-43c1-ac81-54cd26025770.js';

      const head_elt = doc.getElementsByTagName('head')[0];
      head_elt.appendChild(mf_elt);

      if (opts?.id != null) {
         win._mfq.push(['setVariable', 'uid', opts.id ?? 'unknown']);
      }
      win._mfq.push(['setVariable', 'lang', navigator.language]);
      //window._mfq.push(["tag","my-tag"]);
   }
}

function getMfq(): any[] {
   const win = window as any;

   // If not already loaded
   if (win._mfq == null) {
      win._mfq = [];
   }
   return win._mfq as any[];
}

/**
 * See: https://help.mouseflow.com/en/articles/6221377-tagging-recordings-using-code
 */
export function addMfTag(name: string): void {
   getMfq().push(['tag', name]);
}

/**
 * see: https://help.mouseflow.com/en/articles/4312070-custom-variables
 */
export function addMfVar(name: string, val: string): void {
   getMfq().push(['setVariable', name, val]);
}

export function triggerMfFeedback(feedbackId: string): void {
   getMfq().push(['activateFeedback', feedbackId]);
}
