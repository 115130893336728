import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV5 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
// V5
interface IBoardConfigV5 {
   version: number;
   fields: (IFieldInfoV5 | ITextFieldV5)[];
}

interface IFieldInfoV5 {
   name: string;
   type: FieldTypeV5;
}

interface ITextFieldV5 extends IFieldInfoV5 {
   type: FieldTypeV5.TEXT;
}

// V6
interface IBoardConfigV6 {
   version: number;
   fields: (IFieldInfoV6 | ITextFieldV6)[];
}

interface IFieldInfoV6 {
   name: string;
   type: FieldTypeV5;
}

interface ITextFieldV6 extends IFieldInfoV6 {
   type: FieldTypeV5.TEXT;
   ed: TextEditorStyleV6;
}

enum TextEditorStyleV6 {
   INPUT = 'I',
   TEXTAREA = 'T',
}

/**
 * Add editor type configuration.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addEditorTypeConfigMigration_5_6: IFieldDataMigration = {
   targetVersion: 6,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 5);

      const incoming = c as unknown as IBoardConfigV5;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV6;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV5.TEXT) {
            const f_out = f as ITextFieldV6;
            f_out.ed = TextEditorStyleV6.INPUT;
         }
      }

      // Set final version
      c.version = 6;
      return c;
   },
};
