import {BoolStyle} from '@shared/domain_types';

/**
 * Get the image URL for setting for the given style and variant.
 */
export function getImageUrlForBoolStyle(
   boolStyle: BoolStyle,
   variant: 'on' | 'off',
): string | null {
   const opt: IBoolStyleOpts | undefined = BOOL_STYLES[boolStyle];

   if (opt != null) {
      const icon_path = variant === 'on' ? opt.on : opt.off;
      return icon_path != null ? `url('${icon_path}')` : null;
   } else {
      return null;
   }
}

export interface IBoolStyleOpts {
   /** Path to the SVG to show */
   on: string | null;
   /** Path to the SVG to show */
   off: string | null;
}

const FAR_CHECK_SQUARE_ICON = 'assets/bool_icons/far-check-square.svg';
const FAR_CIRCLE_ICON = 'assets/bool_icons/far-circle.svg';
const FAR_CHECK_CIRCLE_ICON = 'assets/bool_icons/far-check-circle.svg';
const FAR_DOT_CIRCLE_ICON = 'assets/bool_icons/far-dot-circle.svg';
const FAR_SQUARE_ICON = 'assets/bool_icons/far-square.svg';
const FAR_THUMBS_DOWN_ICON = 'assets/bool_icons/far-thumbs-down.svg';
const FAR_THUMBS_UP_ICON = 'assets/bool_icons/far-thumbs-up.svg';
const FAS_CHECK_ICON = 'assets/bool_icons/fas-check.svg';
const FAS_LOCK_OPEN_ICON = 'assets/bool_icons/fas-lock-open.svg';
const FAS_LOCK_ICON = 'assets/bool_icons/fas-lock.svg';
const FAR_STAR_ICON = 'assets/bool_icons/far-star.svg';
const FAS_STAR_ICON = 'assets/bool_icons/fas-star.svg';
const FAS_TOGGLE_OFF_ICON = 'assets/bool_icons/fas-toggle-off.svg';
const FAS_TOGGLE_ON_ICON = 'assets/bool_icons/fas-toggle-on.svg';
const FAS_ARROW_DOWN = 'assets/bool_icons/fas-arrow-down.svg';
const FAS_ARROW_UP = 'assets/bool_icons/fas-arrow-up.svg';
const FAS_CIRCLE_ARROW_DOWN = 'assets/bool_icons/fas-circle-arrow-down.svg';
const FAS_CIRCLE_ARROW_UP = 'assets/bool_icons/fas-circle-arrow-up.svg';
const FAS_SQUARE_CARET_DOWN = 'assets/bool_icons/fas-square-caret-down.svg';
const FAS_SQUARE_CARET_UP = 'assets/bool_icons/fas-square-caret-up.svg';
const FAS_XMARK_ICON = 'assets/bool_icons/fas-xmark.svg';

function getIcon(path: string): string {
   return `${window.location.origin}/${path}`;
}

/**
 * References:
 *  https://unicode.org/charts/ - shapes, miscellaneous symbols
 */
export const BOOL_STYLES: {[k: string]: IBoolStyleOpts} = {
   [BoolStyle.ONE]: {
      on: getIcon(FAR_CHECK_SQUARE_ICON),
      off: getIcon(FAR_SQUARE_ICON),
   },
   [BoolStyle.TWO]: {
      on: getIcon(FAR_CHECK_CIRCLE_ICON),
      off: getIcon(FAR_CIRCLE_ICON),
   },
   [BoolStyle.THREE]: {
      on: getIcon(FAR_DOT_CIRCLE_ICON),
      off: getIcon(FAR_CIRCLE_ICON),
   },
   [BoolStyle.FOUR]: {
      on: getIcon(FAS_CHECK_ICON),
      off: null,
   },
   [BoolStyle.FIVE]: {
      on: getIcon(FAR_CHECK_CIRCLE_ICON),
      off: null,
   },
   [BoolStyle.SIX]: {
      on: getIcon(FAR_CHECK_SQUARE_ICON),
      off: null,
   },
   [BoolStyle.SEVEN]: {
      on: getIcon(FAS_TOGGLE_ON_ICON),
      off: getIcon(FAS_TOGGLE_OFF_ICON),
   },
   [BoolStyle.EIGHT]: {
      on: getIcon(FAS_STAR_ICON),
      off: getIcon(FAR_STAR_ICON),
   },
   [BoolStyle.NINE]: {
      on: getIcon(FAR_THUMBS_UP_ICON),
      off: getIcon(FAR_THUMBS_DOWN_ICON),
   },
   [BoolStyle.TEN]: {
      on: getIcon(FAS_LOCK_ICON),
      off: getIcon(FAS_LOCK_OPEN_ICON),
   },
   [BoolStyle.ELEVEN]: {
      on: getIcon(FAS_ARROW_UP),
      off: getIcon(FAS_ARROW_DOWN),
   },
   [BoolStyle.TWELVE]: {
      on: getIcon(FAS_CIRCLE_ARROW_UP),
      off: getIcon(FAS_CIRCLE_ARROW_DOWN),
   },
   [BoolStyle.THIRTEEN]: {
      on: getIcon(FAS_SQUARE_CARET_UP),
      off: getIcon(FAS_SQUARE_CARET_DOWN),
   },
   [BoolStyle.FOURTEEN]: {
      on: getIcon(FAS_CHECK_ICON),
      off: getIcon(FAS_XMARK_ICON),
   },
   [BoolStyle.EMPTY]: {
      on: null,
      off: null,
   },
};
/*
export const BOOL_STYLES: {[k: string]: IBoolStyleOpts} = {
   [BoolStyle.ONE]: {
      on: '✅',
      off: '',
   },
   [BoolStyle.TWO]: {
      on: '✓',
      off: '',
   },
   [BoolStyle.THREE]: {
      on: '❎',
      off: '',
   },
   [BoolStyle.FOUR]: {
      on: '☒', // 2612 ballot box with X
      off: '☐', // 2610 Ballot Box
   },
   [BoolStyle.FIVE]: {
      on: '☑', // 2611 ballot box with check
      off: '☐', // 2610 Ballot Box
   },
   [BoolStyle.SIX]: {
      on: '⦿',
      off: '◯',
   },
   [BoolStyle.SEVEN]: {
      on: '♥',
      off: '♡',
   },
   [BoolStyle.EIGHT]: {
      on: '★',
      off: '☆',
   },
   [BoolStyle.NINE]: {
      on: '🔓',
      off: '🔒',
   },
};
*/
