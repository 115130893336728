import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV13 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
interface IBoardConfigV13 {
   version: number;
   fields: IFieldInfoV13[];
}

interface IFieldInfoV13 {
   name: string;
}

interface IBoardConfigV14 {
   version: number;
   fields: (ITextFieldV14 | IBoolFieldV14 | INumberFieldV14 | IUnknownFieldV14)[];
}

interface IFieldBaseV14 {
   id: string;
   name: string;
   type: FieldTypeV13;
}

interface IUnknownFieldV14 extends IFieldBaseV14 {
   type: FieldTypeV13.UNKNOWN;
}
interface IBoolFieldV14 extends IFieldBaseV14 {
   type: FieldTypeV13.BOOL;
   calc: IFormulaSettingV14;
}

interface INumberFieldV14 extends IFieldBaseV14 {
   type: FieldTypeV13.NUMBER;
   calc: IFormulaSettingV14;
}

interface ITextFieldV14 extends IFieldBaseV14 {
   type: FieldTypeV13.TEXT;
   calc: IFormulaSettingV14;
}

interface IFormulaSettingV14 {
   enabled: boolean;
   formula?: FormulaV14;
}

// Use empty array
type FormulaV14 = any[];

/**
 * Use partially defined types so we ensure we are checking the right ones.
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addFieldCalcs_13_14: IFieldDataMigration = {
   targetVersion: 14,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 13);

      const incoming = c as IBoardConfigV13;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV14;

      // add empty ids
      for (const f of outgoing.fields) {
         if (
            f.type === FieldTypeV13.BOOL ||
            f.type === FieldTypeV13.NUMBER ||
            f.type === FieldTypeV13.TEXT
         ) {
            f.calc = {
               enabled: false,
               formula: [],
            };
         }
      }

      // Set final version
      c.version = 14;
      return c;
   },
};
