import {EditorLayout, EditorWidth, IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IFieldConfigV1 {
   version: number;
   fields: IFieldInfoV1[];
}

interface IFieldConfigV2 {
   version: number;
   fields: IFieldInfoV2[];
}

interface IFieldInfoV1 {
   name: string;
}

interface IFieldInfoV2 {
   name: string;

   editor: {
      width: EditorWidth;
      layout: EditorLayout;
   };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const addEditorMigration_1_2: IFieldDataMigration = {
   targetVersion: 2,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 1);

      const incoming = c as IFieldConfigV1;
      assert(incoming != null);

      const outgoing = c as IFieldConfigV2;

      for (const f of outgoing.fields) {
         f.editor = {
            width: EditorWidth.THIRD,
            layout: EditorLayout.LABEL_ON_TOP,
         };
      }

      // Set final version
      c.version = 2;
      return c;
   },
};
