import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';

import {ExtraErrorData, RewriteFrames} from '@sentry/integrations';
import {BrowserTracing} from '@sentry/tracing';

import {config as rxjs_config} from 'rxjs';
import {SENTRY_DNS_TRS} from '@app/app_config';
import {appLog, breadcrumb} from '@app/services/logging';

import {FbErrorCodes, isFirebaseError} from '@util/firebase';
import {AppModule} from './app/app.module';
import {initAkita} from './app/data/akita_helpers';
import {environment} from './environments/environment';

//const IS_INIT_PAGE = window.location.pathname === '/init';

Sentry.init({
   dsn: SENTRY_DNS_TRS,
   release: `${environment.app_version}-${environment.app_hash}`,
   enabled: environment.production,

   environment: environment.production ? 'production' : 'dev',

   // Increase from default of 100 so we can get more context
   maxBreadcrumbs: 500,

   // TESTING: see if this helps add more details
   attachStacktrace: true,

   // Automatically log sessions for page loads
   autoSessionTracking: true,

   // Configure tracing
   tracesSampleRate: 0.01,

   /** Don't sample any by default. */
   replaysSessionSampleRate: 0.0, //IS_INIT_PAGE ? 0.0 : 1.0,

   /** Sample all errors by default. */
   replaysOnErrorSampleRate: 0.1, // reduced so we don't burn them

   ignoreErrors: [
      // Ignore some errors that we can't handle
      'ChunkLoadError',
      'Network Error',
      'PluginDisabled',
      // see:
      //  - https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      //  - https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',

      // see: https://github.com/firebase/firebase-js-sdk/issues/939 (Chrome transaction timeout)
      'TimeoutError: Transaction timed out due to inactivity',

      // see: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
      'Non-Error promise rejection captured',

      // I think this is internal Firebase IndexDB error  (XXX: may be real??)
      'UnknownError: Error looking up record in object store by key range',

      // Some Chrome browsers for a few users can get cross-origin issues on card_back
      /from accessing a cross\-origin frame/,

      // Ignore issue with chrome ad block extension
      /BetterJsPop/,

      // Ignore issues with chrome extensions
      /chrome\-extension/,
      'EvalError',

      // Ignore a 1Password extension issue
      'get-frame-manager-configuration',

      // Ignore some firebase IndexDB errors
      'AbortError:',

      // Ignore safari destructuring issue
      // https://github.com/firebase/firebase-js-sdk/issues/6636
      'Right side of assignment cannot be destructured',

      // Chrome extension
      `Identifier 'originalPrompt'`,

      'Cannot redefine property: googletag',

      // Indexed DB related errors
      'Connection to Indexed Database server lost',
      'Connection is closing',
      'Database deleted by request',

      // ios fetch errors: see: https://github.com/getsentry/sentry-javascript/issues/7275
      'TypeError: Load failed',
   ],

   // See:
   // - https://docs.sentry.io/platforms/javascript/configuration/integrations/default/
   // - https://develop.sentry.dev/sdk/event-payloads/exception/#exception-mechanism
   // - https://github.com/getsentry/sentry-javascript

   integrations: (integrations) => {
      // Remove the try/catch integration
      // XXX: Re-enable after we get a better handle on things
      const removed_integrations: string[] = []; // ['TryCatch', 'GlobalHandlers']
      const final = integrations.filter((i) => !removed_integrations.includes(i.name));

      final.push(new RewriteFrames());

      final.push(
         // Registers and configures the Tracing integration,
         // which automatically instruments your application to monitor its
         // performance, including custom Angular routing instrumentation
         new BrowserTracing({
            //tracingOrigins: ["localhost", "https://yourserver.io/api"],
            routingInstrumentation: Sentry.routingInstrumentation,
         }),
      );

      final.push(
         new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: false,
            unmask: ['[data-sec-unmask]'],
            stickySession: false,
         }),
      );

      final.push(
         // Add extra data from error that is outside standard attributes
         // provides more context
         new ExtraErrorData(),
      );
      return final;
   },
   /*
   integrations: [
      new Sentry.Integrations.GlobalHandlers({
         // XXX: Re-enable this after other errors get handled
         onerror: false,
         onunhandledrejection: false,
      }),
      // Default strips them all down to root level
      new RewriteFrames(),
   ],
   */

   beforeSend(event, hint) {
      const mechanism = event.exception?.values?.[0]?.mechanism?.type ?? 'unknown';
      const err = hint.originalException;

      // Don't sent firebase network-request-failed.  Some of this are internal to FB and we
      // can't get to them.
      if (mechanism === 'onunhandledrejection' && isFirebaseError(err)) {
         if (err.code === FbErrorCodes.AUTH_NETWORK_REQUEST_FAILED) {
            appLog.warn('AmazingFields: auth/network-request-failed found, skipping.');
            return null;
         }
      }

      return event;
   },
});

// increase stack trace length (optional)
Error.stackTraceLimit = 500;

/** Capture RXJS errors directly. */
rxjs_config.onUnhandledError = (err: any): void => {
   breadcrumb('RXJS global error handler called');
   appLog.error(err);
   Sentry.captureException(err);
};

// Determine if we are in the Trello app
const TRELLO_APP_RE = /TrelloDesktop\/(\S*)/gm;
const APP_MATCH = TRELLO_APP_RE.exec(navigator.userAgent);
const APP_VERSION_STR = APP_MATCH == null ? 'none' : APP_MATCH[1];
Sentry.setTag('trello_desktop', APP_VERSION_STR);


// Validate the we have TrelloPowerUp loaded, if not throw exception to exit.
if (window.TrelloPowerUp == null) {
   // eslint-disable-next-line no-console
   console.warn(
      'TrelloPowerUp not found on window. PowerUp initialization failed. Please reload window',
   );
   Sentry.captureMessage('main: window.TrelloPowerup: null');
} else {
   initAkita({production: environment.production});

   if (environment.production) {
      enableProdMode();
   }

   // eslint-disable-next-line @typescript-eslint/naming-convention
   const longStackTraceZoneSpec = (Zone as any).longStackTraceZoneSpec;
   // increase stack trace limit (optional)
   longStackTraceZoneSpec.longStackTraceLimit = 100;

   /*
Zone.current
   //.fork(new (Zone as any).TaskTrackingZoneSpec())
   .fork(longStackTraceZoneSpec)
   .run(() => {
      // NgZone forks currentZone, which is here longStackTraceZone
      const ng_zone = new NgZone({enableLongStackTrace: true});
      platformBrowserDynamic().bootstrapModule(AppModule, {ngZone: ng_zone});
   });
*/

   platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => appLog.error(err));
}
