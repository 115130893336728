import {
   BadgeColorType,
   FieldDataTypes,
   FieldType,
   IBoolField,
   ICardRefField,
   ICardRefFieldData,
   IDataFieldTypes,
   IDateField,
   IFieldInfo,
   IHeaderField,
   IListField,
   INumberField,
   IStructuralTypes,
   ITabField,
   ITextField,
   MemberType,
   NumberShowType,
   supportsFieldValCalc,
} from '@shared/domain_types';
import {ICustomFieldColor, TrelloCustomFieldTypes} from '@shared/trello_rest_types';
import {isObject} from 'lodash-es';
import {Trello} from 'src/trello';
import {assert} from '@util/assert';

import {isBoolean, isNumber, isString, isValidDate} from '@util/utils';
import {IChecklistSummary} from './card_data.srv';

// Object field types
export function isCardRefFieldData(val: FieldDataTypes | null): val is ICardRefFieldData {
   if (val == null) {
      return false;
   }

   return isObject(val) && (val as any).type === FieldType.CARD_REF;
}

/**
 * Return the default value we want to use if the field is currently
 * null or not set.
 *
 * Note: for many cases this is still something null.
 */
export function getFieldDefaultFormValue(field: IFieldInfo): FieldDataTypes | undefined {
   // For boolean, we must be true or false so go with false
   if (field.type === FieldType.BOOL) {
      return false;
   }
   // For number, leave it unset
   else if (field.type === FieldType.NUMBER) {
      return undefined;
   }
   // Text of '' is treated the same as unset
   else if (field.type === FieldType.TEXT) {
      return '';
   }
   // For date, we leave it unset
   else if (field.type === FieldType.DATE) {
      return undefined;
   } else if (field.type === FieldType.LIST) {
      return [];
   } else if (field.type === FieldType.CARD_REF) {
      return {type: FieldType.CARD_REF, ids: []};
   }
   return '';
}

/**
 * Capture logic about if we should allow viewing the given field
 */
export function isViewingAllowed(
   field: IFieldInfo,
   memberType: Trello.PowerUp.MemberType,
   memberId: string | null,
): boolean {
   if (!isDataField(field)) {
      return true;
   }
   const allowed_type = field.perms.view;
   const allowed_members = field.perms.viewMembers ?? [];

   if (memberId != null && allowed_members.includes(memberId)) {
      return true;
   }

   if (allowed_type === MemberType.ADMIN && memberType === 'admin') {
      return true;
   } else if (
      allowed_type === MemberType.NORMAL &&
      (memberType === 'admin' || memberType === 'normal')
   ) {
      return true;
   } else if (allowed_type === MemberType.OBSERVER) {
      return true;
   }

   return false;
}

export function isEditingAllowed(
   field: IFieldInfo,
   memberType: Trello.PowerUp.MemberType,
   memberId: string | null,
): boolean {
   if (!isDataField(field)) {
      return false;
   }

   const allowed_type = field.perms.edit;
   const allowed_members = field.perms.editMembers ?? [];

   if (memberId != null && allowed_members.includes(memberId)) {
      return true;
   }

   if (allowed_type === MemberType.ADMIN && memberType === 'admin') {
      return true;
   } else if (
      allowed_type === MemberType.NORMAL &&
      (memberType === 'admin' || memberType === 'normal')
   ) {
      return true;
   } else if (allowed_type === MemberType.OBSERVER) {
      return true;
   }

   return false;
}

export function isNumberField(f: IFieldInfo): f is INumberField {
   return f.type === FieldType.NUMBER;
}
export function isTextField(f: IFieldInfo): f is ITextField {
   return f.type === FieldType.TEXT;
}
export function isDateField(f: IFieldInfo): f is IDateField {
   return f.type === FieldType.DATE;
}
export function isBoolField(f: IFieldInfo): f is IBoolField {
   return f.type === FieldType.BOOL;
}
export function isListField(f: IFieldInfo): f is IListField {
   return f.type === FieldType.LIST;
}
export function isTabField(f: IFieldInfo): f is ITabField {
   return f.type === FieldType.TAB;
}
export function isHeaderField(f: IFieldInfo): f is IHeaderField {
   return f.type === FieldType.HEADER;
}
export function isCardRefField(f: IFieldInfo): f is ICardRefField {
   return f.type === FieldType.CARD_REF;
}

/** Return true if a base field is one of the data field types. */
export function isDataField(f: IFieldInfo): f is IDataFieldTypes {
   return isDataFieldType(f.type);
}

/** Return true if a base field is one of the data field types. */
export function isStructuralField(f: IFieldInfo): f is IStructuralTypes {
   return isStructuralFieldType(f.type);
}

export function isDataFieldType(ft: FieldType): boolean {
   return (
      ft === FieldType.NUMBER ||
      ft === FieldType.TEXT ||
      ft === FieldType.DATE ||
      ft === FieldType.BOOL ||
      ft === FieldType.LIST ||
      ft === FieldType.CARD_REF
   );
}

export function isStructuralFieldType(ft: FieldType): boolean {
   return ft === FieldType.HEADER || ft === FieldType.TAB;
}

/**
 * Return true if the field is a number progress field.
 *
 * Needed because nested discriminated union doesn't seem to work.
 */
export function isNumberProgressField(
   field: IFieldInfo | undefined,
): field is INumberField & Required<Pick<INumberField, 'bar'>> {
   return (
      field != null &&
      field.type === FieldType.NUMBER &&
      field.showAs === NumberShowType.PROGRESS_BAR &&
      field.bar != null
   );
}

/**
 * Return true if this field is set to be calculated.
 */
export function isCalculatedField(f: IFieldInfo | undefined): boolean {
   if (f == null) {
      return false;
   } else {
      return supportsFieldValCalc(f) && f.calc.enabled;
   }
}

/**
 * Return true if we should be processing as a checlist linked field.
 */
export function isChecklistLinkedProgressField(
   field: IFieldInfo | undefined,
): field is INumberField & Required<Pick<INumberField, 'bar'>> {
   return isNumberProgressField(field) && field.bar.linkChecklist;
}

/**
 * Compute the value that should be used for a checklist linked progress bar.
 */
export function computeChecklistProgress(
   field: IFieldInfo,
   //cardData: Trello.PowerUp.Card,
   checkLists: IChecklistSummary[] | null,
): number | undefined {
   assert(isChecklistLinkedProgressField(field), 'attempted to use non checklist linked field');

   // No checklists, no value for this card
   if (checkLists == null) {
      return undefined;
   }

   const cl_filter = field.bar.checklistFilter?.toLowerCase() ?? null;

   const matching_checklists =
      cl_filter == null || cl_filter === ''
         ? checkLists
         : checkLists.filter((cl) => {
              return cl.name.toLowerCase().includes(cl_filter);
           });

   const total_items = matching_checklists.reduce((preV, cl) => preV + cl.totalItems, 0);
   const checked_items = matching_checklists.reduce((preV, cl) => preV + cl.completed, 0);

   // no items, so nothing to show
   if (total_items === 0) {
      return undefined;
   }

   // compute percent
   const value_pct: number = Math.round((checked_items / total_items) * 100) / 100;
   const new_value = field.bar.min + (field.bar.max - field.bar.min) * value_pct;
   return new_value;
}

export function convertToBool(data: FieldDataTypes): boolean {
   let str_val: string;

   if (data == null) {
      return false;
   }

   if (isBoolean(data)) {
      return data;
   } else if (isString(data)) {
      if (data === '') {
         return false;
      }

      str_val = data.replace(/^\s+|\s+$/g, ''); // strip
      if (str_val.toLowerCase() === 'true' || str_val.toLowerCase() === 'yes') {
         return true;
      }

      const num = parseFloat(data);
      if (!isNaN(num) && num !== 0) {
         return true;
      }

      //str = str.replace(/,/g, '.');
      //str_val = str_val.replace(/^\s*\-\s*/g, '-');
   } else if (isNumber(data)) {
      return data !== 0;
   }

   return false;
}

export function convertToNumber(data: FieldDataTypes): number | null {
   if (data == null) {
      return null;
   }

   if (isString(data)) {
      return parseFloat(data);
   } else if (isNumber(data)) {
      return data;
   } else if (isBoolean(data)) {
      return data ? 1 : 0;
   } else {
      return null;
   }
}

export function convertToString(data: FieldDataTypes): string {
   return `${data}`;
}

/** Convert from a JS Date to a FieldDate date. */
export function convertToDateFieldStr(date: Date): string {
   return date.toISOString();
}

/** Convert a Field date string to a date object. */
export function convertToDateObj(data: FieldDataTypes): Date | null {
   let ret_date: Date | null = null;

   if (isString(data) && data !== '') {
      ret_date = new Date(data);

      // If we have bad data, then reset it again
      if (!isValidDate(ret_date)) {
         ret_date = null;
      }
   }

   return ret_date;
}

/**
 * Get the string values of selected options from a field of type list
 */
export function getListTextFromValues(f: IListField, listData: string[]): string[] {
   const selected_ids = listData; // Array.isArray(listData) && listData.length > 0 ? listData : [];
   const selected_opts = f.options.filter((o) => selected_ids.includes(o.id));
   return selected_opts.map((o) => o.text);
}

/**
 * Get the custom field type that maps to the given Field Type
 *
 * Returns null if this field type doesn't map to a custom field type.
 */
export function getCustomFieldType(t: FieldType): TrelloCustomFieldTypes | null {
   return t === FieldType.TEXT
      ? 'text'
      : t === FieldType.NUMBER
      ? 'number'
      : t === FieldType.BOOL
      ? 'checkbox'
      : t === FieldType.DATE
      ? 'date'
      : t === FieldType.LIST
      ? 'list'
      : 'text';
}

/**
 * Map from amazing fields color to a trello custom fields color.
 */
export function getCustomFieldColor(c: BadgeColorType | null): ICustomFieldColor {
   switch (c) {
      case BadgeColorType.BLUE:
         return 'blue';
      case BadgeColorType.GREEN:
         return 'green';
      case BadgeColorType.ORANGE:
         return 'orange';
      case BadgeColorType.RED:
         return 'red';
      case BadgeColorType.YELLOW:
         return 'yellow';
      case BadgeColorType.PURPLE:
         return 'purple';
      case BadgeColorType.PINK:
         return 'pink';
      case BadgeColorType.SKY:
         return 'sky';
      case BadgeColorType.LIME:
         return 'lime';
      //case BadgeColorType.LIGHT_GRAY:
      //   return 'light-gray';
      //case null:
      //   return undefined;
      default:
         return 'none';
   }
}

/**
 * Map from custom field color to an amazing field color.
 */
export function getAmfColorFromCustFieldColor(c: ICustomFieldColor): BadgeColorType | null {
   switch (c) {
      case 'blue':
         return BadgeColorType.BLUE;
      case 'green':
         return BadgeColorType.GREEN;
      case 'orange':
         return BadgeColorType.ORANGE;
      case 'red':
         return BadgeColorType.RED;
      case 'yellow':
         return BadgeColorType.YELLOW;
      case 'purple':
         return BadgeColorType.PURPLE;
      case 'pink':
         return BadgeColorType.PINK;
      case 'sky':
         return BadgeColorType.SKY;
      case 'lime':
         return BadgeColorType.LIME;
      case 'none':
         return null;
      default:
         return null;
   }
}

/**
 * Given an amazing field definition, return the corresponding custom field value to use.
 */
export function buildCfValForAmf(
   field: IFieldInfo,
   value: FieldDataTypes,
): Trello.PowerUp.CustomFieldValue | null {
   if (value == null) {
      return null;
   }

   if (field.type === FieldType.TEXT) {
      return {text: value as string};
   } else if (field.type === FieldType.NUMBER) {
      // note: handle case where AMF numbers are empty string and this should be a null
      // eslint-disable-next-line id-blacklist, @typescript-eslint/no-base-to-string
      return value === '' ? null : {number: `${value}`};
   } else if (field.type === FieldType.DATE) {
      return {date: value as string};
   } else if (field.type === FieldType.BOOL) {
      return {checked: (value as boolean) ? 'true' : 'false'};
   } else if (field.type === FieldType.LIST) {
      assert(false, 'buildCfValForAmf should not be called for list types.');
   }

   return null;
}

/**
 * Get an AMF value for the basic types field.
 */
export function getAmfValFromCf(
   field: IFieldInfo,
   custField: Trello.PowerUp.CustomFieldValue | undefined,
): FieldDataTypes {
   assert(field.type !== FieldType.LIST, 'getAmfValFromCf should not be called for list types.');
   // CF is undefined when it should clear
   if (custField == null) {
      // but for boolean this also means 'false'
      if (field.type === FieldType.BOOL) {
         return false;
      }
      return null;
   }
   // Everything else treat like normal
   else {
      if (custField.text != null) {
         return custField.text;
      } else if (custField.number != null) {
         return parseFloat(custField.number);
      } else if (custField.checked != null) {
         return custField.checked === 'true' ? true : false;
      } else if (custField.date != null) {
         return custField.date;
      } else {
         return null;
      }
   }
}
