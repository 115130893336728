import {SetOptional} from 'type-fest';
import {assert} from './utils';

// ---------- CONFIG CONSTANTS ---------------- //
/** The key used to save the configuration. */
export const BOARD_CFG_IDX = 'CFG';

/** The key used to save a hash etag type thing.  Changes every time new data is written. */
export const BOARD_CFG_HASH = 'CFG-HASH';

/** Index where field data is stored on cards. */
export const AMF_FIELD_DATA_IDX = 'FD';

/** Index where hidden field flags are stored on cards. */
export const AMF_FIELDS_HIDDEN_IDX = 'FH';

// ---- Shared types ---- //
export type UUID = string;

// -----------[ CARD TYPES ]-------------- //

/** Base type for storing field data. */
export interface IFieldDataBase {
   type: FieldType;
}

export interface ICardRefFieldData extends IFieldDataBase {
   type: FieldType.CARD_REF;

   /** id of the card to reference. */
   ids: string[];
}

/** The field data items types that are objects. */
export type ObjectFieldDataTypes = ICardRefFieldData;

/**
 * Types that a field can be.
 *
 * Note: Date is stored in ISO formatted datetime string.
 */
export type FieldDataTypes = string | string[] | number | boolean | null | ObjectFieldDataTypes;

/**
 * Interface of the card values that are stored.
 */
export interface ICardData {
   /** Current version of the card field data structures. */
   __version: number;

   /** The board that the card data was written to.  Lets us detect copy. */
   __boardId: string | null;

   /** The UTC time in milliseconds of the last edit made to AMF values on the card. */
   __lastEditSrvMs: number | null;

   // Map from field UUID to the value for that field
   [key: UUID]: FieldDataTypes;
}

export type ICardDataValues = SetOptional<ICardData, '__version' | '__boardId' | '__lastEditSrvMs'>;

/**
 * List of fields to hide.
 */
export interface ICardHiddenFields {
   /** List of the field ids that should be hidden on the given card. */
   hidden: string[];
}

// ------ BOARD TYPES ------------------------------------ //
export enum BackupFrequency {
   DAILY = 'daily',
   WEEKLY = 'weekly',
}

/**
 * Interface of data stored in data storage area about a board.
 */
export interface IBoardConfig {
   /** The current version of the board configuration. */
   version: number;

   /** The name used for the amazing fields section on the cards. */
   sectionName: string;

   /** If true, only the admins can see and adjust card filters. */
   adminOnlyFilters: boolean;

   /**  If true, only the admins can configure amazing fields. (default: false) */
   adminOnlyConfig?: boolean;

   /** Ids of custom fields that we created/own. (used to clean up) */
   custFieldIds: string[];

   /** The list of fields for the board. */
   fields: IFieldInfo[];

   /** The id of the board the config is attached to.  Used to determine if we are created from a template. */
   boardId?: string | null;

   backup?: {
      /** How often to backup.  Null means never. */
      freq: BackupFrequency | null;

      /** The ISO8601 time of last complete backup */
      last: string | null;

      /** Whether to close immediately. */
      close?: boolean;
   };

   /** Internal field that is used to force an invalidation
    * of the cache monitoring.  Should not be used or
    * relied upon.
    */
   invalidate?: string;
}

/** Base list of all types. */
export type IFieldInfo = IDataFieldTypes | IStructuralTypes | IUnknownField;

/** List of types that derive from IDataFieldBase */
export type IDataFieldTypes =
   | INumberField
   | IDateField
   | ITextField
   | IBoolField
   | IListField
   | ICardRefField;

/** Common base for all types of fields. */
export interface IFieldBase {
   /** The UUID of the field.  Remains stable across renames, etc. */
   id: UUID;

   /** The name shown to the user. */
   name: string;

   /** The type of the data stored in the field. */
   type: FieldType;

   /** Configuration for calculating if we field should be visible. */
   visCalc: IFormulaSetting;
}

/** Common Base for fields that store/show data. */
export interface IDataFieldBase extends IFieldBase {
   /** What details to show about the field. */
   show: {
      /** If true, show the value of the field on the front. */
      frontValue: boolean;

      /** If true, show the name of the field on the front. */
      frontName: boolean;

      /** If true, then we track activity of the field changes. */
      activity: boolean;

      /** True if we should be hidden by default. */
      hide: boolean;

      /** Text to display showing a help text description. */
      help?: string;

      /** Optional placeholder to show if value is empty. */
      placeholder?: string;
   };

   editor: {
      /** How wide to make the editor. */
      width: EditorWidth;

      /** How to layout the editor. */
      layout: EditorLayout;
   };

   perms: {
      /** Who can view. */
      view: MemberType;

      /** List of additional member ids that can view. */
      viewMembers?: string[];

      /** Who can edit. */
      edit: MemberType;

      /** List of additional member ids that can edit. */
      editMembers?: string[];
   };

   /** Custom Field Integration. */
   cust: {
      /** If true, custom field connection is enabled. */
      enabled: boolean;

      /** If true, only copy data over to custom fields but not back the other way. */
      //outOnly?: boolean;

      /** Id of the custom field we are linked to. */
      id: string | null;

      /**
       * List of mapping from amf option ids to custom field
       * options.
       */
      options?: {amfId: string; cfId: string}[];
   };
}

export enum FieldType {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',

   HEADER = 'H',
   TAB = 'X',

   // Extended
   CARD_REF = 'R',
}

export function getFieldTypeName(ft: FieldType): string {
   switch (ft) {
      case FieldType.UNKNOWN:
         return 'Unknown';
      case FieldType.TEXT:
         return 'Text';
      case FieldType.NUMBER:
         return 'Number';
      case FieldType.DATE:
         return 'Date';
      case FieldType.BOOL:
         return 'Checkbox';
      case FieldType.LIST:
         return 'Dropdown';
      case FieldType.HEADER:
         return 'Header';
      case FieldType.TAB:
         return 'Tab';
      case FieldType.CARD_REF:
         return 'Card Reference';
      default:
         return 'Unknown';
   }
}

/** Width of the field editor in columns */
export enum EditorWidth {
   // Original sixth splits
   SIXTH = '1',
   THIRD = '2',
   HALF = '3',
   TWO_THIRD = '4',
   FIVE_SIXTH = '5',
   FULL = '6',

   // Extended
   ONE_QUARTER = '1q',
   THREE_QUARTER = '3q',
}

/** How to layout the editor in the UI. */
export enum EditorLayout {
   LABEL_ON_TOP = 'T',
   LABEL_INLINE_LEFT = 'I',
   LABEL_INLINE_RIGHT = 'R',
}

export enum MemberType {
   ADMIN = 'A',
   NORMAL = 'N',
   OBSERVER = 'O',
}

// ------------ UNKNOWN FIELD ---------------- //
export interface IUnknownField extends IFieldBase {
   type: FieldType.UNKNOWN;
}

// ---------- NUMBER FIELD ------------- //
export interface INumberField extends IDataFieldBase {
   type: FieldType.NUMBER;
   showAs: NumberShowType;
   numFormat: NumberFormat;

   /** Currency type to use. */
   currency?: string;

   /** Fractional digits. */
   fDigits?: number;

   /** The step size to use for the number. Default to 1. */
   step?: number;

   color: INumberColorSettings;
   dec?: {
      base: string;
      rules: INumberDecorationRule;
   };

   /** Optional configuration for progress bar. */
   bar?: IProgressBarSettings;

   /** Configuration for calculating field value. */
   calc: IFormulaSetting;
}

export enum NumberFormat {
   /** 1,234.5678 */
   STANDARD = 'N',

   /** 1234.5678 */
   STANDARD_NO_GROUPING = 'n',

   /** 1.23E+03 */
   SCIENTIFIC = 'S',

   /** eng format */
   ENGINEERING = 'E',

   /** $1,234.56 */
   CURRENCY = 'C',

   /** $(1,234.56) */
   ACCOUNTING = 'A',

   /** 1,234.56% (0-1)*/
   PERCENT = 'P',

   /** 12.34% (0-100)*/
   PERCENT_100 = 'p',
}

/** Settings for a progress bar. */
export interface IProgressBarSettings {
   /** The style to use when showing as progress bar. */
   barStyle: ProgressBarStyle;

   /** Should we show the number next to the progress bar. */
   showNum: boolean;

   /** The minimum value for progress. */
   min: number;

   /** The maximum value for progress. */
   max: number;

   /** The width of the bar in characters. */
   width: number;

   /** List of custom Unicode strings used for progress */
   customBars?: string[];

   /** Link to a checklist */
   linkChecklist: boolean;

   /** name filter for the checklist.  Empty or null matches all. */
   checklistFilter: string | null;
}

export interface INumberColorSettings {
   base: BadgeColorType | null;
   rules: INumberColorRule[];
}

export interface INumberColorRule {
   op: NumberRuleOp;
   val: number;
   color: BadgeColorType;
}

export interface INumberDecorationRule {
   op: NumberRuleOp;
   val: number;
   dec: string;
}

export enum NumberShowType {
   NUMBER = 'N',
   PROGRESS_BAR = 'P',
}

export enum ProgressBarStyle {
   ONE = '1',
   TWO = '2',
   THREE = '3',
   FOUR = '4',
   FIVE = '5',
   SIX = '6',
   SEVEN = '7',
   EIGHT = '8',
   NINE = '9',
   TEN = 'A',
   ELEVEN = 'B',
   TWELVE = 'C',
   THIRTEEN = 'D',
   CUSTOM = 'Z',
}

/**
 * List of all the progress bar styles we can use.
 */
export const ALL_PROGRESS_BAR_STYLES = [
   ProgressBarStyle.ONE,
   ProgressBarStyle.TWO,
   ProgressBarStyle.THREE,
   ProgressBarStyle.FOUR,
   ProgressBarStyle.FIVE,
   ProgressBarStyle.SIX,
   ProgressBarStyle.SEVEN,
   ProgressBarStyle.EIGHT,
   ProgressBarStyle.NINE,
   ProgressBarStyle.TEN,
   ProgressBarStyle.ELEVEN,
   ProgressBarStyle.TWELVE,
   ProgressBarStyle.THIRTEEN,
   ProgressBarStyle.CUSTOM,
];

assert(Object.keys(ProgressBarStyle).length === ALL_PROGRESS_BAR_STYLES.length);

export enum NumberRuleOp {
   EQUAL = 'e',
   LT = 'l',
   GT = 'g',
   LTE = 'k',
   GTE = 'i',
   NOT_EQUAL = 'n',
}

// -------------- DATE FIELD -------------- //
export interface IDateField extends IDataFieldBase {
   type: FieldType.DATE;
   dateFormat: DateFormat;
   custom?: {
      // todo: custom data format
      tbd: string;
   };
   color: IDateColorSettings;
   dec?: {
      base: string;
      rules: IDateDecorationRule;
   };

   /** Configuration for calculating field value. */
   calc: IFormulaSetting;
}

export interface IDateColorSettings {
   base: BadgeColorType | null;
   rules: IDateColorRule[];
}

export interface IDateColorRule {
   op: DateRuleOp;
   /** Offset from current for comparison. */
   offset: number;
   /** datetime unit for granularity for comparison. */
   unit: DateUnits;
   color: BadgeColorType;
}

export interface IDateDecorationRule {
   op: DateRuleOp;
   /** Offset from current for comparison. */
   offset: number;
   /** datetime unit for granularity for comparison. */
   unit: DateUnits;
   dec: string;
}

export enum DateFormat {
   DATE_SHORT = 'DS',
   DATE_XSHORT = 'DXS',
   DATE_MEDIUM = 'DM',
   DATE_MEDIUM_WEEKDAY = 'DMW',
   DATE_FULL = 'F',
   DATE_HUGE = 'H',

   DATE_YEAR_ONLY = 'DY',
   DATE_MONTH_YEAR_SHORT = 'DMS',

   TIME_SIMPLE = 't',
   TIME_24_SIMPLE = 't2',
   TIME_OFFSET = 'o',
   TIME_LONG_OFFSET = 'l',
   TIME_24_OFFSET = 'o2',

   DATETIME_SHORT = 'xs',
   DATETIME_XSHORT = 'xxs',
   DATETIME_MEDIUM = 'xm',
   DATETIME_MEDIUM_WEEKDAY = 'xw',
   DATETIME_FULL = 'xf',
   DATETIME_HUGE = 'xh',

   DATETIME_SHORT_OFFSET = 'os',
   DATETIME_MEDIUM_OFFSET = 'om',
   DATETIME_MEDIUM_WEEKDAY_OFFSET = 'ow',
   DATETIME_FULL_OFFSET = 'of',
   DATETIME_HUGE_OFFSET = 'oh',

   DATETIME_24_SHORT = 'xs2',
   DATETIME_24_MEDIUM = 'xm2',
   DATETIME_24_MEDIUM_WEEKDAY = 'xw2',
   DATETIME_24_FULL = 'xf2',
   DATETIME_24_HUGE = 'xh2',
}

export enum DateRuleOp {
   IS = 'i',
   BEFORE = 'b',
   AFTER = 'a',
}

export enum DateUnits {
   MINUTE = 'm',
   HOUR = 'h',
   DAY = 'D',
   WEEK = 'S',
   MONTH = 'M',
   YEAR = 'Y',
}

// --------------- TEXT FIELD ------------- //
export interface ITextField extends IDataFieldBase {
   type: FieldType.TEXT;

   /** The style of the text editor. */
   ed: TextEditorStyle;

   color: ITextColorSettings;
   dec?: {
      base: string;
      rules: ITextDecorationRule;
   };

   mask: {
      opt: TextMaskOption;
      custom: string | null;
   };

   /** Configuration for calculating field value. */
   calc: IFormulaSetting;
}

export enum TextEditorStyle {
   INPUT = 'I',
   TEXTAREA = 'T',
}

export interface ITextColorSettings {
   base: BadgeColorType | null;
   rules: ITextColorRule[];
}

export interface ITextColorRule {
   op: TextRuleOp;
   val: string;
   color: BadgeColorType;
}

export interface ITextDecorationRule {
   op: TextRuleOp;
   val: string;
   dec: string;
}

export enum TextRuleOp {
   IS_EMPTY = 'e',
   NOT_EMPTY = 'E',
   CONTAINS = 'c',
   DOES_NOT_CONTAIN = 'C',
   EQUAL = 'q',
   STARTS_WITH = 's',
   ENDS_WITH = 't',
}

export enum TextMaskOption {
   CUSTOM = 'z',
   NONE = 'n',

   EMAIL = 'A',
   URL = 'B',
   US_PHONE = 'C',
   E164_PHONE = 'D',
   HOURS_MINS = 'E',
}

export interface ITextMaskOptDesc {
   option: TextMaskOption;
   desc: string;
}

export const TEXT_MASK_OPT_DESC: ITextMaskOptDesc[] = [
   {option: TextMaskOption.NONE, desc: 'None'},
   {option: TextMaskOption.EMAIL, desc: 'Email (_@_._)'},
   {option: TextMaskOption.URL, desc: 'URL'},
   {option: TextMaskOption.US_PHONE, desc: 'Phone US (999-999-9999)'},
   {option: TextMaskOption.E164_PHONE, desc: 'Phone E.164 (+999999999999999)'},
   {option: TextMaskOption.HOURS_MINS, desc: 'Hours:Mins (99:99)'},
   {option: TextMaskOption.CUSTOM, desc: 'Custom'},
];

// ---------- BOOLEAN FIELD ------------------------- //
export interface IBoolField extends IDataFieldBase {
   type: FieldType.BOOL;
   bStyle: BoolStyle;
   custom?: {
      on: string;
      off: string;
   };

   /** Optional color configuration to use for on/off state badges */
   color: IBoolColorSettings;

   /** Configuration for calculating field value. */
   calc: IFormulaSetting;
}

export interface IBoolColorSettings {
   on: BadgeColorType | null;
   off: BadgeColorType | null;
}

export enum BoolStyle {
   ONE = '1',
   TWO = '2',
   THREE = '3',
   FOUR = '4',
   FIVE = '5',
   SIX = '6',
   SEVEN = '7',
   EIGHT = '8',
   NINE = '9',
   TEN = 'A',
   ELEVEN = 'B',
   TWELVE = 'C',
   THIRTEEN = 'D',
   FOURTEEN = 'E',
   EMPTY = 'X',
   CUSTOM = 'Z',
}

export const ALL_BOOL_STYLES = [
   BoolStyle.ONE,
   BoolStyle.TWO,
   BoolStyle.THREE,
   BoolStyle.FOUR,
   BoolStyle.FIVE,
   BoolStyle.SIX,
   BoolStyle.SEVEN,
   BoolStyle.EIGHT,
   BoolStyle.NINE,
   BoolStyle.TEN,
   BoolStyle.ELEVEN,
   BoolStyle.TWELVE,
   BoolStyle.THIRTEEN,
   BoolStyle.FOURTEEN,
   BoolStyle.EMPTY,
];

// ------- LIST FIELD ------------------------- //
export interface IListField extends IDataFieldBase {
   type: FieldType.LIST;

   /** List of available options. */
   options: IListOption[];

   /** Color to show when the dropdown is empty. */
   emptyColor: BadgeColorType | null;

   multi: {
      /** If true, allow multiple selection */
      enabled: boolean;

      /** Method to use in managing / showing the list multi options. */
      method: ListMultiMethod;
   };
}

export enum ListMultiMethod {
   SINGLE_BADGE = 'S',
   MULTI_BADGE = 'M',
}

export interface IListOption {
   /**
    * UUID of this list option.  Uses an id so we have a stable value.
    */
   id: string;

   /** The text to display. */
   text: string;

   /** The color to use with it. */
   color: BadgeColorType | null;
}

// ------- CARD REF FIELD ------------------------- //
export interface ICardRefField extends IDataFieldBase {
   type: FieldType.CARD_REF;

   /** If set, restrict to cards on this board. */
   board?: string;

   /** If set, restrict to cards in these lists. */
   lists: string[];

   multi: {
      /** If true, allow multiple selection */
      enabled: boolean;

      /** Method to use in managing / showing the list multi options. */
      method: CardMultiMethod;
   };

   cardType: CardDisplayType;

   /** If true, add backlinks for linked cards. default: false*/
   addBacklink?: boolean;
}

export enum CardMultiMethod {
   SINGLE_BADGE = 'S',
   MULTI_BADGE = 'M',
}

export enum CardDisplayType {
   COMPACT = 'C',
   FULL = 'F',
}

// --- Structural "Fields" items --- //

export type IStructuralTypes = IHeaderField | ITabField;

/** Represents a header to put inline. */
export interface IHeaderField extends IFieldBase {
   type: FieldType.HEADER;

   /** True if we should be hidden by default. */
   hide: boolean;

   /** How wide to make the header */
   width: EditorWidth;

   /** The background color to use for the header. */
   color: BadgeColorType | null;
}

/** Define a tab to group items by in the layout */
export interface ITabField extends IFieldBase {
   type: FieldType.TAB;

   /** True if we should be hidden by default. */
   hide: boolean;
}

// --------- Calculations --------------- //

/**
 * The setting that should be used by each field.
 *
 * should be named: 'calc'
 */
export interface IFormulaSetting {
   /** True iff we should calculate the field value from a formula. */
   enabled: boolean;

   /** Set if we should have a calculation. */
   formula?: Formula;
}

export enum FormulaPartType {
   TEXT = 't',
   FIELD_REF = 'f',
   LIST_FIELD_REF = 'l',
   BOARD_FIELD_REF = 'b',
   CARD_FIELD_REF = 'c',
}

export interface IFormulaPartBase {
   type: FormulaPartType;
}

export interface ITextExpr extends IFormulaPartBase {
   type: FormulaPartType.TEXT;
   text: string;
}

export interface IFieldRef extends IFormulaPartBase {
   type: FormulaPartType.FIELD_REF;
   fid: string;
}

export interface IListFieldRef extends IFormulaPartBase {
   type: FormulaPartType.LIST_FIELD_REF;
   lid: string;
   fid: string;
}

export interface ICardFieldRef extends IFormulaPartBase {
   type: FormulaPartType.CARD_FIELD_REF;
   cid: string;
   fid: string;
}

export interface IBoardFieldRef extends IFormulaPartBase {
   type: FormulaPartType.BOARD_FIELD_REF;
   bid: string;
   fid: string;
}

export type FormulaParts = ITextExpr | IFieldRef | IListFieldRef | ICardFieldRef | IBoardFieldRef;
export type RefParts = Exclude<FormulaParts, ITextExpr>;

export type Formula = FormulaParts[];

export function isTextExpr(p: FormulaParts): p is ITextExpr {
   return p.type === FormulaPartType.TEXT;
}

export function isRefPart(p: FormulaParts): p is RefParts {
   return !isTextExpr(p);
}

export type CalcedFieldTypes = INumberField | ITextField | IBoolField | IDateField;

/** True iff we have a field type that could have calc. */
export function supportsFieldValCalc(f: IFieldInfo): f is CalcedFieldTypes {
   return (
      f.type === FieldType.NUMBER ||
      f.type === FieldType.TEXT ||
      f.type === FieldType.BOOL ||
      f.type === FieldType.DATE
   );
}

// --- MISC ---- //
/**
 * The color to select to set a badge in a rule.
 */
export enum BadgeColorType {
   BLUE = 'b',
   GREEN = 'g',
   ORANGE = 'o',
   RED = 'r',
   YELLOW = 'y',
   PURPLE = 'p',
   PINK = 'k',
   SKY = 's',
   LIME = 'l',
   LIGHT_GRAY = 'z',
}

//
// Note: had to duplicate from trello_powerups so we can use with playwright
export type DomainBadgeColors =
   | 'blue'
   | 'green'
   | 'orange'
   | 'red'
   | 'yellow'
   | 'purple'
   | 'pink'
   | 'sky'
   | 'lime'
   | 'light-gray';

/**
 * Domain data helpers
 *
 * note: must be pure, no angular or anything else outside of here.
 */
export function colorEnumToTrelloBadgeColor(
   v: BadgeColorType | null,
): DomainBadgeColors | undefined {
   switch (v) {
      case BadgeColorType.BLUE:
         return 'blue';
      case BadgeColorType.GREEN:
         return 'green';
      case BadgeColorType.ORANGE:
         return 'orange';
      case BadgeColorType.RED:
         return 'red';
      case BadgeColorType.YELLOW:
         return 'yellow';
      case BadgeColorType.PURPLE:
         return 'purple';
      case BadgeColorType.PINK:
         return 'pink';
      case BadgeColorType.SKY:
         return 'sky';
      case BadgeColorType.LIME:
         return 'lime';
      case BadgeColorType.LIGHT_GRAY:
         return 'light-gray';
      case null:
         return undefined;
      default:
         return undefined;
   }
}

/**
 * Get the key from an enum by value.
 */
export function getEnumKeyByValue<T extends {[index: string]: string}>(
   myEnum: T,
   enumValue: string,
   defaultVal: string | null = null,
): keyof T | null {
   const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
   return keys.length > 0 ? keys[0] : defaultVal;
}
