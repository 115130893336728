// Only define the parts needed

import {assert} from '@util/assert';
import {IBoardConfig} from '../../../../functions/src/shared/domain_types';
import {IFieldDataMigration} from '.';

// V8
enum FieldTypeV8 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

interface IBoardConfigV8 {
   version: number;
   fields: IFieldInfoV8[];
}

type IFieldInfoV8 = INumberFieldV8 | IDateFieldV8 | ITextFieldV8 | IBoolFieldV8;

interface IFieldBaseV8 {
   name: string;
   type: FieldTypeV8;
}

interface INumberFieldV8 extends IFieldBaseV8 {
   type: FieldTypeV8.NUMBER;
   color?: INumberColorSettingsV8;
}

interface INumberColorSettingsV8 {
   enabled: boolean;
   base: BadgeColorTypeV8 | null;
   //rules: INumberColorRule[];
}

interface IDateFieldV8 extends IFieldBaseV8 {
   type: FieldTypeV8.DATE;
   color?: IDateColorSettingsV8;
}

interface IDateColorSettingsV8 {
   enabled: boolean;
   base: BadgeColorTypeV8 | null;
}

interface ITextFieldV8 extends IFieldBaseV8 {
   type: FieldTypeV8.TEXT;
   color?: ITextColorSettingsV8;
}

interface ITextColorSettingsV8 {
   enabled: boolean;
   base: BadgeColorTypeV8 | null;
}

interface IBoolFieldV8 extends IFieldBaseV8 {
   type: FieldTypeV8.BOOL;
   color?: IBoolColorSettingsV8;
}

interface IBoolColorSettingsV8 {
   enabled: boolean;
   on: BadgeColorTypeV8 | null;
   off: BadgeColorTypeV8 | null;
}

//interface IListFieldV8 extends IFieldBaseV8 {
//   type: FieldTypeV8.LIST;
//}

enum BadgeColorTypeV8 {
   BLUE = 'b',
   GREEN = 'g',
   ORANGE = 'o',
   RED = 'r',
   YELLOW = 'y',
   PURPLE = 'p',
   PINK = 'k',
   SKY = 's',
   LIME = 'l',
   LIGHT_GRAY = 'z',
}

// V9
// remove the enabled flag for colors
interface IBoardConfigV9 {
   version: number;
   fields: IFieldInfoV9[];
}

type IFieldInfoV9 = INumberFieldV9 | IDateFieldV9 | ITextFieldV9 | IBoolFieldV9;

interface IFieldBaseV9 {
   name: string;
   type: FieldTypeV8;
}

interface INumberFieldV9 extends IFieldBaseV9 {
   type: FieldTypeV8.NUMBER;
   color: INumberColorSettingsV9;
}

interface INumberColorSettingsV9 {
   base: BadgeColorTypeV8 | null;
   rules: any[];
}

interface IDateFieldV9 extends IFieldBaseV9 {
   type: FieldTypeV8.DATE;
   color: IDateColorSettingsV9;
}

interface IDateColorSettingsV9 {
   base: BadgeColorTypeV8 | null;
   rules: any[];
}

interface ITextFieldV9 extends IFieldBaseV9 {
   type: FieldTypeV8.TEXT;
   color: ITextColorSettingsV9;
}

interface ITextColorSettingsV9 {
   base: BadgeColorTypeV8 | null;
   rules: any[];
}

interface IBoolFieldV9 extends IFieldBaseV9 {
   type: FieldTypeV8.BOOL;
   color: IBoolColorSettingsV9;
}

interface IBoolColorSettingsV9 {
   on: BadgeColorTypeV8 | null;
   off: BadgeColorTypeV8 | null;
}

//interface IListFieldV9 extends IFieldBaseV9 {
//   type: FieldTypeV8.LIST;
//}

/**
 * Change date rules to use date offsets.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const makeBgColorDefault_8_9: IFieldDataMigration = {
   targetVersion: 9,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 8);

      const incoming = c as unknown as IBoardConfigV8;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV9;
      assert(outgoing != null);

      // If field has potential color settings
      // - if it is set, then remove enabled
      // - if not, then create default
      for (const f of incoming.fields) {
         if (
            f.type === FieldTypeV8.TEXT ||
            f.type === FieldTypeV8.NUMBER ||
            f.type === FieldTypeV8.DATE ||
            f.type === FieldTypeV8.BOOL
         ) {
            const f_in = f;
            const f_out = f as IFieldInfoV9;

            if (f_in.color != null) {
               // @ts-expect-error delete an optional
               delete f_in.color.enabled;
            } else {
               f_out.color =
                  f_out.type === FieldTypeV8.BOOL
                     ? {on: null, off: null}
                     : {
                          base: null,
                          rules: [],
                       };
            }
         }
      }

      // Set final version
      c.version = 9;
      return c;
   },
};
