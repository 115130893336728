import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

import {isFirebaseError} from '@util/firebase';

import {SentrySrv} from './sentry.srv';
import {BackendFunctionsSrv} from './services/backend_functions.srv';
import {appLog, breadcrumb} from './services/logging';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
   fnSrv: BackendFunctionsSrv;

   constructor(protected sentrySrv: SentrySrv) {
      super();

      this.fnSrv = new BackendFunctionsSrv(sentrySrv, undefined);
   }

   handleError(err: any): void {
      super.handleError(err);

      // Reload if this looks like an error from a partial update of the powerup
      if (this.isReloadError(err)) {
         window.location.reload();
      }

      // Skip some common Trello internal issues we can't do anything about
      if (this.shouldIgnoreError(err) || this.isReloadError(err)) {
         return;
      }

      // Only log errors for production deployments (dev no)
      if (environment.production) {
         if (isFirebaseError(err)) {
            breadcrumb('CustomErrorHandler: Firebase Error in handleError', {
               data: {name: err.name, code: err.code, message: err.message},
            });
         }

         // tag error so we know where it came from
         breadcrumb('CustomErrorHandler:handleError', undefined, {log: false});

         // Now send to our area
         const tags: {[t: string]: string} = {
            handler: 'AngularErrorHandler',
         };

         let err_msg = String(err);
         if (isFirebaseError(err)) {
            err_msg = `FB-[${err.code}] ${err_msg}`;
         }
         const stack = String(err.stack);

         // Send to sentry if enabled
         this.sentrySrv.handleError(err, 'AngularErrorHandler', tags);

         this.fnSrv
            .logException({
               message: `EXCEPTION: ${err_msg}`,
               data: {
                  description: err_msg,
                  stack,
               },
            })
            .catch((_e: unknown) => {
               appLog.warn('unable to call logException. skipping...');
            });
      }
   }

   shouldIgnoreError(err: any): boolean {
      const err_msg = String(err);

      // Ignore some common Trello related issues
      const ignore_res = [
         /Network Error/,
         /network error/,
         /PostMessageIO:PluginDisabled/,
         /reading 'initialize'/,
      ];

      for (const re of ignore_res) {
         if (re.test(err_msg)) {
            return true;
         }
      }

      return false;
   }

   /**
    * Return true if this error signals that the application has changed and should be reloaded.
    */
   isReloadError(err: any): boolean {
      const err_msg = String(err);

      // Ignore some common Trello related issues
      const reload_res = [/Loading chunk [\d]+ failed/, /ChunkLoadError/];

      for (const re of reload_res) {
         if (re.test(err_msg)) {
            return true;
         }
      }

      return false;
   }
}
