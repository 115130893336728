import {Timestamp} from '@angular/fire/firestore';

/** Helper to convert raw firestore json data into a date. */
export function rawTimeToDate(r: {
   nanoseconds?: number;
   seconds?: number;
   _nanoseconds?: number;
   _seconds?: number;
}): Date | undefined {
   if (r == null) {
      return undefined;
   }

   const ts = new Timestamp(
      // @ts-expect-error creates invalid timestamp on purpose
      r.seconds ?? r._seconds ?? undefined,
      r.nanoseconds ?? r._nanoseconds ?? undefined,
   ).toDate();
   return ts;
}
