import {Injectable, Injector} from '@angular/core';

import {Auth} from '@angular/fire/auth';
import {
   arrayRemove,
   arrayUnion,
   collection,
   doc,
   Firestore,
   serverTimestamp,
   setDoc,
   getDoc,
} from '@angular/fire/firestore';
import {BackupFrequency} from '@shared/domain_types';
import {IUserProps, ProdEvents} from '@shared/prod_events';
import {
   CREATE_BACKUP_EVENT_NAME,
   POWERUP_DISABLED,
   POWERUP_ENABLED,
   POWERUP_REMOVE_DATA,
} from '@shared/shared_analytics_events';
import {UserEventName, USER_DB, BOARD_METRICS_DB} from '@shared/shared_db_types';
import {IBoardData} from '@shared/trello_rest_types';
import {Mutex} from 'async-mutex';
import axios from 'axios';
import dayjs from 'dayjs';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {BoardConfigSrv} from 'src/app/data/board_config.store';
import {IBoardMetricDocClient, IUserDocClient} from 'src/app/data/db_types';
import {TrelloSrv} from 'src/app/services/trello.srv';
import {Trello} from 'src/trello';

import {
   AMAZING_FIELDS_SUPPORT_EMAIL,
   AMZ_FIELDS_LOGO,
   POLLING_INTERVAL_MS,
   SECTION_ICON,
   EXPAND_WINDOW,
   SHRINK_WINDOW,
   QUESTION_ICON,
   AMF_DOCS_SITE_URL,
} from '@app/app_config';
import {CardDataSrv} from '@app/data/card_data.srv';
import {CustomFieldsSrv} from '@app/data/custom_fields.srv';
import {CardBadgesFactory} from '@app/data/format/card_badges_factory';
import {SegmentSrv} from '@app/data/segment.srv';
import {SentrySrv} from '@app/sentry.srv';
import {BackendFunctionsSrv} from '@app/services/backend_functions.srv';
import {FbAuthSrv} from '@app/services/fb_auth.srv';
import {appLog, breadcrumb, timingEnd, timingStart} from '@app/services/logging';
import {AMAZING_FIELDS_APP_NAME} from '@trello/powerup_settings';
import {isDisabledError, isTrelloError, PostMessageIOError} from '@trello/trello_errors';
import {assert} from '@util/assert';
import {runLater} from '@util/async';

import {FbErrorCodes, isFirebaseError} from '@util/firebase';
import {rawTimeToDate} from '@util/firebase_helpers';
import {HttpStatus} from '@util/http_status';
import {enterZone} from '@util/zone/zone_context-ext';
import {DataChangeMonitorSrv, ICardChange} from './data_change_monitor.srv';
import {IHookFunctions} from './hook_interfaces';
import {addMfTag, mfOnPowerupEnabled} from './mouseflow_inject';

/**
 * All the hooks for initializing with Trello to take actions.
 */
@Injectable({
   providedIn: 'root',
})
export class InitHooksSrv implements IHookFunctions {
   static NextId: number = 1;

   /** Reusable badge factory. */
   badgeFactory: CardBadgesFactory | null = null;

   /** Unique id for debugging. */
   uniqueId: number;

   /** Last known board id we were running on. */
   protected lastBoardId: string | null = null;

   /** Board id tracking.  Init is in one frame, so we track the board id. */
   protected boardIdSubj: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

   /** When true we have the polling subscription initialized. */
   protected pollingSub: Subscription | null = null;

   /** When true the polling callback is running. */
   protected _pollingExecuting: boolean = false;

   /** Current iteration count of polling. */
   protected _pollingIteration: number = 0;

   /** Protect access to service initialization. */
   protected serviceReadyMutex = new Mutex();

   /** Set to true iff we run into an issue that will not get resolved (backup not allowed) */
   protected skipBoardBackup: boolean = false;

   // eslint-disable-next-line max-params
   constructor(
      protected trelloSrv: TrelloSrv,
      protected fbAuthSrv: FbAuthSrv,
      protected boardCfgSrv: BoardConfigSrv,
      protected cardDataSrv: CardDataSrv,
      protected custFieldSrv: CustomFieldsSrv,
      protected injector: Injector,
      protected segment: SegmentSrv,
      protected fireAuth: Auth,
      protected firestore: Firestore,
      protected fnSrv: BackendFunctionsSrv,
      protected sentrySrv: SentrySrv,
      protected dataMonitor: DataChangeMonitorSrv,
   ) {
      this.uniqueId = InitHooksSrv.NextId;
      InitHooksSrv.NextId += 1;

      this.registerActiveUseMetric();
      this.registerMonitors();

      this.boardIdSubj
         .pipe(
            filter((b): b is string => b != null),
            distinctUntilChanged(),
         )
         .subscribe((bid) => {
            breadcrumb('HookSrv:BoardIdSubj: board changed, telling data monitor...');
            this.dataMonitor.onBoardChange(bid);
         });
   }

   /**
    * Show buttons for the board on top right corner of board.
    *
    * https://developer.atlassian.com/cloud/trello/power-ups/capabilities/board-buttons/
    */
   // eslint-disable-next-line @typescript-eslint/require-await
   async boardButtonsHook(t: Trello.PowerUp.IFrame): Promise<Trello.PowerUp.BoardButtonCallback[]> {
      try {
         breadcrumb(`[${this.uniqueId}] ==> HOOK: boardButtonsHook: called`);

         //timingStart('boardButtonsHook');

         // Make sure we have trello srv up and running
         await this.ensureServicesReadyForBoard(t);

         this.registerPolling();

         const section_icon_path = `${window.location.origin}${AMZ_FIELDS_LOGO}`;

         const settings_btn: Trello.PowerUp.BoardButtonCallback = {
            text: 'Fields',
            icon: {dark: section_icon_path, light: section_icon_path},
            callback: async (f: Trello.PowerUp.IFrame) => {
               //return this.showSettingsHook(t);
               return f.popup({
                  title: 'Amazing Fields',
                  items: [
                     {
                        text: '🗖 Table View / Search / Export',
                        // eslint-disable-next-line @typescript-eslint/promise-function-async
                        callback: () => {
                           return this.showTablePage(f);
                        },
                     },
                     {
                        text: '⚙ Settings',
                        // eslint-disable-next-line @typescript-eslint/promise-function-async
                        callback: () => {
                           return this.showSettingsHook(t);
                        },
                     },
                  ],
                  mouseEvent: null as any,
               });
            },
            // Only show if you have edit rights
            condition: 'edit',
         };

         //timingEnd('boardButtonsHook');

         // In development we leave a board button so we have easy access
         //return environment.production ? [] : [settings_btn];
         return [settings_btn];
      } catch (e: any) {
         if (isDisabledError(e)) {
            breadcrumb(`Known error found: ${e.name}: skipping buttons hook`);
         } else if (
            isTrelloError(e) &&
            (e.name === PostMessageIOError.NOT_HANDLED ||
               e.name === PostMessageIOError.INVALID_CONTEXT)
         ) {
            breadcrumb(`cardBadgesHook: Trello error found, skipping card badges for this card.`);
         } else {
            this.sentrySrv.handleError(e, 'boardButtonsHook:unexpected_exception');
         }
         // If there are errors, then just return no buttons
         return [];
      }
   }

   /**
    * Redirect card back to the card_back route.
    */
   async cardBackSectionHook(t: Trello.PowerUp.IFrame): Promise<Trello.PowerUp.CardBackSection> {
      try {
         breadcrumb(`[${this.uniqueId}] ==> HOOK: cardBackSectionHook: called`);
         const section_icon_path = `${window.location.origin}${SECTION_ICON}`;

         // Make sure we have trello srv up and running
         // - assert: board config is loaded and ready
         await this.ensureServicesReadyForBoard(t);

         // -- Note Use Badge factory init to make sure we have config loaded -- //
         // - need this to check adminOnlyFilter stuff
         // Lazily lookup the badge factory - ?? why lazy?

         // XXX: Move this to common init code
         if (this.badgeFactory == null) {
            this.badgeFactory = this.injector.get(CardBadgesFactory);
         }
         await this.badgeFactory.initialize();

         // assert: we now have a valid configuration loaded
         const cfg = this.boardCfgSrv.query.getValue().config;
         const num_fields = this.boardCfgSrv.query.getAll().length;

         // Look up if user is an admin
         const permissions = this.trelloSrv.permissions;
         const membership_type = await this.trelloSrv.getBoardMemberType();
         const show_filter =
            permissions.board === 'write' && (!cfg.adminOnlyFilters || membership_type === 'admin');
         const btn_to_show: 'field_config' | 'visible' | null =
            num_fields === 0 ? 'field_config' : show_filter ? 'visible' : null;

         return {
            title: cfg.sectionName,
            icon: section_icon_path,
            content: {
               type: 'iframe',
               url: t.signUrl('./card_back'),
               height: 25, // Start small, then it will expand
            },
            // Show filter button if we can/should use it
            action:
               btn_to_show === 'field_config'
                  ? {
                       text: 'Configure Fields',

                       callback: (tFrame: Trello.PowerUp.IFrame) => {
                          void this.showSettingsHook(tFrame);
                       },
                    }
                  : btn_to_show === 'visible'
                  ? {
                       text: 'Visible Fields',
                       callback: async (tFrame: Trello.PowerUp.IFrame): Promise<void> => {
                          try {
                             await tFrame.popup({
                                title: `Fields to show on this card`,
                                url: './card_back/filter',
                                height: 200,
                                mouseEvent: null as any,
                             });
                          } catch (e: any) {
                             appLog.error('Failed to load Visibility popup. Continuing.');
                          }
                       },
                    }
                  : undefined,
         };
      } catch (e: any) {
         if (isDisabledError(e)) {
            breadcrumb(`Known error found: ${e.name}: skipping cardback hook`);
         } else {
            this.sentrySrv.handleError(e, 'cardBackSectionHook:unexpected_exception');
         }
         // If there are errors, then just return no buttons
         // @ts-expect-error Nothing valid to return
         return undefined;
      }
   }

   /** Badges on the front of the card. */
   async cardBadgesHook(t: Trello.PowerUp.IFrame): Promise<Trello.PowerUp.CardBadge[]> {
      try {
         const time_badges = false;
         const card_id = t.getContext().card;

         breadcrumb(`[${this.uniqueId}] ==> HOOK: cardBadgesHook: called card:${card_id}`);
         const timer_label = `badgesHook:timing:card-${t.getContext().card}`;
         if (time_badges) {
            timingStart(timer_label);
            performance.mark(`${timer_label}-start`);
         }

         // Make sure we have trello srv up and running
         // note: is there a race condition here if old and new board are in progress at same time
         // assert: board configuration is loaded and ready
         await this.ensureServicesReadyForBoard(t);

         // Trigger off change processing
         // note: could do this in parallel but don't want the race condition handling
         if (card_id != null) {
            this.dataMonitor.enqueueCardForDetection(t, card_id);
         }

         // Lazily lookup the badge factory
         // ?? why lazy?
         if (this.badgeFactory == null) {
            this.badgeFactory = this.injector.get(CardBadgesFactory);
         }

         // ensure factory is initialized and reload config as needed
         // - the configuration should have been loaded in ensureServicesReadyForBoard
         //   and would have only been reloaded if we are on a new board now.
         await this.badgeFactory.initialize();

         //appLog.log(`cardBagesHook: context: [${this.uniqueId}]: `, this.trelloSrv.context);
         let badges: Trello.PowerUp.CardBadge[] = [];
         badges = await this.badgeFactory.buildCardBadges(t);

         if (time_badges) {
            timingEnd(timer_label);
            performance.mark(`${timer_label}-end`);
            performance.measure(
               `${timer_label}-total`,
               `${timer_label}-start`,
               `${timer_label}-end`,
            );
         }

         // Signal to E2E code that we have updated some badges
         const bu_func = (window as any).e2e_badgesUpdated;
         if (bu_func != null) {
            appLog.log('cardBadgesHook: calling badges updated');
            await bu_func();
         }
         return badges;
      } catch (e: any) {
         breadcrumb('cardBadgesHook: got error...');
         if (isDisabledError(e)) {
            breadcrumb(`cardBadgesHook: Known error found: ${e.name}: skipping badges hook`);
         } else if (
            isTrelloError(e) &&
            (e.name === PostMessageIOError.NOT_HANDLED ||
               e.name === PostMessageIOError.INVALID_CONTEXT)
         ) {
            breadcrumb(`cardBadgesHook: Trello error found, skipping card badges for this card.`);
         } else {
            breadcrumb(`cardBadgesHook: exception, name: [${e.name}]`, {
               data: {
                  name: e.name,
                  message: e.message,
               },
            });
            this.sentrySrv.handleError(e, 'boardButtonsHook:unexpected_exception');
         }
         // If there are errors, then just return no buttons
         return [];
      }
   }

   /** Badges on the back of the card. */
   // eslint-disable-next-line @typescript-eslint/require-await
   async cardDetailBadgesHook(
      _t: Trello.PowerUp.IFrame,
   ): Promise<Trello.PowerUp.CardDetailBadge[]> {
      return [];
   }

   /**
    * User clicked on gear icon to get to power up settings.
    *
    * See: https://developer.atlassian.com/cloud/trello/power-ups/capabilities/show-settings/
    */
   async showSettingsHook(t: Trello.PowerUp.IFrame): Promise<void> {
      breadcrumb(`[${this.uniqueId}] ==> HOOK: showSettings: called`);
      this.segment.initialize(t);

      // note: use fnSrv so we can handle this even when not yet logged in
      this.fnSrv.logProdEvent({
         type: ProdEvents.SETTINGS_OPEN,
         uid: t.getContext().member,
         props: {},
      });

      type WindowType = 'modal' | 'bar';
      const window_state_key = 'amf_settings_win_type';

      const biz_blue = '#026AA7';

      const expand_window_icon_path = `${window.location.origin}${EXPAND_WINDOW}`;
      const shrink_window_icon_path = `${window.location.origin}${SHRINK_WINDOW}`;
      const question_icon_path = `${window.location.origin}${QUESTION_ICON}`;

      function getWinType(): WindowType {
         let wtype: WindowType = 'modal'; // default
         try {
            const found_type = localStorage.getItem(window_state_key);
            // default to modal when nothing set or bad value
            wtype = found_type === 'bar' ? 'bar' : 'modal';
         } catch (e: any) {
            // ignore
         }
         return wtype;
      }

      function setWinType(wtype: WindowType): void {
         try {
            localStorage.setItem(window_state_key, wtype);
         } catch (e: any) {
            // ignore
         }
      }

      // check that user didn't close while saving
      const onSettingsClosed = (): void => {
         breadcrumb('settings closed');

         if (this.boardCfgSrv.isConfigSaving()) {
            void t.alert({
               message: `Settings were closed while saving. This may leave settings partial saved. Please let saving complete befor closing.`,
               duration: 30,
            });
         }
      };

      const window_type: WindowType = getWinType();

      if (window_type === 'modal') {
         await t.modal({
            url: './settings',
            title: `${AMAZING_FIELDS_APP_NAME} Settings`,
            fullscreen: true,
            accentColor: biz_blue,
            callback: () => {
               onSettingsClosed();
            },
            actions: [
               {
                  icon: question_icon_path,
                  alt: 'Open Documentation',
                  position: 'left',
                  url: AMF_DOCS_SITE_URL,
                  callback: () => {
                     breadcrumb('Open docs site');
                  },
               },
               {
                  icon: shrink_window_icon_path,
                  alt: 'Shrink window',
                  position: 'right',
                  callback: async () => {
                     setWinType('bar');
                     await t.closeModal();
                     void this.showSettingsHook(t);
                  },
               },
            ],
         });
      } else {
         await t.boardBar({
            url: './settings',
            title: `${AMAZING_FIELDS_APP_NAME} Settings`,
            height: 2000, // Just use a large value to get the default 60% of height
            resizable: true,
            accentColor: biz_blue,
            callback: () => {
               onSettingsClosed();
            },

            actions: [
               {
                  icon: question_icon_path,
                  alt: 'Open Documentation',
                  position: 'left',
                  url: AMF_DOCS_SITE_URL,
                  callback: () => {
                     breadcrumb('Open docs site');
                  },
               },
               {
                  icon: expand_window_icon_path,
                  alt: 'Expand window',
                  position: 'right',
                  callback: async () => {
                     setWinType('modal');
                     await t.closeBoardBar();
                     void this.showSettingsHook(t);
                  },
               },
            ],
         });
      }
      return;
   }

   authorizationStatusHook(_t: Trello.PowerUp.IFrame): Trello.PowerUp.AuthorizationStatusResult {
      /*
      return {
         authorized: false,
      };
      */

      return {
         authorized: true,
      };
   }

   /**
    * When need authorization, just show settings.
    */
   async showAuthorizationHook(t: Trello.PowerUp.IFrame): Promise<void> {
      return this.showSettingsHook(t);
   }

   /**
    * Called when powerup is enabled.
    */
   async onEnableHook(t: Trello.PowerUp.IFrame): Promise<void> {
      breadcrumb(`[${this.uniqueId}] ==> HOOK: onEnableHook: called`);
      breadcrumb('Powerup enabled');
      mfOnPowerupEnabled();
      this.segment.initialize(t);
      const member_details = await t.member('all');
      const org_details = await t.organization('all');
      const context = t.getContext();

      const rest_api = t.getRestApi();
      const authorized = await rest_api.isAuthorized();

      this.fnSrv.logEvent({eventName: POWERUP_ENABLED});

      // This will potentially create user in backend and register conversions
      this.fnSrv.logUserEvent({
         eventName: UserEventName.ENABLE,
         userId: context.member,
         boardId: context.board,
         username: member_details.username ?? null,
      });

      // map the user id on enable because that is a pretty good place to get it
      this.fnSrv.logProdEvent({
         type: ProdEvents.IDENTIFY,
         uid: context.member,
         props: {},
         userProps: {
            username: member_details.username ?? undefined,
            avatar: member_details.avatar ?? undefined,
            name: member_details.fullName ?? undefined,
            organization: org_details.name ?? undefined,
            trello_paid_status: member_details.paidStatus ?? undefined,
         },
      });
      this.fnSrv.logProdEvent({
         type: ProdEvents.ENABLE,
         uid: context.member,
         board_id: context.board,
         props: {
            is_authorized: authorized,
         },
      });

      return this.showSettingsHook(t);
   }

   /**
    * Called when the user disables the powerup.
    */
   async onDisableHook(t: Trello.PowerUp.IFrame): Promise<void> {
      try {
         // Note: if there are delays here, we may run into errors because the powerup is
         //       already disabled before getting here
         breadcrumb(`[${this.uniqueId}] ==> HOOK: onDisableHook: called`);
         breadcrumb('Powerup disabled');

         const closing_proms: Promise<any>[] = [];

         const member_details = await t.member('all');
         const context = t.getContext();

         // Attempt to log disablement IFF we have been authorized and are logged in
         breadcrumb('board_id: ', {data: {board: context.board}});

         const cur_user = this.fireAuth.currentUser;
         //breadcrumb('user: ', cur_user);
         if (cur_user != null) {
            closing_proms.push(
               this.sendBoardAction({
                  userId: cur_user.uid,
                  boardId: context.board,
                  boardAction: 'disable',
               }),
            );
         }

         // Collect analytics about disabling
         addMfTag('DISABLED');
         this.segment.initialize(t);
         this.fnSrv.logEvent({eventName: POWERUP_DISABLED});
         this.fnSrv.logUserEvent({
            eventName: UserEventName.DISABLE,
            userId: context.member,
            boardId: context.board,
            username: member_details.username ?? null,
         });

         this.fnSrv.logProdEvent({
            type: ProdEvents.DISABLE,
            uid: context.member,
            board_id: context.board,
            props: {},
         });

         void t.alert({
            message: `Thanks for trying ${AMAZING_FIELDS_APP_NAME}. Let us know if you ran into problems or we can assist. ${AMAZING_FIELDS_SUPPORT_EMAIL}`,
            duration: 30,
         });

         await Promise.all(closing_proms);
      } catch (e: unknown) {
         appLog.warn('Disable handler had failure. Some cleanup may have been skipped.');
      }

      return;
   }

   async removeDataHook(t: Trello.PowerUp.IFrame): Promise<void> {
      breadcrumb(`[${this.uniqueId}] ==> HOOK: removeDataHook: called`);
      breadcrumb('User data removed');
      this.segment.initialize(t);
      const member_details = await t.member('all');
      const context = t.getContext();

      this.fnSrv.logEvent({eventName: POWERUP_REMOVE_DATA});
      this.fnSrv.logUserEvent({
         eventName: UserEventName.REMOVE_POWERUP_DATA,
         userId: context.member,
         boardId: context.board,
         username: member_details.username ?? null,
      });
      this.fnSrv.logProdEvent({
         type: ProdEvents.REMOVE_POWERUP_DATA,
         uid: context.member,
         board_id: context.board,
         props: {},
      });

      // wait a little bit
      await runLater(100);
      return;
   }

   /**
    * User selected to see table page.
    */
   protected async showTablePage(t: Trello.PowerUp.IFrame): Promise<void> {
      breadcrumb(`[${this.uniqueId}] ==> show table page`);

      this.trelloSrv.logProdEvent({
         type: ProdEvents.TABLE_OPEN,
         props: {},
      });

      //type WindowType = 'modal' | 'bar';
      const question_icon_path = `${window.location.origin}${QUESTION_ICON}`;
      const biz_blue = '#026AA7';

      await t.modal({
         url: './table',
         title: `${AMAZING_FIELDS_APP_NAME} Table View`,
         fullscreen: true,
         accentColor: biz_blue,
         callback: () => {
            //onSettingsClosed();
            appLog.log('Table View Closed');
         },
         actions: [
            {
               icon: question_icon_path,
               alt: 'Open Documentation',
               position: 'left',
               url: AMF_DOCS_SITE_URL,
               callback: () => {
                  breadcrumb('Open docs site');
               },
            },
         ],
      });

      return;
   }

   /**
    * Make sure the services are all initialized and ready to go for the current
    * board and frame as set.
    *
    * Note: this may throw AppErrors for errors that occur during initialization.
    */
   protected async ensureServicesReadyForBoard(t: Trello.PowerUp.IFrame): Promise<void> {
      await this.serviceReadyMutex.runExclusive(async () => {
         breadcrumb('ensureServicesReadyForBoard:startingSync', undefined, {log: false});
         // Initialize the service:
         // - not: this does more work that is needed.  We could optimize this to only
         //      (re)-init when the board id has changed in the context.
         if (!this.trelloSrv.initialized) {
            try {
               await this.trelloSrv.init({tCapability: t, loginFb: true});
            } catch (e: any) {
               breadcrumb('ensureServicesReadyForBoard:trelloSrv:init failed: ', {
                  data: {msg: e.message, name: e.name},
               });
               /*
               // If not an expected error, then log to sentry so we know it was here
               if (!isDisabledError(e)) {
                  // Capture the error before it goes higher
                  this.sentrySrv.handleError(e, 'ensureSrvReady:TrelloInitFailed');
               }
               */
               // Rethrow the error so we can further processit
               throw e;
            }
         }

         // hack: need to ensure that when board changes, we use the updated context.
         // - we should really not reset the frame behind the back of the service.
         this.trelloSrv.resetFrame(t);
         const has_edit_rights = this.trelloSrv.permissions.board === 'write';
         const is_authorized = this.trelloSrv.authorized;

         // Check to see if user has changed the board they are looking at
         const cur_board_id = this.trelloSrv.context.board;
         if (cur_board_id !== this.lastBoardId) {
            breadcrumb(
               `==> HookSrv:srvReady: BOARD CHANGED: old: ${this.lastBoardId}  new: ${cur_board_id}`,
            );
            this.lastBoardId = cur_board_id;

            // Do initial configuration load.  May be reloaded later if board id changes
            // or if we get notice that config may have changed.
            await this.boardCfgSrv.loadConfig();

            // If we have edit rights, check if board configuraton needs updated
            if (has_edit_rights && is_authorized) {
               // Check if config matches the board
               const config_board_id = this.boardCfgSrv.query.getConfig().boardId;
               breadcrumb(`HookSrv:srvReady: check cfg: board config id: ${config_board_id}`);
               if (config_board_id !== cur_board_id) {
                  breadcrumb('HookSrv:srvReady: Config needs updated for board id.');
                  await this.boardCfgSrv.updateConfigToBoard(cur_board_id);
                  const new_board_id = this.boardCfgSrv.query.getConfig().boardId;
                  assert(new_board_id === cur_board_id);
               }
            }

            // signal for active metrics change
            this.boardIdSubj.next(this.lastBoardId);
         }
         breadcrumb('ensureServicesReadyForBoard:completed', undefined, {log: false});
      });
   }

   /**
    * Register tracking of active use.
    *
    * note: this will only trigger from the boardIdSubj which *may* be called
    * from any frame but is normally the board buttons frame.
    */
   protected registerActiveUseMetric(): void {
      // Once we have a logged in user, then register to send updates about the user usage
      this.fbAuthSrv.hasLoggedInUser$.subscribe((user) => {
         const user_id = user.uid;

         // Emit event when we have valid user and a board id that changes
         this.boardIdSubj
            .pipe(
               filter((b): b is string => b != null),
               distinctUntilChanged(),
            )
            .subscribe((bid) => {
               breadcrumb('HookSrv:activeUseMetri: board id changed --> send metrics');

               // Note: internally this will send a BOARD_OPEN prod event
               this.sendBoardAction({userId: user_id, boardId: bid, boardAction: 'open'}).catch(
                  (e) => this.sentrySrv.handleError(e, 'sendActiveMetric'),
               );
            });
      });
   }

   /**
    * Register and start the polling callback.
    */
   protected registerPolling(): void {
      if (this.pollingSub == null) {
         this.pollingSub = interval(POLLING_INTERVAL_MS).subscribe(() => {
            void enterZone('hookPoll', this.pollingCallback, this);
         });
      }
   }

   /**
    * Register to watch the monitors
    */
   protected registerMonitors(): void {
      appLog.info('HookSrv:registerMonitors');

      this.dataMonitor.registerCardChangeCallback(
         async (f: Trello.PowerUp.IFrame, c: ICardChange): Promise<void> => {
            await this.monitorChangesCallback(f, c);
         },
      );
   }

   protected async monitorChangesCallback(f: Trello.PowerUp.IFrame, c: ICardChange): Promise<void> {
      const has_edit_rights = this.trelloSrv.permissions.board === 'write';
      const is_authorized = this.trelloSrv.authorized;

      if (has_edit_rights && is_authorized && (c.type === 'create' || c.type === 'copy')) {
         await this.handleCreatedOrCopiedCard(f, c);
      }

      // If we have a change to the card that may have changed an input
      // to a calculation (vis or value), then we need to re-evaluate
      // see: buildExtraContent or IExtraContent
      if (has_edit_rights && is_authorized && c.type === 'change') {
         const upd_fields = c.diff?.updated?.card ?? undefined;
         if (
            upd_fields?.address != null ||
            upd_fields?.desc != null ||
            upd_fields?.name != null ||
            upd_fields?.start != null ||
            upd_fields?.due != null ||
            upd_fields?.dueComplete != null ||
            upd_fields?.idList != null
         ) {
            breadcrumb('--> Card: changeOfInterest: ');
            await this.handleCardChangesOfInterest(f, c);
         }
      }
   }

   /**
    * Handle a newly created or copied card.
    *
    * Do this to update values and calculate formulas.
    * This is hit for imports, templates, etc.
    */
   protected async handleCreatedOrCopiedCard(
      f: Trello.PowerUp.IFrame,
      change: ICardChange,
   ): Promise<void> {
      try {
         if (change.after.card != null) {
            // If we created or copied a card, need to evaluate formulas and get card data update/set
            // do this through an empty patch
            const card_id = change.after.card.id;
            breadcrumb(`handleCreateOrCopiedCard: Patching created/copied card: ${card_id}`);
            await this.cardDataSrv.patchCardField(f, {}, card_id, {
               // Don't overwrite the CF's when AMF fields are null
               // because we want to pick up these values and apply them
               overwriteCfFromNull: false,
            });
         }
      } catch (e: any) {
         this.sentrySrv.handleError(e, 'handleCreatedOrCopiedCard');
      }
   }

   /**
    * Called when we detect that a card had a change from user interaction
    * that is of interest for things like recomputing formulas, etc.
    *
    * Note: this only happens for fields that are of interest to us
    *       outside the normal AMF values.
    */
   protected async handleCardChangesOfInterest(
      f: Trello.PowerUp.IFrame,
      change: ICardChange,
   ): Promise<void> {
      try {
         // If we have a valid card, then patch it so we recompute everything.
         if (change.after.card != null) {
            const card_id = change.after.card.id;
            breadcrumb(`Patching card with changes of interest: ${card_id}`);
            await this.cardDataSrv.patchCardField(f, {}, card_id, {
               // Don't overwrite the CF's when AMF fields are null
               // because we may still need to pickt them up
               overwriteCfFromNull: false,
            });
         }
      } catch (e: any) {
         this.sentrySrv.handleError(e, 'handleCardChangesOfInterest');
      }
   }

   /**
    * Send updated active metrics about board activity.  (active users / boards data)
    *
    * Set Product event related to board open and user details.
    */
   protected async sendBoardAction(o: {
      userId: string;
      boardId: string;
      boardAction: 'open' | 'disable';
   }): Promise<void> {
      try {
         breadcrumb('HookSrv: sending active metric: ', {data: o});
         const db = this.firestore;

         const current_date_str = dayjs().format('YYYY-MM-DD');

         // ---- UPDATE MEMBER DETAILS in DB ------ //
         const user_update: Partial<IUserDocClient> = {
            lastAccess: serverTimestamp(),
            accessed: arrayUnion(current_date_str),
         };

         // update member details to track any changes
         if (this.trelloSrv.member != null) {
            const member = this.trelloSrv.member;
            user_update.member = {
               id: member.id,
               fullName: member.fullName ?? null,
               username: member.username ?? null,
               initials: member.initials ?? null,
               avatar: member.avatar ?? null,
               paidStatus: member.paidStatus ?? null,
            };
         }

         // If we have context, then update subscription details at the same time
         // IMPORTANT: This is key to subscription processing
         if (this.trelloSrv.frame != null) {
            const context = this.trelloSrv.context;
            user_update.enterprise = context.enterprise ?? null;
            // attempt to update workspaces, if request fails just skip for now and catch later
            try {
               const workspaces_resp = await this.trelloSrv.api.get<Trello.PowerUp.Organization[]>(
                  `/members/me/organizations`,
                  {
                     params: {
                        fields: 'id,name',
                     },
                  },
               );
               user_update.workspaces = workspaces_resp.data.map((org) => org.id);
            } catch (e: any) {
               if (
                  axios.isAxiosError(e) &&
                  (e.response?.status === HttpStatus.BAD_REQUEST ||
                     e.response?.status === HttpStatus.UNAUTHORIZED ||
                     e.response?.status === HttpStatus.TOO_MANY_REQUESTS)
               ) {
                  // skip because bad token.
               } else if (axios.isAxiosError(e)) {
                  breadcrumb('WorkspaceLookupFailure: ', {
                     data: {
                        status: e.response?.status,
                        resp_data: e.response?.data,
                     },
                  });
                  this.sentrySrv.handleError(e, 'WorkspaceLookupFailure:');
               }
            }
         }

         // If we are on enabled board, then add to enabled, else we are disabling.
         if (o.boardAction === 'open') {
            user_update.enabledBoards = arrayUnion(o.boardId);
            user_update.disabledBoard = arrayRemove(o.boardId);
         } else {
            user_update.enabledBoards = arrayRemove(o.boardId);
            user_update.disabledBoard = arrayUnion(o.boardId);
         }

         const user_doc = doc(collection(db, USER_DB), o.userId);
         await setDoc(user_doc, user_update, {merge: true});
         this.fnSrv.logDbWrite('client:sendActiveMetric:user');

         // -- Update Board Metrics -- //
         const board_metrics: Partial<IBoardMetricDocClient> = {
            lastAccess: serverTimestamp(),
            accessed: arrayUnion(current_date_str),
            users: arrayUnion(o.userId),
            enabled: o.boardAction === 'open' ? true : o.boardAction === 'disable' ? false : true,
         };

         const board_metrics_doc = doc(collection(db, BOARD_METRICS_DB), o.boardId);
         await setDoc(board_metrics_doc, board_metrics, {merge: true});
         this.fnSrv.logDbWrite('client:sendActiveMetric:board');

         // -- Send Board Open Prod Event -- //
         // update user data at the same time
         const user_snap = await getDoc(user_doc);
         const user_data = user_snap.data() as IUserDocClient | undefined;

         // Update user details each time they open a board
         // so we pick up changes
         const user_props: IUserProps = {};
         if (this.trelloSrv.member != null) {
            // from member
            user_props.avatar = this.trelloSrv.member.avatar ?? undefined;
            user_props.username = this.trelloSrv.member.username ?? undefined;
            user_props.name = this.trelloSrv.member.fullName ?? undefined;
            user_props.trello_paid_status = this.trelloSrv.member.paidStatus ?? undefined;
            user_props.organization = this.trelloSrv.organization?.name ?? undefined;

            if (user_data != null) {
               const created_date = rawTimeToDate(user_data.created);
               const last_edit_date = rawTimeToDate(user_data.lastEdit);
               const enabled_board_count = (user_data.enabledBoards as string[]).length;
               const disabled_board_count = (user_data.disabledBoard as string[]).length;
               const accessed_days = (user_data.accessed as string[]).length;
               const edited_days = (user_data.edited as string[]).length;

               // from user data
               user_props.email = user_data.email ?? undefined;
               user_props.editor = user_data.editor ?? undefined;
               user_props.created = created_date == null ? undefined : created_date.toISOString();
               user_props.last_edit =
                  last_edit_date == null ? undefined : last_edit_date.toISOString();
               user_props.enabled_board_count = enabled_board_count;
               user_props.disabled_board_count = disabled_board_count;
               user_props.accessed_days = accessed_days;
               user_props.edited_days = edited_days;
               user_props.subscription_type = user_data.subscriptionType ?? null;
            }
         }

         this.trelloSrv.logProdEvent({
            type: ProdEvents.BOARD_OPEN,
            props: {},
            userProps: user_props,
         });
      } catch (e: unknown) {
         if (isFirebaseError(e) && e.code === FbErrorCodes.PERMISSION_DENIED) {
            breadcrumb('hookSrv:sendActiveMetric: permission denied. skipping..');
         } else {
            breadcrumb('hookSrv:sendActiveMetric: had exception');
            this.sentrySrv.handleError(e);
         }
      }
   }

   /**
    * Callback we call to check things we need to check.
    */
   protected async pollingCallback(): Promise<void> {
      // Only allow one in at a time.
      if (this._pollingExecuting) {
         return;
      }

      // Only allow one call in here at a time
      // - prevents a slow update from backing up updates
      try {
         this._pollingExecuting = true;
         this._pollingIteration += 1;
         breadcrumb(`pollingCallback: iteration: ${this._pollingIteration}`, {}, {log: false});
         //const has_edit_rights = this.trelloSrv.permissions.board === 'write';
         //const is_authorized = this.trelloSrv.authorized;
         const member_type = await this.trelloSrv.getBoardMemberType();

         // We get no member type when we don't have a valid board
         // could be on workspace view or just have a context that is now invalid.
         if (member_type == null) {
            return;
         }

         /**
         // Only look to update custom fields if we have the right to do that on the board
         if (has_edit_rights && is_authorized) {
            // Don't allow polling to run while service refresh / init is going
            // why??
            await this.serviceReadyMutex.runExclusive(async () => {
               // Check if we need to do things
               await enterZone('checkCustomFields', this.checkCustomFields, this);
            });
         }
         */

         if (member_type === 'admin') {
            await enterZone('checkBoardBackup', this.checkBoardBackup, this);
         }
      } catch (e: any) {
         this.sentrySrv.handleError(e, 'Polling CB');
      } finally {
         this._pollingExecuting = false;
      }
   }

   /**
    * Check if the board needs to be backed up.
    */
   protected async checkBoardBackup(): Promise<void> {
      try {
         //appLog.info('checkBoardBackup: checking');
         if (this.skipBoardBackup) {
            appLog.info('checkBoardBackup: skipping because we should not try anymore');
            return;
         }
         if (this.boardCfgSrv.isConfigSaving()) {
            appLog.info('checkBoardBackup: skipping because config is saving');
            return;
         }
         if (!this.trelloSrv.authorized) {
            appLog.info('checkBoardBackup: skipping because user not authorized');
            return;
         }

         const cfg = this.boardCfgSrv.query.getValue().config;

         // exit if we are not doing backups
         if (cfg.backup == null) {
            return;
         }

         const member_type = await this.trelloSrv.getBoardMemberType();
         const can_create_board = this.trelloSrv.permissions.organization === 'write';
         const is_authorized = this.trelloSrv.authorized;

         // If I am admin user, then I check
         if (member_type === 'admin' && can_create_board && is_authorized) {
            // Has it been long enough
            const last_backup_str = cfg.backup.last;
            const last_backup_time = last_backup_str == null ? null : dayjs(last_backup_str);
            const freq_unit: dayjs.ManipulateType =
               cfg.backup.freq === BackupFrequency.DAILY ? 'day' : 'week';
            const close_board = cfg.backup.close ?? false;

            const next_backup_time =
               last_backup_time == null
                  ? dayjs().subtract(1, 'minute')
                  : last_backup_time.add(1, freq_unit);

            /*if (1 === 1) {
               await runLater(500);
               throw new Error('testing zone error');
            }
            */

            if (dayjs() > next_backup_time) {
               appLog.info('checkBoardBackup: time for a new backup... config: ', cfg.backup);
               const now_time = dayjs();

               // save that we did this
               // - note: this will prevent the backup from immediately backing up if we open it.
               this.boardCfgSrv.store.update((state) => {
                  assert(state.config.backup != null);
                  state.config.backup.last = now_time.toISOString();
               });

               // Get all current board details
               const board_details = await this.trelloSrv.frame.board('all');

               // make the copy
               const api = this.trelloSrv.apiRetry;
               const new_name = `${board_details.name}-backup-${now_time.format(
                  'YYYY-MM-DD_HH_mm',
               )}`;
               const resp = await api.post<IBoardData>('/boards', undefined, {
                  params: {
                     name: new_name,
                     idBoardSource: board_details.id,
                     idOrganization: board_details.idOrganization,
                     keepFromSource: 'cards',
                  },
               });
               const new_board_id = resp.data.id;
               const new_board_url = resp.data.shortUrl;

               appLog.info('Backup response: ', resp.data);

               if (close_board) {
                  const close_resp = await api.put<IBoardData>(
                     `/boards/${new_board_id}`,
                     undefined,
                     {
                        params: {
                           closed: 'true',
                        },
                     },
                  );
                  appLog.info('Backup close response: ', close_resp);
               }

               // Add all admin members
               // - initially only created with me as member
               const cur_user_id = this.trelloSrv.member?.id ?? null;
               const admin_member_ids = board_details.memberships
                  .filter((m) => m.memberType === 'admin')
                  .map((m) => m.idMember)
                  .filter((i) => i !== cur_user_id);

               for (const mid of admin_member_ids) {
                  await api.put(`/boards/${new_board_id}/members/${mid}`, undefined, {
                     params: {
                        type: 'admin',
                     },
                  });
               }

               this.fnSrv.logEvent({eventName: CREATE_BACKUP_EVENT_NAME});
               appLog.info('checkBoardBackup: board backup completed.');

               // Open the board in a new tab if we are not closing it
               if (!close_board) {
                  // Open a URL in a new tab and attempt to stay here.
                  window.open(new_board_url, '_blank', 'noopener');
                  window.focus();
               }

               /*
               const new_board_details = await api.get<IBoardData>(`/boards/${new_board_id}`, {
                  params: {
                     fields: 'all',
                  },
               });
               */

               // celebrate 🎉
            }
         }
      } catch (e: any) {
         if (axios.isAxiosError(e) && e.response?.status === HttpStatus.BAD_REQUEST) {
            // This likely means the backup can't be made due to lack of org space for another board
            // or permissions, so skip trying anymore
            this.skipBoardBackup = true;
            return;
         }
         this.sentrySrv.handleError(e, 'CheckBoardBackup Failure');
      }
   }
}
