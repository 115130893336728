import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

// Only define the parts needed
interface IFieldConfigV3 {
   version: number;
   fields: any[];
}

interface IFieldConfigV4 {
   version: number;
   fields: any[];

   // new
   sectionName: string;
   adminOnlyFilters: boolean;
}

/**
 * Add the configuration for hiding field by default.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addBoardSectionAndAdminFilters_3_4: IFieldDataMigration = {
   targetVersion: 4,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 3);

      const incoming = c as IFieldConfigV3;
      assert(incoming != null);

      const outgoing = c as IFieldConfigV4;

      outgoing.sectionName = 'Amazing Fields';
      outgoing.adminOnlyFilters = false;

      // Set final version
      c.version = 4;
      return c;
   },
};
