/**
 * Style progress bars:
 *
 * References:
 * * https://mike42.me/blog/2018-06-make-better-cli-progress-bars-with-unicode-block-characters
 * * https://changaco.oy.lc/unicode-progress-bars/
 * * https://en.wikipedia.org/wiki/Box-drawing_character
 * * https://unicode.org/charts/ - shapes
 */
import {IProgressBarSettings, ProgressBarStyle} from '@shared/domain_types';

/**
 * Return the progress bar character rep to use.
 */
export function formatProgressBar(val: number, cfg: IProgressBarSettings): string {
   try {
      if (cfg.barStyle !== ProgressBarStyle.CUSTOM) {
         const bar_cfg = BAR_FORMATS[cfg.barStyle] ?? BAR_FORMATS[ProgressBarStyle.ONE];

         const blocks = bar_cfg.partials;
         const empty_char = bar_cfg.emptyChar;
         const end_edge = bar_cfg.endEdge;
         const start_edge = bar_cfg.startEdge;
         const bar_char = bar_cfg.fullBlock;

         // Normalize value: [0.0, 1.0]
         const normalized_value =
            (Math.min(Math.max(val, cfg.min), cfg.max) - cfg.min) / Number(cfg.max - cfg.min);
         const v = normalized_value * cfg.width; // bar length
         const full_blocks = Math.floor(v); // integer part
         const partial_block_pct = v - full_blocks; // fractional part

         // Round to nearest partial block
         const blk_idx = Math.round(partial_block_pct * blocks.length);
         const bar =
            Array(full_blocks).fill(bar_char).join('') +
            // if we rounded up to full block then use it
            (partial_block_pct > 0.0
               ? blk_idx === blocks.length
                  ? bar_char
                  : blocks[blk_idx]
               : '');
         const remaining = Array(cfg.width - bar.length)
            .fill(empty_char)
            .join('');
         const bar_out = `${start_edge != null ? start_edge : ''}${bar}${remaining}${
            end_edge != null ? end_edge : ''
         }`;
         return bar_out;
      } else {
         if (cfg.customBars == null) {
            return 'missing_custom_bar';
         }
         const normalized_value =
            (Math.min(Math.max(val, cfg.min), cfg.max) - cfg.min) / Number(cfg.max - cfg.min);
         // range: ex: 0.0 * 10 => 0, 0.95 * 10 => 9.5
         const index_offset = normalized_value * (cfg.customBars.length - 1);
         let index = Math.round(index_offset);

         // Clamp to valid index range
         if (index < 0) {
            index = 0;
         } else if (index >= cfg.customBars.length) {
            index = cfg.customBars.length - 1;
         }
         const bar_out = cfg.customBars[index];
         return bar_out;
      }
   } catch (e: unknown) {
      return '';
   }
}

export interface IBarConfig {
   partials: string[];
   fullBlock: string;
   startEdge: string | null;
   endEdge: string | null;
   emptyChar: string;
}

export const BAR_FORMATS: {[k: string]: IBarConfig} = {
   [ProgressBarStyle.ONE]: {
      partials: [' ', '▏', '▎', '▍', '▌', '▋', '▊', '▉'],
      fullBlock: '█',
      // Unicode M width empty
      emptyChar: ' ',
      //startEdge: '▕',
      startEdge: null,
      endEdge: '▏',
   },
   // ██████▁▁▁▁▁
   [ProgressBarStyle.TWO]: {
      partials: ['▁', '█'],
      fullBlock: '█',
      emptyChar: '▁',
      startEdge: null,
      endEdge: null,
   },
   // ⣿⣿⣿⣿⣿⣿⣦⣀⣀⣀⣀⣀ - - braille
   [ProgressBarStyle.THREE]: {
      partials: ['⠀', '⡀', '⡄', '⡆', '⡇', '⣇', '⣧', '⣷'],
      fullBlock: '⣿',
      emptyChar: '⠀',
      startEdge: null,
      endEdge: null,
   },
   // ⣿⣿⣿⣿⣿⣿⣇⣀⣀⣀⣀⣀⣀  - braille
   [ProgressBarStyle.FOUR]: {
      partials: ['⠀', '⡇'],
      fullBlock: '⣿',
      emptyChar: '⠀',
      startEdge: null,
      endEdge: null,
   },
   // ⬤⬤⬤⬤⬤⬤◐○○○○○
   // ●◐○
   [ProgressBarStyle.FIVE]: {
      partials: ['○', '◐'],
      fullBlock: '●',
      emptyChar: '○',
      startEdge: null,
      endEdge: null,
   },
   // ■■■■■◧□□□□□
   [ProgressBarStyle.SIX]: {
      partials: ['□', '◧'],
      fullBlock: '■',
      emptyChar: '□',
      startEdge: null,
      endEdge: null,
   },
   // ██████░░░░░░
   [ProgressBarStyle.SEVEN]: {
      partials: ['░', '█'],
      fullBlock: '█',
      emptyChar: '░',
      startEdge: null,
      endEdge: null,
   },
   // ⬛⬛⬛⬛⬛⬛⬜⬜⬜⬜⬜
   [ProgressBarStyle.EIGHT]: {
      partials: ['⬜', '⬛'],
      fullBlock: '⬛',
      emptyChar: '⬜',
      startEdge: null,
      endEdge: null,
   },
   // ▰▰▰▰▰▰▱▱▱▱▱▱
   [ProgressBarStyle.NINE]: {
      partials: ['▱', '▰'],
      fullBlock: '▰',
      emptyChar: '▱',
      startEdge: null,
      endEdge: null,
   },
   // ◼◼◼◼◼◼▭▭▭▭▭▭
   [ProgressBarStyle.TEN]: {
      partials: ['▭', '◼'],
      fullBlock: '◼',
      emptyChar: '▭',
      startEdge: null,
      endEdge: null,
   },
   // ▮▮▮▮▮▮▯▯▯▯▯▯
   [ProgressBarStyle.ELEVEN]: {
      partials: ['▯', '▮'],
      fullBlock: '▮',
      emptyChar: '▯',
      startEdge: null,
      endEdge: null,
   },
   // ⬤⬤⬤⬤⬤⬤◯◯◯◯◯◯
   [ProgressBarStyle.TWELVE]: {
      partials: ['◯', '⬤'],
      fullBlock: '⬤',
      emptyChar: '◯',
      startEdge: null,
      endEdge: null,
   },
   // ⚫⚫⚫⚫⚫⚫⚪⚪⚪⚪⚪⚪
   [ProgressBarStyle.THIRTEEN]: {
      partials: ['⚪', '⚫'],
      fullBlock: '⚫',
      emptyChar: '⚪',
      startEdge: null,
      endEdge: null,
   },
};
