//import {environment} from 'src/environments/environment';
import {Component, Injector} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {AMAZING_FIELDS_APP_NAME} from 'src/trello/powerup_settings';

import {appConfig} from '@app/app_config';
import {appLog} from '@app/services/logging';

import {canAccessLocalStorage, canFirebaseInitWithBrowserSecurity} from '@util/utils';
import {CookieFailCallbacksSrv} from './cookies_warning.comp';
import {InitHooksSrv} from './hooks.srv';

/**
 * Component for initializing Trello hooks.
 */
@Component({
   selector: 'tr-init-comp',
   template: `<div>Init</div>`,
})
export class InitComponent {
   constructor(protected injector: Injector) {
      appLog.log('InitComponent loaded');

      if (window.TrelloPowerUp == null || window.TrelloPowerUp.initialize == null) {
         appLog.warn('TrelloPowerUp interface not found. Please attempt to reload page.');
         Sentry.captureMessage('InitComp: window.TrelloPowerUp not found');
      } else {
         // If we can't access local storage, then third-party cookies are probably
         // disabled and we are not going to be able to show the powerup correctly
         // Instead we show a warning with links to support.
         if (canAccessLocalStorage() && canFirebaseInitWithBrowserSecurity()) {
            // Lookup the hooks srv now that we know it can initialize
            const hooks_srv = this.injector.get(InitHooksSrv);

            window.TrelloPowerUp.initialize(
               {
                  'board-buttons': hooks_srv.boardButtonsHook.bind(hooks_srv),
                  'card-badges': hooks_srv.cardBadgesHook.bind(hooks_srv),
                  'card-back-section': hooks_srv.cardBackSectionHook.bind(hooks_srv),
                  'card-detail-badges': hooks_srv.cardDetailBadgesHook.bind(hooks_srv),
                  'show-settings': hooks_srv.showSettingsHook.bind(hooks_srv),

                  //'authorization-status': hooks_srv.authorizationStatusHook.bind(hooks_srv),
                  //'show-authorization': hooks_srv.showAuthorizationHook.bind(hooks_srv),

                  'on-enable': hooks_srv.onEnableHook.bind(hooks_srv),
                  'on-disable': hooks_srv.onDisableHook.bind(hooks_srv),
                  'remove-data': hooks_srv.removeDataHook.bind(hooks_srv),
               },
               {
                  appKey: appConfig.trelloApiKey,
                  appName: appConfig.amzPowerupName,
                  helpfulStacks: true, //!environment.production,
               },
            );
         } else {
            //Sentry.captureMessage('InitComp: LocalStorage and FbInit failed.');
            // Note: Powerup initialization and google analytics will fail now
            const cookie_fail_srv = this.injector.get(CookieFailCallbacksSrv);
            window.TrelloPowerUp.initialize(
               {
                  'show-settings': cookie_fail_srv.showSettingsHook.bind(cookie_fail_srv),
                  'on-enable': cookie_fail_srv.onEnableHook.bind(cookie_fail_srv),
                  'on-disable': cookie_fail_srv.onDisableHook.bind(cookie_fail_srv),
                  'board-buttons': cookie_fail_srv.boardButtonsHook.bind(cookie_fail_srv),
                  'card-back-section': cookie_fail_srv.cardBackSectionHook.bind(
                     cookie_fail_srv,
                  ) as any,

                  // Null hooks
                  'card-badges': cookie_fail_srv.doNothing.bind(cookie_fail_srv) as any,

                  'card-detail-badges': cookie_fail_srv.doNothing.bind(cookie_fail_srv) as any,
                  'remove-data': cookie_fail_srv.doNothing.bind(cookie_fail_srv),
               },
               {
                  // NOTE: We don't pass this because if we did it will fail the powerup
                  //       initialization when trying to init a REST API class.
                  //appKey: TRELLO_API_KEY,
                  appName: AMAZING_FIELDS_APP_NAME,
                  helpfulStacks: true, //!environment.production,
               },
            );
         }
      }
   }
}
