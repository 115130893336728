import dayjs from 'dayjs';

/**
 * Assert that a cond is true
 */
export function assert(cond: boolean, msg: string = ''): asserts cond {
   if (!cond) {
      throw Error(`ASSERT FAILURE: ${msg}`);
   }
}

/** Test if is string. */
export function isString(value: any): value is string {
   return typeof value === 'string';
}

/**
 * Given an email address, add a subaddress section.
 */
export function addEmailSubaddress(email: string, subaddress: string): string {
   const at_index = email.indexOf('@');
   if (at_index === -1) {
      throw new Error('Invalid email address.');
   }

   const local_part = email.slice(0, at_index);
   const domain_part = email.slice(at_index);

   return `${local_part}+${subaddress}${domain_part}`;
}

/**
 * Given an email address, remove a subaddress if any is found.
 */
export function removeEmailSubaddress(email: string): string {
   const at_index = email.indexOf('@');
   if (at_index === -1) {
      //throw new Error('Invalid email address.');
      return email;
   }

   const local_part = email.slice(0, at_index);
   const plus_index = local_part.lastIndexOf('+');
   if (plus_index === -1) {
      return email;
   }

   const domain_part = email.slice(at_index);
   return `${local_part.slice(0, plus_index)}${domain_part}`;
}

// -- custom diff implementations that run 10X faster than normal dayjs.diff -- //
const MS_PER_DAY = 24 * 60 * 60 * 1000;
const MS_PER_MINUTE = 60 * 1000;

export function diffMinutes(start: dayjs.Dayjs, end: dayjs.Dayjs): number {
   const diff_ms = end.valueOf() - start.valueOf();
   return absFloor(diff_ms / MS_PER_MINUTE);
}

export function diffDays(start: dayjs.Dayjs, end: dayjs.Dayjs): number {
   const diff_ms = end.valueOf() - start.valueOf();
   return absFloor(diff_ms / MS_PER_DAY);
}

export function absFloor(n: number): number {
   return n < 0 ? Math.ceil(n) || 0 : Math.floor(n);
}

/**
 * Allocate an array across the given range.
 */
// eslint-disable-next-line @typescript-eslint/array-type
export function range(size: number, startAt: number = 0): ReadonlyArray<number> {
   return [...Array(size).keys()].map((i) => i + startAt);
}

export function arrayMin(arr: number[]): number {
   return arr.reduce((p, v) => {
      return p < v ? p : v;
   });
}

export function arrayMax(arr: number[]): number {
   return arr.reduce((p, v) => {
      return p > v ? p : v;
   });
}

export function arraySum(arr: number[]): number {
   return arr.reduce((p, v) => p + v, 0);
}

export function arrayZip<T>(...arrays: T[][]): T[][] {
   const min_len = Math.min(...arrays.map((arr) => arr.length));
   const [first_arr, ...rest_arrs] = arrays;
   return first_arr.slice(0, min_len).map((val, i) => [val, ...rest_arrs.map((arr) => arr[i])]);
}

/** Return union of arrays. */
export function arrayIntersection<T>(a: readonly T[], b: readonly T[]): T[] {
   if (a.length > b.length) {
      return a.filter((x) => b.includes(x));
   } else {
      return b.filter((x) => a.includes(x));
   }
}


/**
 * Compute a new array as a moving average of the given array and window.
 */
export function movingAverage(arr: number[], windowSize: number): number[] {
   const res: number[] = [];

   for (let i = 0; i < arr.length; i += 1) {
      // try to arrange window from i-windowSize to i but move to
      // the right if we are at start of array.
      // - look back windowSize history including us, but clip to 0
      const start = Math.max(i - (windowSize - 1), 0);
      // - look forward to get window size from start, but limit to end of array.
      const end = Math.min(start + (windowSize - 1), arr.length - 1);

      let sum = 0;
      for (let x = start; x <= end; x++) {
         sum += arr[x];
      }

      const smooth_avg = sum / (end - start + 1);
      res.push(smooth_avg);
   }

   return res;
}
