// eslint-disable-next-line @typescript-eslint/naming-convention
export const firebase_staging = {
   apiKey: 'AIzaSyBIbzi6F7XrUAAS2qaTq5_CZzPlubNnRk4',
   authDomain: 'amazing-powerups-staging.firebaseapp.com',
   databaseURL: 'https://amazing-powerups-staging-default-rtdb.firebaseio.com',
   projectId: 'amazing-powerups-staging',
   storageBucket: 'amazing-powerups-staging.appspot.com',
   messagingSenderId: '850958044779',
   appId: '1:850958044779:web:89d56af17070a317a66b2e',
   measurementId: 'G-FF4YHXG7Q2',
};

// ADD Later
// eslint-disable-next-line @typescript-eslint/naming-convention
export const firebase_production = {
   apiKey: 'AIzaSyAHmOLKk8M-ycRX8XWN9NprmkduHs5rxSU',
   authDomain: 'amazing-powerups.firebaseapp.com',
   databaseURL: 'https://amazing-powerups-default-rtdb.firebaseio.com',
   projectId: 'amazing-powerups',
   storageBucket: 'amazing-powerups.appspot.com',
   messagingSenderId: '576560537040',
   appId: '1:576560537040:web:e4387b73c909b7b05badd5',
   measurementId: 'G-40QJZYMLWN',
};

export const IN_PLAYWRIGHT: boolean = navigator.userAgent.includes('pwt');

/**
 * Bring in build environment variables from extra-webpack.config
 */
declare const BUILD_APP_VERSION: string;
declare const BUILD_APP_HASH: string;

export const APP_VERSION_ENV = {
   version: BUILD_APP_VERSION,
   hash: BUILD_APP_HASH,
};
