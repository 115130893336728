/**
 * Define the formatting for all Date types
 */

import {DateFormat, DateRuleOp} from '@shared/domain_types';
import {environment} from 'src/environments/environment';

/**
 * Return formatted string version of the given date with the given format
 */
export function formatDate(d: Date, format: DateFormat): string {
   const format_opts = DATE_FORMATS[format]?.format ?? {};
   if (environment.testSuite) {
      format_opts.timeZone = 'America/Chicago';
   }
   /*
   console.log('locale info: ', [
      window.navigator.language,
      window.navigator.languages,
      new Intl.DateTimeFormat().resolvedOptions().locale,
   ]);
   */
   return Intl.DateTimeFormat([...navigator.languages], format_opts).format(d);
}

export function getSortedDateFormats(): DateFormat[] {
   const entries = Object.entries(DATE_FORMATS).map((v) => ({key: v[0], order: v[1].order}));
   entries.sort((a, b) => a.order - b.order);
   const formats = entries.map((v) => v.key);
   return formats as DateFormat[];
}

export const DATE_FORMATS: {[k: string]: {order: number; format: Intl.DateTimeFormatOptions}} = {
   [DateFormat.DATE_SHORT]: {
      order: 1,
      format: {
         year: 'numeric',
         month: 'numeric',
         day: 'numeric',
      },
   },

   [DateFormat.DATE_XSHORT]: {
      order: 2,
      format: {
         year: '2-digit',
         month: 'numeric',
         day: 'numeric',
      },
   },

   [DateFormat.DATE_MEDIUM]: {
      order: 3,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
      },
   },

   [DateFormat.DATE_MEDIUM_WEEKDAY]: {
      order: 4,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         weekday: 'short',
      },
   },

   [DateFormat.DATE_FULL]: {
      order: 5,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
      },
   },

   [DateFormat.DATE_HUGE]: {
      order: 6,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         weekday: 'long',
      },
   },

   [DateFormat.DATE_MONTH_YEAR_SHORT]: {
      order: 7,
      format: {
         year: 'numeric',
         month: 'short',
      },
   },

   [DateFormat.DATE_YEAR_ONLY]: {
      order: 8,
      format: {
         year: 'numeric',
      },
   },

   [DateFormat.TIME_SIMPLE]: {
      order: 10,
      format: {
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.TIME_24_SIMPLE]: {
      order: 11,
      format: {
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
      },
   },

   [DateFormat.TIME_OFFSET]: {
      order: 12,
      format: {
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'short',
      },
   },
   [DateFormat.TIME_LONG_OFFSET]: {
      order: 13,
      format: {
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'long',
      },
   },

   [DateFormat.TIME_24_OFFSET]: {
      order: 14,
      format: {
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
         timeZoneName: 'short',
      },
   },

   [DateFormat.DATETIME_SHORT]: {
      order: 20,
      format: {
         year: 'numeric',
         month: 'numeric',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.DATETIME_XSHORT]: {
      order: 21,
      format: {
         year: '2-digit',
         month: 'numeric',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.DATETIME_MEDIUM]: {
      order: 22,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.DATETIME_MEDIUM_WEEKDAY]: {
      order: 23,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         weekday: 'short',
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.DATETIME_FULL]: {
      order: 24,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.DATETIME_HUGE]: {
      order: 25,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         weekday: 'long',
         hour: 'numeric',
         minute: 'numeric',
      },
   },

   [DateFormat.DATETIME_SHORT_OFFSET]: {
      order: 30,
      format: {
         year: 'numeric',
         month: 'numeric',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'short',
      },
   },

   [DateFormat.DATETIME_MEDIUM_OFFSET]: {
      order: 31,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'short',
      },
   },

   [DateFormat.DATETIME_MEDIUM_WEEKDAY_OFFSET]: {
      order: 32,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         weekday: 'short',
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'short',
      },
   },

   [DateFormat.DATETIME_FULL_OFFSET]: {
      order: 33,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'short',
      },
   },

   [DateFormat.DATETIME_HUGE_OFFSET]: {
      order: 34,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         weekday: 'long',
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName: 'short',
      },
   },

   [DateFormat.DATETIME_24_SHORT]: {
      order: 40,
      format: {
         year: 'numeric',
         month: 'numeric',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
      },
   },

   [DateFormat.DATETIME_24_MEDIUM]: {
      order: 41,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
      },
   },

   [DateFormat.DATETIME_24_MEDIUM_WEEKDAY]: {
      order: 42,
      format: {
         year: 'numeric',
         month: 'short',
         day: 'numeric',
         weekday: 'short',
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
      },
   },

   [DateFormat.DATETIME_24_FULL]: {
      order: 43,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
      },
   },

   [DateFormat.DATETIME_24_HUGE]: {
      order: 44,
      format: {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         weekday: 'long',
         hour: 'numeric',
         minute: 'numeric',
         hour12: false,
      },
   },
};

interface IDateRuleOpDesc {
   op: DateRuleOp;
   desc: string;
}

export const DATE_RULE_OP_DESCS: IDateRuleOpDesc[] = [
   {op: DateRuleOp.IS, desc: 'Is Same'},
   {op: DateRuleOp.BEFORE, desc: 'Before'},
   {op: DateRuleOp.AFTER, desc: 'After'},
];
