import {Injectable} from '@angular/core';

import {
   BadgeColorType,
   BoolStyle,
   colorEnumToTrelloBadgeColor,
   DateRuleOp,
   DateUnits,
   FieldDataTypes,
   FieldType,
   IBoolColorSettings,
   IBoolField,
   ICardRefField,
   ICardRefFieldData,
   IDataFieldTypes,
   IDateColorSettings,
   IDateField,
   IFieldInfo,
   IListField,
   INumberColorSettings,
   INumberField,
   ITextColorSettings,
   ListMultiMethod,
   NumberRuleOp,
   NumberShowType,
   TextRuleOp,
} from '@shared/domain_types';
import {Mutex} from 'async-mutex';
import dayjs from 'dayjs';
import {cloneDeep, isObject} from 'lodash-es';
import {environment} from 'src/environments/environment';
import {Trello} from 'src/trello';
import {assert, isBoolean, isEmptyString, isNumber, isString, isValidDate} from 'src/util';

import {BoardConfigSrv} from '@app/data/board_config.store';
import {
   computeChecklistProgress,
   getListTextFromValues,
   isCardRefField,
   isChecklistLinkedProgressField,
   isDataField,
   isNumberProgressField,
   isViewingAllowed,
} from '@app/data/data_helpers';
import {BOOL_STYLES, IBoolStyleOpts} from '@app/data/format/bool_presets';
import {appLog, breadcrumb} from '@app/services/logging';
import {TrelloSrv} from '@app/services/trello.srv';

import {isTrelloError} from '@trello/trello_errors';
import {CardDataSrv} from '../card_data.srv';
import {formatDate} from './date_format';
import {formatNumber} from './number_format';
import {formatProgressBar} from './progress_bar_format';

/**
 * Class to help build up card badges.
 */
@Injectable({
   providedIn: 'root',
})
export class CardBadgesFactory {
   /** Protect access to init process. */
   protected initMutex: Mutex = new Mutex();

   /** The hash of the last successful initialization load. */
   protected lastConfigHash: string | null = null;

   /**
    * Construct the factory.
    *
    * Note: The Trello srv must be initialized upon construction
    */
   constructor(
      protected trelloSrv: TrelloSrv,
      protected boardCfgSrv: BoardConfigSrv,
      protected cardSrv: CardDataSrv,
   ) {
      assert(trelloSrv.initialized, 'Must used an initialized service');
   }

   get initialized(): boolean {
      return this.lastConfigHash != null;
   }

   /**
    * Initialize the card badge factory so we are up and running.
    */
   async initialize(): Promise<void> {
      // This should be stable and all calls should get same value from the board.
      const config_hash = await this.boardCfgSrv.getConfigHash();

      // if haven't been initialized before OR if config doesn't match previous time
      await this.initMutex.runExclusive(async () => {
         const hash_mismatch = config_hash !== this.lastConfigHash;

         // If we haven't been initialized before, then use the config
         if (this.lastConfigHash == null) {
            this.lastConfigHash = config_hash;
         }
         // else, if we have a different config, force a reload
         // Q: why do we do this?
         else if (hash_mismatch) {
            appLog.info('CardBadgesFactory: Reloading board config due to hash mismatch.', {
               last_hash: this.lastConfigHash,
               new_hash: config_hash,
            });

            this.lastConfigHash = config_hash;
            await this.boardCfgSrv.loadConfig();
         }
      });
   }

   async buildCardBadges(cardFrame: Trello.PowerUp.IFrame): Promise<Trello.PowerUp.CardBadge[]> {
      assert(this.initialized, 'Card factory should be initialized already');

      const f_cfg = this.boardCfgSrv.query.getDataFields();
      const has_linked_checklist = f_cfg.some((f) => isChecklistLinkedProgressField(f));

      try {
         const board_id = cardFrame.getContext().board;
         if (this.trelloSrv.context.board !== board_id) {
            breadcrumb('buildCardBadges: exiting early due to call on old/non-matching board.');
            return [];
         }

         const f_data_p = this.cardSrv.getCardFields(cardFrame);
         const f_hidden_p = this.cardSrv.getHiddenFields(cardFrame);
         const member_type_p = this.trelloSrv.getBoardMemberType();

         const f_data = await f_data_p;
         const f_hidden = (await f_hidden_p) ?? {hidden: []};
         const member_type = await member_type_p;
         const member_id = this.trelloSrv.member?.id ?? null;

         // handle failure case caused by invalid board context or board changing
         // since we started
         if (member_type == null || board_id !== this.trelloSrv.context.board) {
            return [];
         }

         const checklist_data =
            has_linked_checklist && member_type !== 'observer'
               ? await this.cardSrv.getCardChecklists(cardFrame)
               : null;

         const badges: Trello.PowerUp.CardBadge[] = [];

         const getFieldValue = (f: IFieldInfo): FieldDataTypes | undefined => {
            if (isChecklistLinkedProgressField(f)) {
               return computeChecklistProgress(f, checklist_data);
            } else {
               return f_data?.[f.id] ?? undefined;
            }
         };

         for (const f of f_cfg) {
            if (!f_hidden.hidden.includes(f.id) && isViewingAllowed(f, member_type, member_id)) {
               if (isCardRefField(f)) {
                  const badge = await this.buildCardRefBadge(
                     f,
                     getFieldValue(f) as ICardRefFieldData | undefined,
                  );
                  if (badge != null) {
                     badges.push(badge);
                  }
               } else {
                  const f_badges = this.buildBadges(f, getFieldValue(f));
                  if (f_badges != null) {
                     badges.push(...f_badges);
                  }
               }
            }
         }
         return badges;
      } catch (e: any) {
         const is_trello_error = isTrelloError(e);
         breadcrumb(`CardBadgeFactory: exception, name: [${e.name}]`, {
            data: {
               name: e.name,
               message: e.message,
               is_trello_error,
            },
         });
         // If we have a Trello error, then continue with no badges
         // - happens when switching boards or when trello gets out of sync
         if (is_trello_error) {
            breadcrumb('Returning empty badge set due to TrelloError');
            return [];
         } else {
            breadcrumb('Rethrowing exception.');
            throw e;
         }
      }
   }

   /**
    * Return the badge(s) details to show or null if we should not show.
    */
   buildBadges(
      cfg: IDataFieldTypes,
      data: FieldDataTypes | undefined,
      opts?: {edit: boolean},
   ): Trello.PowerUp.CardBadge[] | null {
      let badge_color: Trello.PowerUp.CardBadgeColors | undefined;
      const is_edit_badge = opts?.edit ?? false;

      // Nothing to show, so just return
      if (!(cfg.show.frontValue || cfg.show.frontName) && !is_edit_badge) {
         return null;
      }

      // No card data has been set yet, so don't show anything.
      // OR card data is cleared for date, or list case.
      // if text field, then skip forward in case rule is hit.
      if (
         (data == null || data === '' || (Array.isArray(data) && data.length === 0)) &&
         cfg.type !== FieldType.TEXT
      ) {
         return null;
      }

      // Determine the value string rep and the icon to use.
      let val_content = '';
      let icon: string | undefined;

      if (cfg.type === FieldType.TEXT) {
         if (isObject(data)) {
            breadcrumb('Found text data of:', {data: {text_data: data}});
         }
         assert(!isObject(data), 'Should be string of null');
         val_content = data != null ? `${data}` : '';
         const res = this.getTextColor(cfg.color, val_content);
         badge_color = res.c;

         // Don't show for empty string unless this is due to a hit empty rule
         // OR we are doing edit mode and need something back in all cases
         if (!is_edit_badge && val_content === '' && !res.hitRule) {
            return null;
         }
      } else if (cfg.type === FieldType.BOOL) {
         const bool_val = isBoolean(data) ? data : false;
         icon = cfg.show.frontValue || is_edit_badge ? this.getBoolIcon(cfg, bool_val) : undefined;
         badge_color = this.getBoolColor(cfg.color, bool_val);
         // show if show_front and either:
         //  - have an icon
         //  - have coloring (show showing colored text)
         //  - are true and thus show label name instead of the icon
         val_content =
            cfg.show.frontName && (icon != null || badge_color != null || bool_val) ? cfg.name : '';

         // special case for empty style to give icons to edit view
         if (cfg.bStyle === BoolStyle.EMPTY) {
            const edit_icon_style = BOOL_STYLES[BoolStyle.ONE];
            const edit_icon = bool_val ? edit_icon_style.on : edit_icon_style.off;
            icon = !is_edit_badge ? undefined : edit_icon!;
            badge_color = this.getBoolColor(cfg.color, bool_val);
            val_content = bool_val && !is_edit_badge ? cfg.name : '';
         }

         // If we have no content to show, then there is no badge to show
         if (isEmptyString(val_content) && icon == null) {
            return null;
         }
      } else if (cfg.type === FieldType.NUMBER) {
         val_content = this.getNumStr(cfg, data);

         badge_color = this.getNumColor(cfg.color, data);
      } else if (cfg.type === FieldType.DATE) {
         val_content = this.getDateStr(cfg, data);
         badge_color = this.getDateColor(cfg.color, data);
      }
      // For list compute it all in here
      else if (cfg.type === FieldType.LIST) {
         assert(data != null, 'undefined data should be filtered by now');
         const content = this.getListTextAndColors(cfg, data);
         const result_badges: Trello.PowerUp.CardBadge[] = [];
         for (const c of content) {
            const parts: string[] = [];
            if (cfg.show.frontName && !is_edit_badge) {
               parts.push(cfg.name);
            }
            if (cfg.show.frontValue && !isEmptyString(c.text)) {
               parts.push(c.text);
            }
            result_badges.push({
               text: parts.join(': '),
               color: c.color,
            });
            c.text = parts.join(': ');
         }
         return result_badges;
      }

      // Compute the final content to show based upon show settings
      let final_content = '';
      if (cfg.type !== FieldType.BOOL) {
         const parts: string[] = [];
         if (cfg.show.frontName && !is_edit_badge) {
            parts.push(cfg.name);
         }
         if (cfg.show.frontValue && !isEmptyString(val_content)) {
            parts.push(val_content);
         }
         final_content = parts.join(': ');
      }
      // For boolean, the content is the name
      else {
         final_content = val_content;
      }

      return [
         {
            text: final_content,
            color: badge_color,
            icon,
         },
      ];
   }

   /**
    * Build and return a badge setting to use for a preview.
    *
    * Because it is preview, we ensure that value is shown at least
    */
   buildBadgePreviews(cfg: IFieldInfo): Trello.PowerUp.CardBadge[] | null {
      const cfg_override = cloneDeep(cfg);
      if (isDataField(cfg_override)) {
         cfg_override.show.frontValue = true;
      }

      if (cfg.type === FieldType.TEXT) {
         return this.buildBadges(cfg, 'Test Val');
      } else if (cfg.type === FieldType.BOOL) {
         return this.buildBadges(cfg, true);
      } else if (cfg.type === FieldType.NUMBER) {
         if (isNumberProgressField(cfg)) {
            const mid_val = (cfg.bar.max - cfg.bar.min) / 2.0 + cfg.bar.min;
            return this.buildBadges(cfg, mid_val);
         } else {
            return this.buildBadges(cfg, 10);
         }
      } else if (cfg.type === FieldType.DATE) {
         // If in test suite, use stable date otherwise use current
         const date = environment.testSuite
            ? new Date(Date.UTC(2021, 8, 12, 15, 47, 59, 22))
            : new Date();
         return this.buildBadges(cfg, date.toISOString());
      } else if (cfg.type === FieldType.LIST) {
         // Always just return the first item
         const first_value = cfg.options?.[0]?.id ?? 'undefined';
         return this.buildBadges(cfg, first_value);
      } else {
         return null;
      }
   }

   /**
    * Build and return a set of badge settings to use for editing.
    */
   buildBadgesEditMode(
      cfg: IDataFieldTypes,
      data: FieldDataTypes | undefined,
   ): Trello.PowerUp.CardBadge[] | null {
      const f_cfg = {...cfg, show: {...cfg.show, frontValue: true, frontName: false}};

      if (f_cfg.type === FieldType.TEXT) {
         const badges = this.buildBadges(f_cfg, data, {edit: true});
         if (badges != null && (data == null || data === '')) {
            badges[0].text = f_cfg.show.placeholder ?? 'Add text...';
         }
         return badges;
      } else if (f_cfg.type === FieldType.BOOL) {
         const badges = this.buildBadges(f_cfg, data ?? false, {edit: true}) ?? [{text: ''}];
         if (data == null || badges[0].icon === '' || badges[0].icon == null) {
            badges[0].text = f_cfg.show.placeholder ?? '---';
         }
         return badges;
      } else if (f_cfg.type === FieldType.NUMBER) {
         const badges = this.buildBadges(f_cfg, data, {edit: true}) ?? [{text: ''}];
         if (data == null) {
            badges[0].text = f_cfg.show.placeholder ?? 'Add value...';
            badges[0].color = colorEnumToTrelloBadgeColor(f_cfg.color.base);
         }
         return badges;
      } else if (f_cfg.type === FieldType.DATE) {
         const badges = this.buildBadges(f_cfg, data, {edit: true}) ?? [{text: ''}];
         if (data == null) {
            badges[0].text = f_cfg.show.placeholder ?? 'Select date...';
            badges[0].color = colorEnumToTrelloBadgeColor(f_cfg.color.base);
         }
         return badges;
      } else if (f_cfg.type === FieldType.LIST) {
         // Always just return the first item
         const badges = this.buildBadges(f_cfg, data, {edit: true}) ?? [{text: ''}];
         if (data == null || (Array.isArray(data) && data.length === 0)) {
            badges[0].text = f_cfg.show.placeholder ?? 'Select...';
            badges[0].color = colorEnumToTrelloBadgeColor(f_cfg.emptyColor) ?? undefined;
         }
         return badges;
      } else {
         return null;
      }
   }

   /**
    * Get the color to use for a text field with the given configuration and value.
    *
    * Return the color and if the color is due to a rule matching the text.
    * (if a rule triggers then we will show the badge even if empty.)
    */
   getTextColor(
      cfg: ITextColorSettings,
      text: string,
   ): {c: Trello.PowerUp.CardBadgeColors | undefined; hitRule: boolean} {
      let hit_rule = false;

      let color: BadgeColorType | null = cfg.base;

      for (const rule of cfg.rules) {
         if (
            (rule.op === TextRuleOp.IS_EMPTY && text === '') ||
            (rule.op === TextRuleOp.NOT_EMPTY && text !== '') ||
            (rule.op === TextRuleOp.CONTAINS && text.includes(rule.val)) ||
            (rule.op === TextRuleOp.DOES_NOT_CONTAIN && !text.includes(rule.val)) ||
            (rule.op === TextRuleOp.EQUAL && text === rule.val) ||
            (rule.op === TextRuleOp.STARTS_WITH && text.startsWith(rule.val)) ||
            (rule.op === TextRuleOp.ENDS_WITH && text.endsWith(rule.val))
         ) {
            color = rule.color;
            hit_rule = true;
            break;
         }
      }

      return {c: colorEnumToTrelloBadgeColor(color), hitRule: hit_rule};
   }

   getBoolIcon(cfg: IBoolField, data: boolean): string | undefined {
      const style: IBoolStyleOpts | undefined = BOOL_STYLES[cfg.bStyle];
      if (style == null) {
         return undefined;
      }
      return (data ? style.on : style.off) ?? undefined;
   }

   /**
    * Get the color to use for a boolean configuration
    */
   getBoolColor(
      cfg: IBoolColorSettings,
      value: boolean,
   ): Trello.PowerUp.CardBadgeColors | undefined {
      return colorEnumToTrelloBadgeColor(value ? cfg.on : cfg.off);
   }

   /**
    * Build up the string to represent the given date.
    */
   getDateStr(cfg: IDateField, data: any): string {
      try {
         if (data == null || !isString(data)) {
            return '';
         }
         const date = new Date(data);
         return isValidDate(date) ? formatDate(date, cfg.dateFormat) : '';
      } catch (e: unknown) {
         appLog.error('Found invalid data: ', e);
         return '';
      }
   }

   /**
    * Get the color to use for the date badge.
    */
   getDateColor(cfg: IDateColorSettings, data: any): Trello.PowerUp.CardBadgeColors | undefined {
      if (data == null || !isString(data)) {
         return undefined;
      } else {
         const cur_val_date = new Date(data);
         if (!isValidDate(cur_val_date)) {
            return undefined;
         }

         // set color to default to start
         let color: BadgeColorType | null = cfg.base;
         const cur_dt = dayjs(cur_val_date);

         // RULES: If current date_time is RULE_OP compare_date
         for (const rule of cfg.rules) {
            // Get correct date to compare against

            const offset_unit: dayjs.ManipulateType =
               rule.unit === DateUnits.DAY
                  ? 'day'
                  : rule.unit === DateUnits.WEEK
                  ? 'week'
                  : rule.unit === DateUnits.MONTH
                  ? 'month'
                  : rule.unit === DateUnits.YEAR
                  ? 'year'
                  : rule.unit === DateUnits.MINUTE
                  ? 'minute'
                  : rule.unit === DateUnits.HOUR
                  ? 'hour'
                  : 'day';
            const compare_date: dayjs.Dayjs = dayjs().add(rule.offset, offset_unit);

            // Compute the granularity we use for comparisons
            // - Restrict IS comparisons to granularity of the offset so
            //   we get 'range' comparisons of sorts

            // - before/after we use granularity of day or minute to match natural expectation
            //   of "is date before tomorrow", "is date before today", etc
            const compare_granularity: dayjs.OpUnitType =
               rule.op === DateRuleOp.IS
                  ? (offset_unit as dayjs.OpUnitType)
                  : rule.unit === DateUnits.HOUR || rule.unit === DateUnits.MINUTE
                  ? 'minute'
                  : 'day';

            if (rule.op === DateRuleOp.IS && cur_dt.isSame(compare_date, compare_granularity)) {
               color = rule.color;
               break;
            } else if (
               rule.op === DateRuleOp.BEFORE &&
               cur_dt.isBefore(compare_date, compare_granularity)
            ) {
               color = rule.color;
               break;
            } else if (
               rule.op === DateRuleOp.AFTER &&
               cur_dt.isAfter(compare_date, compare_granularity)
            ) {
               color = rule.color;
               break;
            }
         }

         return colorEnumToTrelloBadgeColor(color);
      }
   }

   /**
    * Build the string representation of a number.
    */
   getNumStr(cfg: INumberField, data: any): string {
      try {
         if (data == null || !isNumber(data)) {
            return '';
         }

         if (cfg.showAs === NumberShowType.NUMBER) {
            return formatNumber(data, cfg);
         } else if (cfg.showAs === NumberShowType.PROGRESS_BAR) {
            assert(isNumberProgressField(cfg));
            const bar = formatProgressBar(data, cfg.bar);
            const num_str = cfg.bar.showNum ? formatNumber(data, cfg) : null;
            return num_str == null ? bar : `${bar} ${num_str}`;
         } else {
            return '';
         }
      } catch (e: unknown) {
         appLog.error('Found invalid data: ', e);
         return '';
      }
   }

   /**
    * Get the color to use for the number field.
    */
   getNumColor(
      cfg: INumberColorSettings,
      curVal: FieldDataTypes | undefined,
   ): Trello.PowerUp.CardBadgeColors | undefined {
      let color: BadgeColorType | null = cfg.base;

      if (curVal !== null && isNumber(curVal)) {
         for (const rule of cfg.rules) {
            if (
               (rule.op === NumberRuleOp.EQUAL && curVal === rule.val) ||
               (rule.op === NumberRuleOp.NOT_EQUAL && curVal !== rule.val) ||
               (rule.op === NumberRuleOp.LT && curVal < rule.val) ||
               (rule.op === NumberRuleOp.LTE && curVal <= rule.val) ||
               (rule.op === NumberRuleOp.GT && curVal > rule.val) ||
               (rule.op === NumberRuleOp.GTE && curVal >= rule.val)
            ) {
               color = rule.color;
               break;
            }
         }
      }

      return colorEnumToTrelloBadgeColor(color);
   }

   /**
    * Get the details for the badge for the given list configuration.
    *
    * Return a list of text and associated colors.
    */
   getListTextAndColors(
      cfg: IListField,
      data: FieldDataTypes,
   ): {text: string; color: Trello.PowerUp.CardBadgeColors | undefined}[] {
      // Build up list of items so we can treat them uniformly below
      const selected_items = Array.isArray(data) && data.length > 0 ? data : [];
      const selected_opts = cfg.options.filter((o) => selected_items.includes(o.id));

      const results: {text: string; color: Trello.PowerUp.CardBadgeColors | undefined}[] = [];

      if (selected_opts.length === 0) {
         // Return an empty option so we still see that the field exists??
         results.push({
            text: '',
            color: colorEnumToTrelloBadgeColor(cfg.emptyColor),
         });
      } else {
         if (cfg.multi.method === ListMultiMethod.SINGLE_BADGE) {
            // pull color from first selected item
            const first_opt = selected_opts[0];
            const list_text = getListTextFromValues(cfg, selected_items);
            results.push({
               text: list_text.join(', '),
               color: colorEnumToTrelloBadgeColor(first_opt.color),
            });
         }
         // Multi-badge should have multiple items returned
         else {
            for (const opt of selected_opts) {
               results.push({
                  text: opt.text,
                  color: colorEnumToTrelloBadgeColor(opt.color),
               });
            }
         }
      }

      return results;
   }

   async buildCardRefBadge(
      cfg: ICardRefField,
      data: ICardRefFieldData | undefined,
   ): Promise<Trello.PowerUp.CardBadge | null> {
      if (data == null || data.ids.length === 0) {
         return null;
      }

      let text_val = '';

      if (cfg.show.frontName) {
         text_val = `${cfg.name}: `;
      }

      const card_names: string[] = [];

      if (cfg.show.frontValue) {
         const cards = await this.cardSrv.getCardRefCards(data);
         for (const c of cards) {
            card_names.push(c.name);
         }
      }
      text_val = `${text_val} ${card_names.join(', ')}`;

      return {
         text: text_val,
      };
   }
}
