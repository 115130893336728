import {Injectable} from '@angular/core';

import {
   AMF_FIELDS_HIDDEN_IDX,
   AMF_FIELD_DATA_IDX,
   ICardData,
   ICardHiddenFields,
   IFieldInfo,
} from '@shared/domain_types';
import * as lz_string from 'lz-string';

import {appConfig} from '@app/app_config';
import {SentrySrv} from '@app/sentry.srv';
import {appLog, breadcrumb} from '@app/services/logging';
import {Trello} from '@trello/trello_powerup_client';

import {assert} from '@util/assert';
import {isString} from '@util/utils';
import {CARD_DATA_MIGRATIONS} from './card_migrations';

/** The current version of the card data structure. */
export const CURRENT_CARD_DATA_VERSION = 1;

/** Build and return the default empty field configuration for new cards. */
export function buildDefaultCardData(): ICardData {
   return {
      __version: CURRENT_CARD_DATA_VERSION,
      __boardId: null,
      __lastEditSrvMs: null,
   };
}

/**
 * Service for managing the data stored on cards.
 */
@Injectable({providedIn: 'root'})
export class CardDataStorageSrv {
   constructor(protected sentrySrv: SentrySrv) {}

   /**
    * Return the value for all known fields.
    *
    * Note: this may return more or less than currently configured.
    *
    * If card is passed, then pull out of plugin data.
    *
    * @param cardOrId - If card data, pull directly.  If id, then lookup, if null then pull from context.
    */
   async loadFields(
      cardFrame: Trello.PowerUp.IFrame,
      fieldCfgs: IFieldInfo[],
      cardOrId?: Pick<Trello.PowerUp.Card, 'id' | 'pluginData'> | string,
      targetVersion?: number,
   ): Promise<ICardData> {
      let field_data: ICardData;

      let raw_val: string | ICardData | undefined;

      let debug_card_id: any;

      // -- LOOKUP THE CARD DATA -- //
      if (cardOrId == null || isString(cardOrId)) {
         const card_scope_id: Trello.PowerUp.Scope | string = cardOrId == null ? 'card' : cardOrId;
         debug_card_id = card_scope_id;

         // debugging invalid value
         try {
            raw_val = await cardFrame.get<string | ICardData>(
               card_scope_id,
               'shared',
               AMF_FIELD_DATA_IDX,
               undefined,
            );
         } catch (e: any) {
            breadcrumb(`Error loading field from scope: [${e.message}]`, {data: {cardOrId}});
            throw e;
         }
      }
      // If we have a card then try to pull directly from plugin data.
      else {
         assert(cardOrId.pluginData != null, 'Must have card plugin data to get card fields');
         const powerup_id = appConfig.amzPowerUpId;
         debug_card_id = cardOrId.id ?? 'unknown';

         const found = cardOrId.pluginData.find(
            (p) => p.idPlugin === powerup_id && p.access === 'shared',
         );
         if (found == null) {
            raw_val = undefined;
         } else {
            const raw_card_data = JSON.parse(found.value) as Record<string, object>;
            raw_val = raw_card_data[AMF_FIELD_DATA_IDX] as ICardData | string;
         }
      }

      // -- DECODE THE CARD DATA -- //
      // If we don't have good data (network connection or empty), then return nothing.
      if (raw_val == null) {
         field_data = buildDefaultCardData();
      } else {
         try {
            // Attempt to get compressed data and then fallback to trying JSON
            // If was a compressed value
            if (isString(raw_val)) {
               const data_str = lz_string.decompressFromUTF16(raw_val);
               if (data_str != null) {
                  try {
                     field_data = JSON.parse(data_str) as ICardData;
                  } catch (e: any) {
                     breadcrumb('JSON parse failure: ', {data: {data_str}});
                     throw e;
                  }
               } else {
                  breadcrumb('Failed to decode card data', {
                     data: {raw_val, context: cardFrame.getContext(), debug_card_id},
                  });
                  // If the data looks corrupt or unparsable, throw an exception.
                  // XXX: THINK ABOUT THIS!!!  (will this let card data be destroyed on errors)
                  throw new Error('Failed to decode card data');
               }
            }
            // Must have old JSON format card data
            else {
               field_data = raw_val;
            }
         } catch (e: any) {
            appLog.warn('Failed to process card data.');
            this.sentrySrv.handleError(e, 'CardDataStorageSrv:loadFields:BAD_DATA-createDefault', {
               debug_card_id,
            });
            field_data = buildDefaultCardData();

            // XXX: Resolve this
            const rewrite_bad_data = false;
            const can_write = cardFrame.getContext().permissions?.card === 'write';

            // XXX: DON'T LEAVE THIS
            // - REWRITE DATA TO BLANK - DATA LOSS HERE
            if (rewrite_bad_data && can_write) {
               try {
                  this.sentrySrv.captureMessage(
                     `CardDataStorageSrv:loadFields:Reparing bad card data: ${e.message}`,
                     {
                        tags: {
                           board: cardFrame.getContext().board,
                           debug_card_id,
                        },
                     },
                  );
                  field_data.__boardId = cardFrame.getContext().board;
                  field_data.__lastEditSrvMs = 0;
                  await this.storeCardFields(cardFrame, field_data, debug_card_id);
               } catch (err: any) {
                  this.sentrySrv.handleError(err, 'CardDataStorageSrv:loadFields:repair_failed', {
                     debug_card_id,
                  });
               }
            }
         }
      }

      //appLog.log(`CARD_DATA:before_migrate`, field_data);

      // -- MIGRATE DATA ON THE FLY -- //
      // Handle case of initial migration before there were version numbers
      if (field_data.__version === undefined) {
         appLog.log('CARD_DATA: FOUND undefined version --> setting to 0');
         field_data.__version = 0;
      }
      // handle case of card with no board id - can't migrate because we don't want to set.
      if (field_data.__boardId === undefined) {
         field_data.__boardId = null;
      }

      // Migrate data as needed
      field_data = this.migrateConfig(field_data, fieldCfgs, targetVersion);

      //appLog.log(`CARD_DATA:after_migrate`, field_data);

      return field_data;
   }

   /**
    * Migrate card data from version to targetted version
    */
   migrateConfig(fieldData: ICardData, fieldCfgs: IFieldInfo[], targetVersion?: number): ICardData {
      let final_data = fieldData;
      const target_version = targetVersion ?? CURRENT_CARD_DATA_VERSION;

      if (final_data.__version > target_version) {
         appLog.warn(
            `Field config is version ${final_data.__version}, code expected: ${target_version}`,
         );
      }
      // Migrate until we get to targetted version
      while (final_data.__version < target_version) {
         const next_version = final_data.__version + 1;
         const migration = CARD_DATA_MIGRATIONS.find((m) => m.targetVersion === next_version);
         assert(migration != null, `Unable to find card data migration for ver: ${next_version}`);
         final_data = migration.f(final_data, fieldCfgs);
         assert(final_data.__version === next_version, 'Should migrate to the target version');
      }
      return final_data;
   }

   /**
    * store the card fields into the card.
    */
   async storeCardFields(
      cardFrame: Trello.PowerUp.IFrame,
      dataIn: ICardData,
      cardId: string,
   ): Promise<void> {
      // note: this is extra overhead to try to determine why card decoding can fail
      const double_check_saving = true;

      try {
         breadcrumb('CardDataStorageSrv:storeCardFields', {
            data: {context: cardFrame.getContext()},
         });
         appLog.info('CardDataStorageSrv:storeCardFields - fields', dataIn);

         // -- Compress data to set
         const fields_str = JSON.stringify(dataIn);
         const fields_comp_str = lz_string.compressToUTF16(fields_str);

         // Save card data and edit time
         await cardFrame.set<string>(cardId, 'shared', AMF_FIELD_DATA_IDX, fields_comp_str);

         if (double_check_saving) {
            const checked_val = await cardFrame.get<string>(
               cardId,
               'shared',
               AMF_FIELD_DATA_IDX,
               undefined,
            );
            const checked_str =
               checked_val != null ? lz_string.decompressFromUTF16(checked_val) : null;

            if (fields_comp_str !== checked_val) {
               breadcrumb('cross check failed: ', {
                  data: {
                     dataIn,
                     fields_str,
                     fields_comp_str,
                     crosschecked_value: checked_val,
                     crosschecked_str: checked_str,
                     context: cardFrame.getContext(),
                  },
               });
               this.sentrySrv.handleWarning(
                  'CardDataStorageSrv:storeCardFields: data storage cross check failed!!!',
               );
            }
         }

         appLog.log(
            `Card data stored: raw size: ${fields_str.length} comp_size: ${fields_comp_str.length}`,
         );
      } catch (e: unknown) {
         this.handleDataSetErrors(cardFrame, e);
      }
   }

   /**
    * Return the list of hidden fields
    *
    * Note: this may return more or less than currently configured.
    */
   async loadHiddenFields(
      cardFrame: Trello.PowerUp.IFrame,
      cardId: string,
   ): Promise<ICardHiddenFields | null> {
      let retval: ICardHiddenFields | null = null;

      const raw_val = await cardFrame.get<ICardHiddenFields | string | null>(
         cardId,
         'shared',
         AMF_FIELDS_HIDDEN_IDX,
         null,
      );

      if (raw_val != null) {
         try {
            // if was a compressed value
            if (isString(raw_val)) {
               const json_str = lz_string.decompressFromUTF16(raw_val);
               if (json_str != null) {
                  retval = JSON.parse(json_str) as ICardHiddenFields;
               }
            } else {
               retval = raw_val;
            }
         } catch (e: unknown) {
            appLog.warn('Failed to process card hidden field data.');
            retval = null;
         }
      }

      return retval;
   }

   /**
    * Save the field values that are hidden
    */
   async storeHiddenFields(
      frame: Trello.PowerUp.IFrame,
      cardId: string,
      data: ICardHiddenFields,
   ): Promise<void> {
      try {
         breadcrumb('CardDataStorageSrv:storeHiddenFields', {
            data: {context: frame.getContext()},
         });
         const data_str = JSON.stringify(data);
         const data_comp = lz_string.compressToUTF16(data_str);
         await frame.set<string>(cardId, 'shared', AMF_FIELDS_HIDDEN_IDX, data_comp);
      } catch (e: unknown) {
         this.handleDataSetErrors(frame, e);
      }
   }

   /**
    * Either handle the error or rethrow it.
    *
    * When handling, we may present an alert to the user.
    */
   protected handleDataSetErrors(t: Trello.PowerUp.IFrame, e: unknown): void {
      // Trello set errors are of type Error
      if (e instanceof Error) {
         if (/PluginData length.*exceeded/.test(e.message)) {
            appLog.warn('Card data storage exceeded. Save failed.');
            void t.alert({
               message:
                  'Card Storage Exceeded: Trello limits the amount of data stored in cards.  Please try to reduce the size of the fields.',
               display: 'error',
               duration: 30,
            });
         } else if (e.message.includes('Scope not editable')) {
            appLog.warn('Card data save failed because scope is not editable.');
            void t.alert({
               message:
                  'You do not have permission to edit card data. Please check your board permissions.',
               display: 'error',
               duration: 30,
            });
         } else {
            this.sentrySrv.handleError(e, 'CardDataStorage:dataSetError-A');
            void t.alert({
               message: 'Error storing card data. Please refresh and try again. [CardStore-A]',
               display: 'error',
               duration: 30,
            });
            //throw e;
         }
      } else {
         this.sentrySrv.handleError(e, 'CardDataStorage:dataSetError-B');
         void t.alert({
            message: 'Error storing card data. Please refresh and try again. [CardStore-B]',
            display: 'error',
            duration: 30,
         });
         //throw new Error(`Error Saving Card Data [B]: ${e}`);
         //throw e;
      }
   }
}
