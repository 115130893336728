import {IBoardConfig} from '@shared/domain_types';

import {assert} from '@util/assert';

import {IFieldDataMigration} from '.';

enum FieldTypeV9 {
   /** Default type for using before we know what type to use. */
   UNKNOWN = 'U',

   TEXT = 'T',
   NUMBER = 'N',
   DATE = 'D',
   BOOL = 'B',
   LIST = 'L',
}

// Only define the parts needed
// V9
interface IBoardConfigV9 {
   version: number;
   fields: (IFieldInfoV9 | ITextFieldV9)[];
}

interface IFieldInfoV9 {
   name: string;
   type: FieldTypeV9;
}

interface ITextFieldV9 extends IFieldInfoV9 {
   type: FieldTypeV9.TEXT;
}

// V10
interface IBoardConfigV10 {
   version: number;
   fields: (IFieldInfoV10 | ITextFieldV10)[];
}

interface IFieldInfoV10 {
   name: string;
   type: FieldTypeV9;
}

interface ITextFieldV10 extends IFieldInfoV10 {
   type: FieldTypeV9.TEXT;
   mask: {
      opt: TextMaskOptionV10;
      custom: string | null;
   };
}

enum TextMaskOptionV10 {
   CUSTOM = 'z',
   NONE = 'n',
}

/**
 * Add text masking options
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const addTextInputMasksConfig_9_10: IFieldDataMigration = {
   targetVersion: 10,
   f: (c: IBoardConfig): IBoardConfig => {
      // Verify we have valid input data
      assert(c.version === 9);

      const incoming = c as unknown as IBoardConfigV9;
      assert(incoming != null);

      const outgoing = c as unknown as IBoardConfigV10;

      for (const f of outgoing.fields) {
         if (f.type === FieldTypeV9.TEXT) {
            const f_out = f as ITextFieldV10;
            f_out.mask = {
               opt: TextMaskOptionV10.NONE,
               custom: null,
            };
         }
      }

      // Set final version
      c.version = 10;
      return c;
   },
};
